import { __assign, __read } from "tslib";
import { isFormValueCondition } from './models/FormConditions';
function resolveConditions(conditions, formValue, externalValues) {
    var entries = Object.entries(conditions);
    var conditionsResolved = entries.reduce(function (acc, _a) {
        var _b, _c;
        var _d = __read(_a, 2), nextConditionName = _d[0], nextCondition = _d[1];
        if (nextCondition.type === 'equals') {
            var actualValue = null;
            var expectedValue = nextCondition.value;
            if (isFormValueCondition(nextCondition)) {
                actualValue = formValue[nextCondition.fieldName];
            }
            else {
                actualValue = externalValues[nextCondition.external];
            }
            return __assign(__assign({}, acc), (_b = {}, _b[nextConditionName] = actualValue === expectedValue, _b));
        }
        return __assign(__assign({}, acc), (_c = {}, _c[nextConditionName] = false, _c));
    }, {});
    return conditionsResolved;
}
export function resolveErrorConditions(conditions, formErrors) {
    var entries = Object.entries(conditions);
    var conditionsResolved = entries.reduce(function (acc, _a) {
        var _b, _c;
        var _d = __read(_a, 2), nextConditionName = _d[0], nextCondition = _d[1];
        if (nextCondition.type === 'isInvalid') {
            var fieldName = nextCondition.fieldName;
            var hasError = !!formErrors[fieldName];
            return __assign(__assign({}, acc), (_b = {}, _b[nextConditionName] = hasError, _b));
        }
        return __assign(__assign({}, acc), (_c = {}, _c[nextConditionName] = false, _c));
    }, {});
    return conditionsResolved;
}
export default resolveConditions;
