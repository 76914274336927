import { __assign, __decorate, __extends, __read, __spread } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as PresentationalComponents from './elements';
import FormSmartField from './FormSmartField.vue';
import { isElementWithChildren } from './models/formElements';
var presentationalComponentNames = Object.keys(PresentationalComponents);
var FormElement = /** @class */ (function (_super) {
    __extends(FormElement, _super);
    function FormElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FormElement_1 = FormElement;
    Object.defineProperty(FormElement.prototype, "currentPath", {
        get: function () {
            return __spread(this.parentPath, [this.index]);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FormElement.prototype, "currentPathStringified", {
        get: function () {
            return this.currentPath.join('.');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FormElement.prototype, "componentName", {
        get: function () {
            var type = this.element.type;
            if (this.element.type === 'FormField') {
                return 'FormSmartField';
            }
            if (presentationalComponentNames.includes(type)) {
                return this.element.type;
            }
            return 'DebugComponent';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FormElement.prototype, "componentProps", {
        get: function () {
            var _a = this, form = _a.form, formMetadata = _a.formMetadata, formValue = _a.formValue;
            if (this.element.type === 'FormField') {
                var _b = this.element, name = _b.name, type_1 = _b.type, input = _b.input, props_1 = _b.props, condProps = _b.condProps;
                return {
                    name: name,
                    type: type_1,
                    input: input,
                    props: props_1,
                    condProps: condProps,
                    form: form,
                    formMetadata: formMetadata,
                    formValue: formValue
                };
            }
            var _c = this.element, type = _c.type, props = _c.props;
            return {
                type: type,
                props: props !== null && props !== void 0 ? props : {},
                index: this.index,
                parentPath: this.parentPath,
                form: form,
                formMetadata: formMetadata
            };
        },
        enumerable: false,
        configurable: true
    });
    // use render function: when using template it seems
    // to have trouble handling recursive rendering
    FormElement.prototype.render = function (h) {
        var _a = this, componentName = _a.componentName, componentProps = _a.componentProps, element = _a.element;
        if (isElementWithChildren(element)) {
            var _b = this, currentPath_1 = _b.currentPath, currentPathStringified_1 = _b.currentPathStringified, form_1 = _b.form, formMetadata_1 = _b.formMetadata, formValue_1 = _b.formValue;
            var children = element.children.map(function (child, index) {
                return h(FormElement_1, {
                    props: {
                        key: currentPathStringified_1 + index,
                        element: child,
                        form: form_1,
                        formMetadata: formMetadata_1,
                        formValue: formValue_1,
                        index: index,
                        parentPath: currentPath_1
                    }
                });
            });
            return h(componentName, { props: componentProps }, children);
        }
        return h(componentName, { props: componentProps });
    };
    var FormElement_1;
    __decorate([
        Prop({ default: function () { return []; } })
    ], FormElement.prototype, "parentPath", void 0);
    __decorate([
        Prop({ required: true })
    ], FormElement.prototype, "index", void 0);
    __decorate([
        Prop({ required: true })
    ], FormElement.prototype, "element", void 0);
    __decorate([
        Prop({ required: true })
    ], FormElement.prototype, "form", void 0);
    __decorate([
        Prop({ required: true })
    ], FormElement.prototype, "formValue", void 0);
    __decorate([
        Prop({ required: true })
    ], FormElement.prototype, "formMetadata", void 0);
    FormElement = FormElement_1 = __decorate([
        Component({
            components: __assign(__assign({}, PresentationalComponents), { FormSmartField: FormSmartField })
        })
    ], FormElement);
    return FormElement;
}(Vue));
export default FormElement;
