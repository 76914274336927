import { __decorate, __extends } from "tslib";
import { Component } from 'vue-property-decorator';
import { QInput } from 'quasar';
import BaseControl from './BaseControl';
var NumberControl = /** @class */ (function (_super) {
    __extends(NumberControl, _super);
    function NumberControl() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NumberControl.prototype.handleInput = function (value) {
        var valueParsed = Number.parseInt(value, 10);
        if (Number.isNaN(valueParsed)) {
            this.emitInput(0);
            return;
        }
        this.emitInput(valueParsed);
    };
    NumberControl = __decorate([
        Component({
            components: { QInput: QInput }
        })
    ], NumberControl);
    return NumberControl;
}(BaseControl));
export default NumberControl;
