import { __awaiter, __generator } from "tslib";
import { createClient, PROXY_BASE_URL } from './serviceVPPClient';
var ENDPOINT_CUSTOMER = '/customer/get';
// eslint-disable-next-line import/prefer-default-export
export var getCustomerData = function (authToken, customerID) { return __awaiter(void 0, void 0, void 0, function () {
    var client, response, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                client = createClient({ url: PROXY_BASE_URL, apikey: '', authToken: authToken });
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, client.get(ENDPOINT_CUSTOMER.concat('/').concat(customerID))];
            case 2:
                response = _a.sent();
                return [2 /*return*/, response.data.data.object];
            case 3:
                e_1 = _a.sent();
                console.error('Fehler beim laden aus dem VPP Backend');
                return [2 /*return*/, null];
            case 4: return [2 /*return*/];
        }
    });
}); };
