import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
var FormTitle = /** @class */ (function (_super) {
    __extends(FormTitle, _super);
    function FormTitle() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(FormTitle.prototype, "title", {
        get: function () {
            return this.form.title;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop({ required: true })
    ], FormTitle.prototype, "form", void 0);
    FormTitle = __decorate([
        Component
    ], FormTitle);
    return FormTitle;
}(Vue));
export default FormTitle;
