import { __awaiter, __generator } from "tslib";
import { KeycloakInstance } from './keycloakClient';
import { createClient, PROXY_BASE_URL } from './serviceVPPClient';
var ENDPOINT_CONTRACT = '/contract/get';
// eslint-disable-next-line import/prefer-default-export
export var getContactData = function (authToken, templateID) { return __awaiter(void 0, void 0, void 0, function () {
    var client, response, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                client = createClient({ url: PROXY_BASE_URL, apikey: '', authToken: authToken });
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, client.get(ENDPOINT_CONTRACT.concat('?idTemplatedata=')
                        .concat(templateID)
                        .concat('&showIdentity=true&showPersons=true&showAddresses=true&showContacts=true&showPayments=true'))];
            case 2:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 3:
                e_1 = _a.sent();
                console.error('Fehler beim laden aus dem VPP Backend');
                return [2 /*return*/, null];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var refreshAuthToken = function (url, kctoken) { return __awaiter(void 0, void 0, void 0, function () {
    var body, response, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                body = new URLSearchParams();
                body.append('grant_type', 'refresh_token');
                body.append('client_id', 'vpp');
                body.append('refresh_token', kctoken);
                body.append('public-client', 'true');
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, KeycloakInstance.post(url, body.toString(), { headers: { 'content-type': 'application/x-www-form-urlencoded' } })];
            case 2:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 3:
                e_2 = _a.sent();
                console.debug('Fehler bei keepAlive', e_2.error_description);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/, null];
        }
    });
}); };
