import { __awaiter, __decorate, __extends, __generator } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { ClosePopup, QBtn, QBtnGroup, QCard, QCardSection, QCheckbox, QIcon, QInput, QItem, QItemLabel, QItemSection, QSelect } from 'quasar';
import { delay } from '../../helpers';
import { sendMailData } from '../../utils/contactFormMailing';
import useGetDocuments from '../../utils/documents';
import { createAPIKey } from '../../utils/serviceProxy';
var ContactForm = /** @class */ (function (_super) {
    __extends(ContactForm, _super);
    function ContactForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.salutation = '';
        _this.subject = 1;
        _this.customerType = '';
        _this.customerOptions = ['Privat', 'Gewerbe'];
        _this.firstName = '';
        _this.lastName = '';
        _this.telephone = '';
        _this.mail = '';
        _this.name = '';
        _this.zipCode = '';
        _this.city = '';
        _this.street = '';
        _this.houseNumber = '';
        _this.text = '';
        _this.privacyLink = '';
        _this.hasAgreed = false;
        _this.hasEmailError = false;
        return _this;
    }
    ContactForm.prototype.mounted = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var apikey, docs, res, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.initialValue();
                        return [4 /*yield*/, this.getAPIKey()];
                    case 1:
                        apikey = _b.sent();
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 4, , 5]);
                        docs = useGetDocuments({ apikey: apikey });
                        return [4 /*yield*/, docs.getDocuments(parseFloat(this.inputData.tariffCalculatorTypeId), undefined)];
                    case 3:
                        res = _b.sent();
                        this.privacyLink = (_a = res === null || res === void 0 ? void 0 : res.data.privacyUrl) !== null && _a !== void 0 ? _a : '';
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _b.sent();
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(ContactForm.prototype, "isFormValid", {
        get: function () {
            return this.name && this.zipCode && this.street && this.houseNumber && this.city && this.city && this.subject && this.mail && this.telephone && this.text && this.hasAgreed;
        },
        enumerable: false,
        configurable: true
    });
    ContactForm.prototype.isValidEmail = function () {
        var emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
        var res = emailPattern.test(this.mail);
        this.hasEmailError = !res;
    };
    Object.defineProperty(ContactForm.prototype, "additionalText", {
        get: function () {
            var _this = this;
            var _a;
            return (_a = this.contactFormSubject.find(function (f) { return f.id === _this.subject; })) === null || _a === void 0 ? void 0 : _a.altText;
        },
        enumerable: false,
        configurable: true
    });
    ContactForm.prototype.initialValue = function () {
        if (this.inputData.zipCode) {
            this.zipCode = this.inputData.zipCode;
            this.city = this.inputData.city;
            this.street = this.inputData.street;
            this.houseNumber = this.inputData.houseNumber;
        }
    };
    ContactForm.prototype.sendForm = function () {
        return __awaiter(this, void 0, void 0, function () {
            var apikey, body, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getAPIKey()];
                    case 1:
                        apikey = _a.sent();
                        body = this.formInputData();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 5, , 6]);
                        return [4 /*yield*/, sendMailData(body, body.Email, this.subject, this.inputData.tariffCalculatorTypeId, apikey)];
                    case 3:
                        _a.sent();
                        this.$toast({ message: 'Ihre Anfrage wurde erfolgreich gesendet', duration: 2000, type: 'success' });
                        return [4 /*yield*/, delay(1200)];
                    case 4:
                        _a.sent();
                        this.goBackToTR();
                        return [3 /*break*/, 6];
                    case 5:
                        e_2 = _a.sent();
                        this.$toast({ duration: 2000, message: e_2.toString(), type: 'error' });
                        throw new Error(e_2.message);
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ContactForm.prototype.getAPIKey = function () {
        return __awaiter(this, void 0, void 0, function () {
            var apikey, newAPIKey;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        apikey = this.$q.cookies.get('adserv');
                        if (!!apikey) return [3 /*break*/, 2];
                        return [4 /*yield*/, createAPIKey()];
                    case 1:
                        newAPIKey = _a.sent();
                        this.$q.cookies.set('adserv', newAPIKey, { expires: '4m' });
                        return [2 /*return*/, newAPIKey];
                    case 2: return [2 /*return*/, apikey];
                }
            });
        });
    };
    ContactForm.prototype.formInputData = function () {
        var _a;
        var _b = this, customerType = _b.customerType, name = _b.name, zipCode = _b.zipCode, street = _b.street, houseNumber = _b.houseNumber, city = _b.city, subject = _b.subject, mail = _b.mail, telephone = _b.telephone, text = _b.text;
        var hostname = window.location.hostname;
        return {
            Frage: (_a = this.contactFormSubject.find(function (f) { return f.id === subject; })) === null || _a === void 0 ? void 0 : _a.text,
            Kundenart: customerType,
            Name: name,
            PLZ: zipCode,
            Ort: city,
            Strasse: street,
            Hausnummer: houseNumber,
            Telefon: telephone,
            Email: mail,
            Nachricht: text,
            Datenschutzbestimmungen: 'Ich habe die aktuellen Datenschutzbestimmungen gelesen und akzeptiere diese.',
            Webseite: hostname
        };
    };
    ContactForm.prototype.goBackToTR = function () { };
    __decorate([
        Prop({ required: false })
    ], ContactForm.prototype, "contactFormSubject", void 0);
    __decorate([
        Prop({ required: true })
    ], ContactForm.prototype, "inputData", void 0);
    __decorate([
        Prop({ default: function () { return ({}); } })
    ], ContactForm.prototype, "styles", void 0);
    __decorate([
        Emit()
    ], ContactForm.prototype, "goBackToTR", null);
    ContactForm = __decorate([
        Component({
            components: {
                QCard: QCard,
                QCardSection: QCardSection,
                QIcon: QIcon,
                QItem: QItem,
                QItemLabel: QItemLabel,
                QItemSection: QItemSection,
                QInput: QInput,
                QBtn: QBtn,
                QBtnGroup: QBtnGroup,
                QSelect: QSelect,
                QCheckbox: QCheckbox
            },
            directives: {
                ClosePopup: ClosePopup
            }
        })
    ], ContactForm);
    return ContactForm;
}(Vue));
export default ContactForm;
