import { __decorate, __extends } from "tslib";
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { QBanner, QBtn } from 'quasar';
var FormSubmissionMessage = /** @class */ (function (_super) {
    __extends(FormSubmissionMessage, _super);
    function FormSubmissionMessage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(FormSubmissionMessage.prototype, "message", {
        get: function () {
            if (this.formMetadata.hasSubmissionError) {
                return this.props.errorMessage;
            }
            if (this.formMetadata.hasSubmissionSuccess) {
                return this.props.successMessage;
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FormSubmissionMessage.prototype, "backgroundClass", {
        get: function () {
            return {
                'bg-primary': this.formMetadata.hasSubmissionSuccess,
                'bg-negative': this.formMetadata.hasSubmissionError
            };
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Inject()
    ], FormSubmissionMessage.prototype, "clearSubmissionState", void 0);
    __decorate([
        Prop({ required: true })
    ], FormSubmissionMessage.prototype, "formMetadata", void 0);
    __decorate([
        Prop({ required: true })
    ], FormSubmissionMessage.prototype, "props", void 0);
    FormSubmissionMessage = __decorate([
        Component({ components: { QBanner: QBanner, QBtn: QBtn } })
    ], FormSubmissionMessage);
    return FormSubmissionMessage;
}(Vue));
export default FormSubmissionMessage;
