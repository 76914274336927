export function isRequiredField(fieldDefinition) {
    var validation = fieldDefinition === null || fieldDefinition === void 0 ? void 0 : fieldDefinition.validation;
    if (!validation) {
        return false;
    }
    return Object.prototype.hasOwnProperty.call(validation, 'required');
}
export function isDateOperationsInitialValue(fieldInitialValue) {
    return fieldInitialValue instanceof Array;
}
