import { __assign, __decorate, __extends } from "tslib";
import { Component, Prop, Ref } from 'vue-property-decorator';
import { QDate, QIcon, QInput, QPopupProxy } from 'quasar';
import dayjs from 'dayjs';
import { INTERNAL_DATE_FORMAT } from '../../../helpers';
import { resolveDateOperations, resolveMinMaxDateRule } from '../dateOperations';
import BaseControl from './BaseControl';
var QUASAR_DATE_FORMAT = 'YYYY/MM/DD';
var DateControl = /** @class */ (function (_super) {
    __extends(DateControl, _super);
    function DateControl() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(DateControl.prototype, "date", {
        get: function () {
            return this.baseInputProps.value;
        },
        set: function (value) {
            var _this = this;
            this.emitInput(value);
            setTimeout(function () {
                var _a;
                (_a = _this.popupProxy) === null || _a === void 0 ? void 0 : _a.hide();
            }, 700);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateControl.prototype, "displayDate", {
        get: function () {
            var displayFormat = this.props.displayFormat;
            if (!this.date) {
                return '';
            }
            return dayjs(this.date, INTERNAL_DATE_FORMAT).format(displayFormat !== null && displayFormat !== void 0 ? displayFormat : 'DD-MM-YYYY');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateControl.prototype, "dateInputProps", {
        get: function () {
            var _a = this, baseInputProps = _a.baseInputProps, allowedRange = _a.allowedRange, initialMonth = _a.initialMonth, limitRange = _a.limitRange;
            return __assign(__assign(__assign(__assign(__assign({}, baseInputProps), { mask: INTERNAL_DATE_FORMAT }), (allowedRange.min
                ? {
                    navigationMinYearMonth: dayjs(allowedRange.min)
                        .format(QUASAR_DATE_FORMAT)
                        .slice(0, 7)
                }
                : {})), (allowedRange.max
                ? {
                    navigationMaxYearMonth: dayjs(allowedRange.max)
                        .format(QUASAR_DATE_FORMAT)
                        .slice(0, 7)
                }
                : {})), { defaultYearMonth: initialMonth, options: limitRange });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateControl.prototype, "allowedRange", {
        get: function () {
            var min = null;
            var max = null;
            var validationRules = this.form.fields[this.name].validation;
            if (!validationRules) {
                return { min: min, max: max };
            }
            var minResolved = resolveMinMaxDateRule(validationRules.min);
            var maxResolved = resolveMinMaxDateRule(validationRules.max);
            return { min: minResolved, max: maxResolved };
        },
        enumerable: false,
        configurable: true
    });
    DateControl.prototype.limitRange = function (date) {
        var _a = this.allowedRange, min = _a.min, max = _a.max;
        var isAfterMin = min
            ? dayjs(date, 'YYYY/MM/DD')
                .add(1, 'day')
                .isAfter(min)
            : true;
        var isBeforeMax = max
            ? dayjs(date, 'YYYY/MM/DD')
                .subtract(1, 'day')
                .isBefore(max)
            : true;
        return isAfterMin && isBeforeMax;
    };
    Object.defineProperty(DateControl.prototype, "initialMonth", {
        get: function () {
            if (this.date)
                return null;
            var initialMonth = this.props.initialMonth;
            var dateResolved = resolveDateOperations(initialMonth !== null && initialMonth !== void 0 ? initialMonth : []);
            return dayjs(dateResolved).format('YYYY/MM');
        },
        enumerable: false,
        configurable: true
    });
    DateControl.prototype.onInputClick = function () {
        var _a;
        (_a = this.popupProxy) === null || _a === void 0 ? void 0 : _a.show();
    };
    __decorate([
        Ref('popup-proxy')
    ], DateControl.prototype, "popupProxy", void 0);
    __decorate([
        Prop({ required: true })
    ], DateControl.prototype, "props", void 0);
    DateControl = __decorate([
        Component({
            components: { QDate: QDate, QInput: QInput, QIcon: QIcon, QPopupProxy: QPopupProxy }
        })
    ], DateControl);
    return DateControl;
}(BaseControl));
export default DateControl;
