import { __decorate, __extends } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { QCard } from 'quasar';
var Modal = /** @class */ (function (_super) {
    __extends(Modal, _super);
    function Modal() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Modal.prototype.onClose = function () { };
    __decorate([
        Prop({ default: false, required: true })
    ], Modal.prototype, "open", void 0);
    __decorate([
        Emit()
    ], Modal.prototype, "onClose", null);
    Modal = __decorate([
        Component({
            components: {
                QCard: QCard
            }
        })
    ], Modal);
    return Modal;
}(Vue));
export default Modal;
