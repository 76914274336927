import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { QInnerLoading } from 'quasar';
var LoadingWrapper = /** @class */ (function (_super) {
    __extends(LoadingWrapper, _super);
    function LoadingWrapper() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        Prop({ required: true, default: function () { return false; }, type: Boolean })
    ], LoadingWrapper.prototype, "status", void 0);
    LoadingWrapper = __decorate([
        Component({
            components: { QInnerLoading: QInnerLoading }
        })
    ], LoadingWrapper);
    return LoadingWrapper;
}(Vue));
export default LoadingWrapper;
