import { __awaiter, __generator } from "tslib";
import Cookies from 'js-cookie';
import { createAPIKey, createClient } from './serviceProxy';
var ENPINT_PATH_PRIMA = '/prima/information/telco/v1/address/gridconnectionsByAdressId';
var inFourMinutes = new Date(new Date().getTime() + 4 * 60 * 1000);
// eslint-disable-next-line import/prefer-default-export
export var getPrimaAddressData = function (Id) { return __awaiter(void 0, void 0, void 0, function () {
    var apikey, client, response, e_1;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                apikey = (_a = Cookies === null || Cookies === void 0 ? void 0 : Cookies.get) === null || _a === void 0 ? void 0 : _a.call(Cookies, 'adserv');
                if (!!apikey) return [3 /*break*/, 2];
                return [4 /*yield*/, createAPIKey()];
            case 1:
                apikey = _b.sent();
                Cookies.set('adserv', apikey !== null && apikey !== void 0 ? apikey : '', { expires: inFourMinutes });
                _b.label = 2;
            case 2:
                client = createClient({ apikey: apikey !== null && apikey !== void 0 ? apikey : '' });
                _b.label = 3;
            case 3:
                _b.trys.push([3, 5, , 6]);
                return [4 /*yield*/, client.get(ENPINT_PATH_PRIMA.concat('?Id=')
                        .concat(Id)
                        .concat('&gridConnectionType=0'))];
            case 4:
                response = _b.sent();
                return [2 /*return*/, response.data.data];
            case 5:
                e_1 = _b.sent();
                console.error('Fehler beim laden aus dem Prima Backend');
                return [2 /*return*/, null];
            case 6: return [2 /*return*/];
        }
    });
}); };
