var handleValidation = function (value, formName) {
    if (formName === 'contactData') {
        var _a = value, email = _a.email, salutation = _a.salutation, lastName = _a.lastName, firstName = _a.firstName, phoneNumber = _a.phoneNumber, birthday = _a.birthday;
        return !!email && !!salutation && !!lastName && !!firstName && !!phoneNumber && !!birthday;
    }
    if (formName === 'addressData') {
        var _b = value, zipCodeBilling = _b.zipCodeBilling, zipCode = _b.zipCode, streetBilling = _b.streetBilling, street = _b.street, firstName = _b.firstName, lastName = _b.lastName, salutation = _b.salutation, cityBilling = _b.cityBilling, city = _b.city, billingSameAsDelivery = _b.billingSameAsDelivery, houseNumberBilling = _b.houseNumberBilling, houseNumber = _b.houseNumber;
        if (!billingSameAsDelivery) {
            return !!zipCode && !!zipCodeBilling && !!city && !!cityBilling && !!houseNumber && !!houseNumberBilling && !!street && !!streetBilling && !!salutation && !!firstName && !!lastName;
        }
        return !!zipCode && !!street && !!city && !!houseNumber;
    }
    if (formName === 'preSupplierData') {
        var _c = value, desiredDate = _c.desiredDate, dateMoveIn = _c.dateMoveIn, currentMeterReading = _c.currentMeterReading, prevCustomerNum = _c.prevCustomerNum, maLoID = _c.maLoID, meterNumber = _c.meterNumber, newMeterNumber = _c.newMeterNumber, reasonToChange = _c.reasonToChange, dateOfCancellation = _c.dateOfCancellation, prevSupplier = _c.prevSupplier, startOfDelivery = _c.startOfDelivery, terminatePrevSupplier = _c.terminatePrevSupplier;
        // E01
        if (reasonToChange === 'E03') {
            // terminate prev supplier but no desiredDate
            if (terminatePrevSupplier === 'true') {
                if (maLoID)
                    return !!prevSupplier && !!dateOfCancellation && !!maLoID;
                return !!prevSupplier && !!dateOfCancellation;
            }
            // not yet terminate prev supplier but have desiredDate
            if (startOfDelivery !== 'nextPossibleDate') {
                if (maLoID)
                    return !!prevSupplier && !!desiredDate && !!maLoID;
                return !!desiredDate && !!prevSupplier;
            }
            // both arguments true
            if (terminatePrevSupplier === 'true' && startOfDelivery !== 'nextPossibleDate') {
                if (maLoID)
                    return !!prevSupplier && !!desiredDate && !!dateOfCancellation && !!maLoID;
                return !!prevSupplier && !!desiredDate && !!dateOfCancellation;
            }
            // no desiredDate & have terminated prev supplier
            if (maLoID)
                return !!prevSupplier && !!maLoID;
            return !!prevSupplier;
        }
        if (reasonToChange === 'E03') {
            return !!dateMoveIn && !!("" + currentMeterReading) && !!newMeterNumber;
        }
        if (reasonToChange !== 'E03' && reasonToChange !== 'E01') {
            return !!prevCustomerNum && !!meterNumber;
        }
        return true;
    }
    if (formName === 'bankData') {
        var _d = value, agreeSepa = _d.agreeSepa, firstName = _d.firstName, lastName = _d.lastName, bic = _d.bic, iban = _d.iban, creditInstitution = _d.creditInstitution, paymentMethod = _d.paymentMethod;
        if (paymentMethod === 'bankTransfer')
            return true;
        return agreeSepa && !!firstName && !!lastName && !!bic && !!iban && !!creditInstitution;
    }
    return true;
};
export default handleValidation;
