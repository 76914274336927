import { __assign, __awaiter, __decorate, __extends, __generator } from "tslib";
import { Component, Emit, PropSync, Ref } from 'vue-property-decorator';
import { QBtn, QCard, QCardSection, QIcon, QSeparator } from 'quasar';
import { formatDateToGerman, formatMoney } from '../../helpers';
import useLabels from '../../utils/labels';
import { createAPIKey } from '../../utils/serviceProxy';
import Widget from '../Widget';
import labels from './labels';
var TariffConfirmation = /** @class */ (function (_super) {
    __extends(TariffConfirmation, _super);
    function TariffConfirmation() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.kundenart = 0;
        _this.tariffCalculatorIDs = '1,2,3,4,5,6';
        _this.telcoCalculatorIDs = '7';
        _this.shippingCost = 0;
        return _this;
    }
    TariffConfirmation.prototype.created = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getProxyLabels()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(TariffConfirmation.prototype, "baseTelcoPrice", {
        get: function () {
            var _a, _b;
            if (this.selectedTariff.shippingCost && ((_a = this.selectedTariff) === null || _a === void 0 ? void 0 : _a.setupCost))
                return this.selectedTariff.shippingCost + ((_b = this.selectedTariff) === null || _b === void 0 ? void 0 : _b.setupCost);
            return this.selectedTariff.setupCost;
        },
        enumerable: false,
        configurable: true
    });
    TariffConfirmation.prototype.sumpPricePerMonth = function (index) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var sum = 0;
        (_b = (_a = this.selectedTariff) === null || _a === void 0 ? void 0 : _a.optionGroups) === null || _b === void 0 ? void 0 : _b.forEach(function (g) {
            var _a;
            (_a = g.entries) === null || _a === void 0 ? void 0 : _a.forEach(function (options) {
                var _a, _b, _c, _d;
                if (options.isSelected) {
                    if (((_a = options.monthlyPrices) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                        if (index) {
                            var pricePerMonth = (_b = options.monthlyPrices[index]) === null || _b === void 0 ? void 0 : _b.pricePerMonth;
                            if (pricePerMonth) {
                                if (pricePerMonth !== 0)
                                    sum += pricePerMonth * (options.chooseAmount ? options.chooseAmount : 1);
                            }
                            else {
                                var optionsPrice = (_c = options.monthlyPrices[0]) === null || _c === void 0 ? void 0 : _c.pricePerMonth;
                                if (optionsPrice !== 0) {
                                    sum += optionsPrice * (options.chooseAmount ? options.chooseAmount : 1);
                                }
                            }
                        }
                        else {
                            var pricePerMonth = (_d = options.monthlyPrices[0]) === null || _d === void 0 ? void 0 : _d.pricePerMonth;
                            if (pricePerMonth !== 0) {
                                sum += pricePerMonth * (options.chooseAmount ? options.chooseAmount : 1);
                            }
                        }
                    }
                }
            });
        });
        (_d = (_c = this.selectedTariff) === null || _c === void 0 ? void 0 : _c.hardwareGroups) === null || _d === void 0 ? void 0 : _d.forEach(function (g) {
            var _a;
            (_a = g.entries) === null || _a === void 0 ? void 0 : _a.forEach(function (options) {
                var _a, _b;
                if (options.isSelected) {
                    if (((_a = options.monthlyPrices) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                        var pricePerMonth = (_b = options.monthlyPrices[0]) === null || _b === void 0 ? void 0 : _b.pricePerMonth;
                        if (pricePerMonth !== 0) {
                            sum += pricePerMonth * (options.chooseAmount ? options.chooseAmount : 1);
                        }
                    }
                }
            });
        });
        if ((_e = this.selectedTariff) === null || _e === void 0 ? void 0 : _e.monthlyPrices) {
            if (((_f = this.selectedTariff.monthlyPrices) === null || _f === void 0 ? void 0 : _f.length) >= 1 && index) {
                var pricePerMonth = (_g = this.selectedTariff.monthlyPrices[index]) === null || _g === void 0 ? void 0 : _g.pricePerMonth;
                sum += pricePerMonth || 0;
            }
            else {
                var pricePerMonth = (_h = this.selectedTariff.monthlyPrices[0]) === null || _h === void 0 ? void 0 : _h.pricePerMonth;
                sum += pricePerMonth || 0;
            }
        }
        return sum;
    };
    TariffConfirmation.prototype.getProxyLabels = function () {
        return __awaiter(this, void 0, void 0, function () {
            var apikey, getExternalLabels, _a, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.getAPIKey()];
                    case 1:
                        apikey = _b.sent();
                        getExternalLabels = useLabels({ apikey: apikey }).getExternalLabels;
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 4, 5, 6]);
                        _a = this;
                        return [4 /*yield*/, getExternalLabels(this.widgetData.portalDaten.tariffCalculatorTypeId, undefined, 'confirmation')];
                    case 3:
                        _a.customLabelsProxyConfirmation = (_b.sent()).data;
                        return [3 /*break*/, 6];
                    case 4:
                        e_1 = _b.sent();
                        console.error(e_1.message);
                        return [3 /*break*/, 6];
                    case 5:
                        this.syncLabelID += 1;
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    TariffConfirmation.prototype.formatNumber = function (number) {
        return number.toFixed(2).replace('.', ',');
    };
    TariffConfirmation.prototype.getAPIKey = function () {
        return __awaiter(this, void 0, void 0, function () {
            var apikey;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        apikey = this.$q.cookies.get('adserv');
                        if (!!apikey) return [3 /*break*/, 2];
                        return [4 /*yield*/, createAPIKey()];
                    case 1:
                        apikey = _a.sent();
                        this.$q.cookies.set('adserv', apikey, { expires: '5m' });
                        _a.label = 2;
                    case 2: return [2 /*return*/, apikey];
                }
            });
        });
    };
    Object.defineProperty(TariffConfirmation.prototype, "isLabelLoaded", {
        get: function () {
            return this.syncLabelID > 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffConfirmation.prototype, "defaultLabels", {
        get: function () {
            return labels;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffConfirmation.prototype, "labels", {
        get: function () {
            var _a, _b;
            return __assign(__assign({ priceMonthly: 'Gesamt monatlich', priceOnce: 'Gesamt einmalig', shippingCosts: 'Versandkosten' }, labels), (_b = (_a = this.customLabelsProxyConfirmation) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.confirmationPage);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffConfirmation.prototype, "basePrice", {
        get: function () {
            var _a, _b, _c, _d, _e;
            switch ((_a = this.widgetData.vertriebspartnerportal) === null || _a === void 0 ? void 0 : _a.anzeigeNettoBrutto) {
                case 1:
                default:
                    return (_c = (_b = this.selectedTariff) === null || _b === void 0 ? void 0 : _b.grundpreisBrutto) === null || _c === void 0 ? void 0 : _c.toFixed(2).replace('.', ',');
                case 0:
                    return (_e = (_d = this.selectedTariff) === null || _d === void 0 ? void 0 : _d.grundpreisNetto) === null || _e === void 0 ? void 0 : _e.toFixed(2).replace('.', ',');
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffConfirmation.prototype, "basePriceTelco", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.selectedTariff) === null || _a === void 0 ? void 0 : _a.basicPrice) === null || _b === void 0 ? void 0 : _b.toFixed(2).replace('.', ',');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffConfirmation.prototype, "terminwunsch", {
        get: function () {
            return this.widgetData.portalDaten.terminwunsch && formatDateToGerman(this.widgetData.portalDaten.terminwunsch);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffConfirmation.prototype, "workingPrice", {
        get: function () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
            if ((_a = this.selectedTariff) === null || _a === void 0 ? void 0 : _a.arbeitspreisCtKWhBrutto) {
                switch ((_b = this.widgetData.vertriebspartnerportal) === null || _b === void 0 ? void 0 : _b.anzeigeNettoBrutto) {
                    case 1:
                    default:
                        return (_d = (_c = this.selectedTariff) === null || _c === void 0 ? void 0 : _c.arbeitspreisCtKWhBrutto) === null || _d === void 0 ? void 0 : _d.toFixed(2).replace('.', ',');
                    case 0:
                        return (_f = (_e = this.selectedTariff) === null || _e === void 0 ? void 0 : _e.arbeitspreisCtKWhNetto) === null || _f === void 0 ? void 0 : _f.toFixed(2).replace('.', ',');
                }
            }
            switch ((_g = this.widgetData.vertriebspartnerportal) === null || _g === void 0 ? void 0 : _g.anzeigeNettoBrutto) {
                case 1:
                default:
                    return (_j = (_h = this.selectedTariff) === null || _h === void 0 ? void 0 : _h.arbeitspreisHTCtKWhBrutto) === null || _j === void 0 ? void 0 : _j.toFixed(2).replace('.', ',');
                case 0:
                    return (_l = (_k = this.selectedTariff) === null || _k === void 0 ? void 0 : _k.arbeitspreisHTCtKWhNetto) === null || _l === void 0 ? void 0 : _l.toFixed(2).replace('.', ',');
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffConfirmation.prototype, "workingPriceHT", {
        get: function () {
            var _a, _b, _c, _d, _e;
            switch ((_a = this.widgetData.vertriebspartnerportal) === null || _a === void 0 ? void 0 : _a.anzeigeNettoBrutto) {
                case 1:
                default:
                    return (_c = (_b = this.selectedTariff) === null || _b === void 0 ? void 0 : _b.arbeitspreisHTCtKWhBrutto) === null || _c === void 0 ? void 0 : _c.toFixed(2).replace('.', ',');
                case 0:
                    return (_e = (_d = this.selectedTariff) === null || _d === void 0 ? void 0 : _d.arbeitspreisHTCtKWhNetto) === null || _e === void 0 ? void 0 : _e.toFixed(2).replace('.', ',');
            }
        },
        enumerable: false,
        configurable: true
    });
    TariffConfirmation.prototype.getGesamtpreisBN = function () {
        var _a;
        switch ((_a = this.widgetData.vertriebspartnerportal) === null || _a === void 0 ? void 0 : _a.anzeigeNettoBrutto) {
            case 0:
                return this.selectedTariff.gesamtpreisNetto;
            case 1:
                return this.selectedTariff.gesamtpreisBrutto;
            default:
                return this.selectedTariff.gesamtpreisBrutto;
        }
    };
    TariffConfirmation.prototype.getErsparnisBN = function () {
        var _a;
        switch ((_a = this.widgetData.vertriebspartnerportal) === null || _a === void 0 ? void 0 : _a.anzeigeNettoBrutto) {
            case 0:
                return this.selectedTariff.ersparnisNetto;
            case 1:
                return this.selectedTariff.ersparnisBrutto;
            default:
                return this.selectedTariff.ersparnisBrutto;
        }
    };
    Object.defineProperty(TariffConfirmation.prototype, "workingPriceNT", {
        get: function () {
            var _a, _b, _c, _d, _e;
            switch ((_a = this.widgetData.vertriebspartnerportal) === null || _a === void 0 ? void 0 : _a.anzeigeNettoBrutto) {
                case 1:
                default:
                    return (_c = (_b = this.selectedTariff) === null || _b === void 0 ? void 0 : _b.arbeitspreisNTCtKWhBrutto) === null || _c === void 0 ? void 0 : _c.toFixed(2).replace('.', ',');
                case 0:
                    return (_e = (_d = this.selectedTariff) === null || _d === void 0 ? void 0 : _d.arbeitspreisNTCtKWhNetto) === null || _e === void 0 ? void 0 : _e.toFixed(2).replace('.', ',');
            }
        },
        enumerable: false,
        configurable: true
    });
    TariffConfirmation.prototype.getCalcWertBN = function (boni) {
        var _a;
        switch ((_a = this.widgetData.vertriebspartnerportal) === null || _a === void 0 ? void 0 : _a.anzeigeNettoBrutto) {
            case 0:
                return boni.calcWertNetto;
            case 1:
                return boni.calcWertBrutto;
            default:
                return boni.calcWertBrutto;
        }
    };
    Object.defineProperty(TariffConfirmation.prototype, "isTelco", {
        get: function () {
            return this.widgetData.portalDaten.tariffCalculatorTypeId ? this.telcoCalculatorIDs.includes(this.widgetData.portalDaten.tariffCalculatorTypeId) : false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffConfirmation.prototype, "contactData", {
        get: function () {
            return this.widgetData.portalDaten;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffConfirmation.prototype, "selectedTariff", {
        get: function () {
            return this.widgetData.tarifErgebnis;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffConfirmation.prototype, "isMovingOut", {
        get: function () {
            return this.contactData.einzug_umzug === 'E01';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffConfirmation.prototype, "isChangeSupplier", {
        get: function () {
            return this.contactData.einzug_umzug === 'E03';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffConfirmation.prototype, "isChangeTariff", {
        get: function () {
            return this.contactData.einzug_umzug === 'tarifwechsel';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffConfirmation.prototype, "contactDataBirthday", {
        get: function () {
            return this.contactData.la_geburtsdatum && formatDateToGerman(this.contactData.la_geburtsdatum);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffConfirmation.prototype, "movingInDate", {
        get: function () {
            return this.contactData.datum_einzug_umzug && formatDateToGerman(this.contactData.datum_einzug_umzug);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffConfirmation.prototype, "hasUsedTemplate", {
        get: function () {
            return this.widgetData.hasUsedTemplate;
        },
        enumerable: false,
        configurable: true
    });
    TariffConfirmation.prototype.formatValue = function (value) {
        return formatMoney(value);
    };
    TariffConfirmation.prototype.downloadPDF = function () {
        var value = this.$q.cookies.get('allowed');
        if (value) {
            return window.open(this.widgetData.portalDaten.PDF_generatetUrl, '_blank');
        }
        return this.$q
            .dialog({
            title: 'Ihre Session ist abgelaufen',
            message: 'Ein Download ist nun nicht mehr möglich.',
            cancel: false,
            persistent: true
        })
            .onOk(function () {
            // this.goToInput();
        })
            .onCancel(function () {
            // nothing to do
        });
    };
    TariffConfirmation.prototype.goToInput = function () {
        // store.states.presignedDownloadUrl = '';
    };
    __decorate([
        Ref('printPage')
    ], TariffConfirmation.prototype, "div", void 0);
    __decorate([
        PropSync('labelID', { type: Number })
    ], TariffConfirmation.prototype, "syncLabelID", void 0);
    __decorate([
        PropSync('resultList', { default: function () { return []; } })
    ], TariffConfirmation.prototype, "syncResList", void 0);
    __decorate([
        Emit()
    ], TariffConfirmation.prototype, "goToInput", null);
    TariffConfirmation = __decorate([
        Component({
            components: {
                QCardSection: QCardSection,
                QCard: QCard,
                QBtn: QBtn,
                QIcon: QIcon,
                QSeparator: QSeparator
            }
        })
    ], TariffConfirmation);
    return TariffConfirmation;
}(Widget));
export default TariffConfirmation;
