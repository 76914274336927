import dayjs from 'dayjs';
export var dateNow = function (format) { return dayjs().format(format); };
export var INTERNAL_DATE_FORMAT = 'YYYY-MM-DD';
export var INTERNAL_DATETIME_FORMAT = 'YYYY-MM-DDThh:mm:ss';
export var INTERNAL_DATETIME_FORMAT_ZZ = 'YYYY-MM-DDThh:mm:ssZ';
export var GERMAN_DATE_FORMAT = 'DD.MM.YYYY';
export var formatDateToGerman = function (date) { return dayjs(date).format(GERMAN_DATE_FORMAT); };
export var uuid = function () { return Date.now() * Math.random() * Math.random() * 100000; };
export var EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export var formatDateToInternal = function (date) {
    if (date) {
        if (date.includes('.')) {
            var temp = date.split('.');
            if (temp && temp.length > 0) {
                return temp[2]
                    .concat('-')
                    .concat(temp[1])
                    .concat('-')
                    .concat(temp[0]);
            }
        }
    }
    return date;
};
export var translatePaymentType = function (value) {
    var paymentTypeName = value.toLowerCase();
    if (paymentTypeName.includes('sepa-lastschrift'))
        return 'DirectDebit';
    if (paymentTypeName.includes('gutschrift'))
        return 'CreditAccount';
    return 'BankTransfer';
};
export var formatMoney = function (money) {
    var formatter = new Intl.NumberFormat('de-DE', {
        currency: 'EUR',
        style: 'currency'
    });
    return formatter.format(money);
};
export var createDefinedOrEmptyObject = function (key, value) {
    var _a;
    if (!value) {
        return {};
    }
    return _a = {}, _a[key] = value, _a;
};
export function capitalize(str) {
    var splitStr = str.split(' ');
    for (var i = 0; i < splitStr.length; i += 1) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].slice(1);
    }
    return splitStr.join(' ');
}
export function htmlFree(str) {
    if (str === undefined)
        return str;
    var value = str.replaceAll('<[^>]+>', '');
    value = value.replaceAll(/<[^>]+>/g, '');
    value = value.replaceAll('&auml;', 'ä');
    value = value.replaceAll('&ouml;', 'ö');
    value = value.replaceAll('&uuml;', 'ü');
    value = value.replaceAll('&szlig;', 'ß');
    value = value.replaceAll('&Auml;', 'Ä');
    value = value.replaceAll('&Ouml;', 'Ö');
    value = value.replaceAll('&Uuml;', 'Ü');
    value = value.replaceAll('&amp;', '&');
    value = value.replaceAll('&nbsp;', ' ');
    return value;
}
export function checkIBANValue(str) {
    return /^([A-Z]{2}[ -]?[0-9]{2})(?=(?:[ -]?[A-Z0-9]){9,30}$)((?:[ -]?[A-Z0-9]{3,5}){2,7})([ -]?[A-Z0-9]{1,3})?$/.test(str);
}
export function delay(ms) {
    return new Promise(function (resolve) { return setTimeout(resolve, ms); });
}
