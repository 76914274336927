import { __assign, __awaiter, __generator, __read, __rest } from "tslib";
import { contextMapping } from '../models/Simplifier';
import { createClient } from './serviceProxy';
var useTariffWebService = function (_a, context) {
    var apikey = _a.apikey;
    var client = createClient({ apikey: apikey });
    var ctx = contextMapping[context];
    // naming problem between Simplifier and Tariff WS
    var ENDPOINT_PATH_FILTER_RESULT = "/ws/tariff/" + (ctx === 'energy' ? 'energie' : ctx) + "/getBerechneteTarife";
    var ENDPOINT_PATH_PRE_SUPPLIER = "/ws/tariff/" + (ctx === 'energy' ? 'energie' : ctx) + "/getVersorger";
    var ENDPOINT_PATH_COMPARETARIFF = "/ws/tariff/" + (ctx === 'energy' ? 'energie' : ctx) + "/getVersorgerTarifvarianten";
    var ENDPOINT_PATH_TARIFF_CARD = "/ws/tariff/" + (ctx === 'energy' ? 'energie' : ctx) + "/getTarifkarte";
    var ENDPOINT_PATH_UNIVERSALL = "/ws/tariff/" + (ctx === 'energy' ? 'energie' : ctx) + "/getUniversalinformation";
    var getFilterResult = function (_a) {
        var plz = _a.zipCode, ort = _a.city, verbrauchHT = _a.usageHT, verbrauchNT = _a.usageNT, bestandskundenTarif = _a.bestandskundenTarif, bonus = _a.bonus, fixpreis = _a.fixpreis, grenzenBeiATignorierenBis = _a.grenzenBeiATignorierenBis, grundversorgungstarife = _a.grundversorgungstarife, handlingTarif = _a.handlingTarif, kaution = _a.kaution, oeko = _a.oeko, paket = _a.paket, portalRichtlinie = _a.portalRichtlinie, preisgarantie = _a.preisgarantie, smartmeter = _a.smartmeter, sonderabschlag = _a.sonderabschlag, tarifeVonGrundversorgern = _a.tarifeVonGrundversorgern, tariftyp = _a.tariftyp, vergleichsRichtlinie = _a.vergleichsRichtlinie, vorkasse = _a.vorkasse, grundversorger = _a.grundversorger, netzbetreiber = _a.netzbetreiber, kundenart = _a.kundenart, netzgebiet = _a.netzgebiet, vertragslaufzeitBis = _a.vertragslaufzeitBis, kuendigungsfrist = _a.kuendigungsfrist, preisgarantieDauer = _a.preisgarantieDauer, seitenGroesse = _a.seitenGroesse, seitenNr = _a.seitenNr, versorgerIDs = _a.versorgerIDs, sortierung = _a.sortierung, details = _a.details;
        return __awaiter(void 0, void 0, void 0, function () {
            var resFilterResult, params, smartmeter_1, sortierung_1, verbrauchNT_1, verbrauchHT_1, restData, data, res, _b, containerData, res, _c, containerData, e_1;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!ctx)
                            throw new Error('Context is missing');
                        resFilterResult = null;
                        params = {
                            plz: plz,
                            ort: ort,
                            verbrauchHT: verbrauchHT,
                            kundenart: kundenart,
                            grundversorger: grundversorger !== null && grundversorger !== void 0 ? grundversorger : '',
                            netzbetreiber: netzbetreiber !== null && netzbetreiber !== void 0 ? netzbetreiber : '',
                            netzgebiet: netzgebiet !== null && netzgebiet !== void 0 ? netzgebiet : '',
                            verbrauchNT: verbrauchNT !== null && verbrauchNT !== void 0 ? verbrauchNT : null,
                            tariftyp: tariftyp !== null && tariftyp !== void 0 ? tariftyp : encodeURI('1,2,3'),
                            grenzenBeiATignorierenBis: grenzenBeiATignorierenBis !== null && grenzenBeiATignorierenBis !== void 0 ? grenzenBeiATignorierenBis : 20000,
                            tarifeVonGrundversorgern: tarifeVonGrundversorgern !== null && tarifeVonGrundversorgern !== void 0 ? tarifeVonGrundversorgern : 3,
                            grundversorgungstarife: grundversorgungstarife !== null && grundversorgungstarife !== void 0 ? grundversorgungstarife : 3,
                            handlingTarife: handlingTarif !== null && handlingTarif !== void 0 ? handlingTarif : 3,
                            vergleichsRichtlinie: vergleichsRichtlinie !== null && vergleichsRichtlinie !== void 0 ? vergleichsRichtlinie : 1,
                            oeko: oeko,
                            fixpreis: fixpreis !== null && fixpreis !== void 0 ? fixpreis : 3,
                            vorkasse: vorkasse !== null && vorkasse !== void 0 ? vorkasse : 3,
                            paket: paket !== null && paket !== void 0 ? paket : 3,
                            portalRichtlinie: portalRichtlinie !== null && portalRichtlinie !== void 0 ? portalRichtlinie : 3,
                            bonus: bonus !== null && bonus !== void 0 ? bonus : 3,
                            preisgarantie: preisgarantie !== null && preisgarantie !== void 0 ? preisgarantie : 3,
                            kaution: kaution !== null && kaution !== void 0 ? kaution : 3,
                            sonderabschlag: sonderabschlag !== null && sonderabschlag !== void 0 ? sonderabschlag : 3,
                            smartmeter: smartmeter !== null && smartmeter !== void 0 ? smartmeter : 3,
                            bestandskundenTarif: bestandskundenTarif !== null && bestandskundenTarif !== void 0 ? bestandskundenTarif : 3,
                            vertragslaufzeitBis: vertragslaufzeitBis !== null && vertragslaufzeitBis !== void 0 ? vertragslaufzeitBis : 0,
                            kuendigungsfrist: kuendigungsfrist !== null && kuendigungsfrist !== void 0 ? kuendigungsfrist : 0,
                            preisgarantieDauer: preisgarantieDauer !== null && preisgarantieDauer !== void 0 ? preisgarantieDauer : 0,
                            seitenGroesse: seitenGroesse !== null && seitenGroesse !== void 0 ? seitenGroesse : 20,
                            seitenNr: seitenNr !== null && seitenNr !== void 0 ? seitenNr : 1,
                            versorgerIDs: versorgerIDs !== null && versorgerIDs !== void 0 ? versorgerIDs : '',
                            sortierung: sortierung !== null && sortierung !== void 0 ? sortierung : 1,
                            details: details !== null && details !== void 0 ? details : 1
                        };
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 6, , 7]);
                        if (!(ctx === 'gas')) return [3 /*break*/, 3];
                        smartmeter_1 = params.smartmeter, sortierung_1 = params.sortierung, verbrauchNT_1 = params.verbrauchNT, verbrauchHT_1 = params.verbrauchHT, restData = __rest(params, ["smartmeter", "sortierung", "verbrauchNT", "verbrauchHT"]);
                        data = {
                            parameterObj: [
                                __assign(__assign({}, restData), { verbrauch: verbrauchHT_1, grenzenBeiATignorierenBis: 0, details: 1, kennzahl: 0, limit: 0 })
                            ],
                            sortierung: 1
                        };
                        return [4 /*yield*/, client.post(ENDPOINT_PATH_FILTER_RESULT, data)];
                    case 2:
                        res = _d.sent();
                        _b = __read(res.data.ergebnisContainer, 1), containerData = _b[0];
                        resFilterResult = containerData;
                        _d.label = 3;
                    case 3:
                        if (!(ctx === 'energy')) return [3 /*break*/, 5];
                        return [4 /*yield*/, client.get(ENDPOINT_PATH_FILTER_RESULT, {
                                params: params
                            })];
                    case 4:
                        res = _d.sent();
                        _c = __read(res.data.ergebnisContainer, 1), containerData = _c[0];
                        resFilterResult = containerData;
                        _d.label = 5;
                    case 5: return [3 /*break*/, 7];
                    case 6:
                        e_1 = _d.sent();
                        throw new Error(e_1.message);
                    case 7: return [2 /*return*/, resFilterResult || []];
                }
            });
        });
    };
    var getVerbandsnummer = function (versorger) {
        var _a;
        var vnb = '';
        (_a = versorger === null || versorger === void 0 ? void 0 : versorger.dynamischeAngaben) === null || _a === void 0 ? void 0 : _a.forEach(function (f) {
            if (f.stringObj) {
                f.stringObj.forEach(function (d) {
                    if (d.name === 'verbandsnummer_lieferant')
                        vnb = d.wert;
                });
            }
        });
        return vnb;
    };
    var getSupplierList = function () { return __awaiter(void 0, void 0, Promise, function () {
        var response, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    response = null;
                    if (!ctx)
                        throw new Error('Context is Missing');
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 6, , 7]);
                    if (!(ctx === 'gas')) return [3 /*break*/, 3];
                    return [4 /*yield*/, client.post(ENDPOINT_PATH_PRE_SUPPLIER, {})];
                case 2:
                    response = _a.sent();
                    _a.label = 3;
                case 3:
                    if (!(ctx === 'energy')) return [3 /*break*/, 5];
                    return [4 /*yield*/, client.get(ENDPOINT_PATH_PRE_SUPPLIER)];
                case 4:
                    response = _a.sent();
                    _a.label = 5;
                case 5: return [3 /*break*/, 7];
                case 6:
                    e_2 = _a.sent();
                    throw new Error(e_2.message);
                case 7:
                    if (!response || !response.data)
                        return [2 /*return*/, []];
                    return [2 /*return*/, response.data.data.versorger
                            .map(function (data) { return ({
                            label: data.markenID !== 0 && data.markenName ? data.markenName : data.versorgerName,
                            value: data.markenID !== 0 && data.markenName ? data.markenName : data.versorgerName,
                            key: getVerbandsnummer(data)
                        }); })
                            .sort(function (a, b) { return a.label.localeCompare(b.label); })];
            }
        });
    }); };
    var getCompareSupplierList = function (inputParams) { return __awaiter(void 0, void 0, Promise, function () {
        var response, tarifftyp, e_3, e_4, res;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    response = null;
                    if (!ctx)
                        throw new Error('Context is Missing');
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 9, , 10]);
                    _c.label = 2;
                case 2:
                    _c.trys.push([2, 7, , 8]);
                    if (!(ctx === 'gas')) return [3 /*break*/, 4];
                    return [4 /*yield*/, client.post(ENDPOINT_PATH_COMPARETARIFF, {
                            plz: inputParams.zipCode,
                            kundenart: inputParams.customerType,
                            verbrauch: inputParams.usageHT,
                            netzbetreiber: inputParams.netzbetreiber,
                            grundversorger: inputParams.grundversorger
                        })];
                case 3:
                    response = _c.sent();
                    _c.label = 4;
                case 4:
                    if (!(ctx === 'energy')) return [3 /*break*/, 6];
                    tarifftyp = inputParams.tariftyp;
                    if (inputParams.usageNT && inputParams.tariffCalculatorTypeId === '1')
                        tarifftyp = '1,2';
                    if (inputParams.usageNT && inputParams.tariffCalculatorTypeId === '4' && inputParams.tariftyp === '1')
                        tarifftyp = '1,2';
                    if (!inputParams.usageNT && inputParams.tariffCalculatorTypeId === '4' && inputParams.tariftyp === '1')
                        tarifftyp = '1';
                    if (inputParams.tariffCalculatorTypeId === '4' && inputParams.tariftyp === '5')
                        tarifftyp = '5';
                    return [4 /*yield*/, client.get(ENDPOINT_PATH_COMPARETARIFF.concat("?plz=" + inputParams.zipCode + "&kundenart=" + inputParams.customerType + "&verbrauch=" + inputParams.usageHT + "&netzbetreiber=" + inputParams.netzbetreiber + "&grundversorger=" + inputParams.grundversorger + "&tariftyp=" + tarifftyp), {})];
                case 5:
                    response = _c.sent();
                    _c.label = 6;
                case 6: return [3 /*break*/, 8];
                case 7:
                    e_3 = _c.sent();
                    throw new Error(e_3.message);
                case 8: return [3 /*break*/, 10];
                case 9:
                    e_4 = _c.sent();
                    throw new Error(e_4.message);
                case 10:
                    res = !(response === null || response === void 0 ? void 0 : response.data)
                        ? []
                        : (_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.versorger.map(function (data) { return ({
                            label: data.versorgerName ? data.versorgerName : '',
                            value: data.versorgerID
                        }); }).sort(function (a, b) { return a.label.localeCompare(b.label); });
                    return [2 /*return*/, res.filter(function (v, i, a) { return a.findIndex(function (d) { return d.label === v.label; }) === i; })];
            }
        });
    }); };
    var getCompareTariffList = function (inputParams, vid) { return __awaiter(void 0, void 0, Promise, function () {
        var response, tarifftyp, e_5, resTarif;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    response = null;
                    if (!ctx)
                        throw new Error('Context is Missing');
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 6, , 7]);
                    if (!(ctx === 'gas')) return [3 /*break*/, 3];
                    return [4 /*yield*/, client.post(ENDPOINT_PATH_COMPARETARIFF, {
                            versorgerID: vid,
                            plz: inputParams.zipCode,
                            kundenart: inputParams.customerType,
                            verbrauch: inputParams.usageHT,
                            netzbetreiber: inputParams.netzbetreiber,
                            grundversorger: inputParams.grundversorger
                        })];
                case 2:
                    response = _c.sent();
                    _c.label = 3;
                case 3:
                    if (!(ctx === 'energy')) return [3 /*break*/, 5];
                    tarifftyp = inputParams.tariftyp;
                    if (inputParams.usageNT && inputParams.tariffCalculatorTypeId === '1')
                        tarifftyp = '1,2';
                    if (inputParams.usageNT && inputParams.tariffCalculatorTypeId === '4' && inputParams.tariftyp === '1')
                        tarifftyp = '1,2';
                    if (!inputParams.usageNT && inputParams.tariffCalculatorTypeId === '4' && inputParams.tariftyp === '1')
                        tarifftyp = '1';
                    if (inputParams.tariffCalculatorTypeId === '4' && inputParams.tariftyp === '5')
                        tarifftyp = '5';
                    return [4 /*yield*/, client.get(ENDPOINT_PATH_COMPARETARIFF.concat("?versorgerID=" + vid).concat("&plz=" + inputParams.zipCode + "&kundenart=" + inputParams.customerType + "&verbrauch=" + inputParams.usageHT + "&netzbetreiber=" + inputParams.netzbetreiber + "&grundversorger=" + inputParams.grundversorger + "&tariftyp=" + tarifftyp), {})];
                case 4:
                    //   response = await client.get<Response>(ENDPOINT_PATH_COMPARETARIFF.concat(`?versorgerID=${vid}`), {});
                    response = _c.sent();
                    _c.label = 5;
                case 5: return [3 /*break*/, 7];
                case 6:
                    e_5 = _c.sent();
                    throw new Error(e_5.message);
                case 7:
                    resTarif = (response === null || response === void 0 ? void 0 : response.data) && response.data.data.versorger && response.data.data.versorger.length > 0 && ((_a = response.data.data.versorger[0]) === null || _a === void 0 ? void 0 : _a.tarife)
                        ? (_b = response.data.data.versorger[0]) === null || _b === void 0 ? void 0 : _b.tarife.map(function (data) { return ({
                            label: data.tarifName,
                            value: data.tarifID
                        }); }).sort(function (a, b) { return a.label.localeCompare(b.label); }) : [];
                    return [2 /*return*/, resTarif.filter(function (v, i, a) { return a.findIndex(function (d) { return d.label === v.label; }) === i; })];
            }
        });
    }); };
    var getTariffCart = function (inputParams, tariffRootId, variantenID) { return __awaiter(void 0, void 0, void 0, function () {
        var response, e_6, resTarifCard;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    response = null;
                    if (!ctx)
                        throw new Error('Context is Missing');
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 6, , 7]);
                    if (!(ctx === 'gas')) return [3 /*break*/, 3];
                    return [4 /*yield*/, client.post(ENDPOINT_PATH_TARIFF_CARD, {
                            rootID: tariffRootId,
                            ort: inputParams.city,
                            plz: inputParams.zipCode,
                            netzbetreiber: inputParams.netzbetreiber,
                            grundversorger: inputParams.grundversorger,
                            variantenID: variantenID,
                            ausgabeKonfiguration: 1
                        })];
                case 2:
                    response = _a.sent();
                    _a.label = 3;
                case 3:
                    if (!(ctx === 'energy')) return [3 /*break*/, 5];
                    return [4 /*yield*/, client.get(ENDPOINT_PATH_TARIFF_CARD.concat("?rootID=" + tariffRootId).concat("&plz=" + inputParams.zipCode + "&ort=" + inputParams.city + "&variantenID=" + variantenID + "&netzbetreiber=" + inputParams.netzbetreiber + "&grundversorger=" + inputParams.grundversorger + "&ausgabeKonfiguration=1"), {})];
                case 4:
                    response = _a.sent();
                    _a.label = 5;
                case 5: return [3 /*break*/, 7];
                case 6:
                    e_6 = _a.sent();
                    throw new Error(e_6.message);
                case 7:
                    resTarifCard = response === null || response === void 0 ? void 0 : response.data;
                    if (resTarifCard === null || resTarifCard === void 0 ? void 0 : resTarifCard.data.tarifkarten[0])
                        return [2 /*return*/, resTarifCard === null || resTarifCard === void 0 ? void 0 : resTarifCard.data.tarifkarten[0]];
                    return [2 /*return*/, null];
            }
        });
    }); };
    var getUniversalInformation = function (usageHT, tariffRootId) { return __awaiter(void 0, void 0, void 0, function () {
        var response, e_7, resUniversallInformation;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    response = null;
                    if (!ctx)
                        throw new Error('Context is Missing');
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 6, , 7]);
                    if (!(ctx === 'gas')) return [3 /*break*/, 3];
                    return [4 /*yield*/, client.post(ENDPOINT_PATH_UNIVERSALL, {
                            tarifRootIDs: tariffRootId,
                            verbrauch: usageHT
                        })];
                case 2:
                    response = _a.sent();
                    _a.label = 3;
                case 3:
                    if (!(ctx === 'energy')) return [3 /*break*/, 5];
                    return [4 /*yield*/, client.get(ENDPOINT_PATH_UNIVERSALL.concat("?rootID=" + tariffRootId).concat("&verbrauchHT=" + usageHT + "&tarifRootIDs=" + tariffRootId), {})];
                case 4:
                    response = _a.sent();
                    _a.label = 5;
                case 5: return [3 /*break*/, 7];
                case 6:
                    e_7 = _a.sent();
                    throw new Error(e_7.message);
                case 7:
                    resUniversallInformation = response === null || response === void 0 ? void 0 : response.data;
                    if (resUniversallInformation)
                        return [2 /*return*/, resUniversallInformation === null || resUniversallInformation === void 0 ? void 0 : resUniversallInformation.data];
                    return [2 /*return*/, null];
            }
        });
    }); };
    return { getFilterResult: getFilterResult, getSupplierList: getSupplierList, getCompareTariffList: getCompareTariffList, getCompareSupplierList: getCompareSupplierList, getTariffCart: getTariffCart, getUniversalInformation: getUniversalInformation };
};
export default useTariffWebService;
