import { __assign, __awaiter, __decorate, __extends, __generator, __read } from "tslib";
import { Component, Emit, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { ClosePopup, QBtn, QBtnGroup, QCard, QCardSection, QCheckbox, QDate, QIcon, QInput, QItem, QItemLabel, QItemSection, QRadio, QSelect, QSeparator } from 'quasar';
import dayjs from 'dayjs';
import LoadingWrapper from '../../components/LoadingWrapper.vue';
import { dateNow, delay } from '../../helpers';
import { sendMailData } from '../../utils/cancelationFormMailing';
import useGetDocuments from '../../utils/documents';
import useLabels from '../../utils/labels';
import { createAPIKey } from '../../utils/serviceProxy';
var stages = ['input', 'confirmation'];
var CancellationForm = /** @class */ (function (_super) {
    __extends(CancellationForm, _super);
    function CancellationForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.index = 0;
        _this.showBirthDate = false;
        _this.showCancellationDate = false;
        _this.cancellationDate = '';
        _this.anbieter = null;
        _this.anbieterOptions = [{ grund: [], name: '', backupmail: '', betreff: '', externeURL: '', template: '', zielmail: '', urlId: '', sendermail: '' }];
        _this.zaehlernummer = '';
        _this.salutation = '';
        _this.subject = 1;
        _this.customerType = '';
        _this.marktlokationsid = '';
        _this.vertragsnummer = '';
        _this.kundennummer = '';
        _this.kuendigungsgrund = null;
        _this.kuendigungsgrundOption = [{ id: 0, text: '' }];
        _this.customerOptions = ['Privat', 'Gewerbe'];
        _this.firstName = '';
        _this.lastName = '';
        _this.telephone = '';
        _this.mail = '';
        _this.name = '';
        _this.vorname = '';
        _this.geburtsdatum = '';
        _this.kontakt = false;
        _this.kontaktPerMail = false;
        _this.kontaktPerPost = false;
        _this.zeitPunkt = 'ordentlich';
        _this.zipCode = '';
        _this.city = '';
        _this.street = '';
        _this.houseNumber = '';
        _this.text = '';
        _this.privacyLink = '';
        _this.hasAgreed = false;
        _this.hasEmailError = false;
        _this.labelID = 0;
        _this.labelsReady = false;
        _this.PDF_generatetUrl = '';
        _this.isElectricityEnabled = false;
        _this.isGasEnabled = false;
        _this.isHeizstromEnabled = false;
        _this.isAutostromEnabled = false;
        _this.isTelcoEnabled = false;
        _this.isTHGEnabled = false;
        _this.isDateForCancellationValid = false;
        _this.tarifId = '';
        _this.birthdayValid = false;
        _this.isLoading = false;
        return _this;
    }
    CancellationForm.prototype.mounted = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var apikey, docs, res, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isLoading = true;
                        return [4 /*yield*/, this.getAPIKey()];
                    case 1:
                        apikey = _b.sent();
                        return [4 /*yield*/, this.onIsAvailableTarif()];
                    case 2:
                        _b.sent();
                        return [4 /*yield*/, this.getProxyLabels(null)];
                    case 3:
                        _b.sent();
                        this.tarifId = this.tariffCalculatorTypeId;
                        _b.label = 4;
                    case 4:
                        _b.trys.push([4, 6, , 7]);
                        docs = useGetDocuments({ apikey: apikey });
                        return [4 /*yield*/, docs.getDocuments(parseFloat(this.tariffCalculatorTypeId), undefined)];
                    case 5:
                        res = _b.sent();
                        this.privacyLink = (_a = res === null || res === void 0 ? void 0 : res.data.privacyUrl) !== null && _a !== void 0 ? _a : '';
                        return [3 /*break*/, 7];
                    case 6:
                        e_1 = _b.sent();
                        throw new Error(e_1.message);
                    case 7:
                        if (this.anbieterOptions.length < 2) {
                            // eslint-disable-next-line prefer-destructuring
                            this.anbieter = this.anbieterOptions[0];
                        }
                        this.isLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(CancellationForm.prototype, "hasChooseElectric", {
        get: function () {
            return this.tarifId === '1';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CancellationForm.prototype, "hasChooseGas", {
        get: function () {
            return this.tarifId === '2';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CancellationForm.prototype, "hasChooseHeizstrom", {
        get: function () {
            return this.tarifId === '3';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CancellationForm.prototype, "hasChooseAutostrom", {
        get: function () {
            return this.tarifId === '4';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CancellationForm.prototype, "hasChooseTelco", {
        get: function () {
            return this.tarifId === '7';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CancellationForm.prototype, "hasChooseTHG", {
        get: function () {
            return this.tarifId === '8';
        },
        enumerable: false,
        configurable: true
    });
    CancellationForm.prototype.onHandleTarifTypeChange = function (tarifId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.tarifId = tarifId.toString();
                        return [4 /*yield*/, this.getProxyLabels(tarifId.toString())];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CancellationForm.prototype.onIsAvailableTarif = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        return __awaiter(this, void 0, void 0, function () {
            var apikey, getExternalLabels, _o, e, g, h, a, i, t, _p, _q, _r;
            return __generator(this, function (_s) {
                switch (_s.label) {
                    case 0: return [4 /*yield*/, this.getAPIKey()];
                    case 1:
                        apikey = _s.sent();
                        getExternalLabels = useLabels({ apikey: apikey }).getExternalLabels;
                        _q = (_p = Promise).all;
                        return [4 /*yield*/, getExternalLabels('1', undefined, 'cancellationform')];
                    case 2:
                        _r = [
                            _s.sent()
                        ];
                        return [4 /*yield*/, getExternalLabels('2', undefined, 'cancellationform')];
                    case 3:
                        _r = _r.concat([
                            _s.sent()
                        ]);
                        return [4 /*yield*/, getExternalLabels('3', undefined, 'cancellationform')];
                    case 4:
                        _r = _r.concat([
                            _s.sent()
                        ]);
                        return [4 /*yield*/, getExternalLabels('4', undefined, 'cancellationform')];
                    case 5:
                        _r = _r.concat([
                            _s.sent()
                        ]);
                        return [4 /*yield*/, getExternalLabels('7', undefined, 'cancellationform')];
                    case 6:
                        _r = _r.concat([
                            _s.sent()
                        ]);
                        return [4 /*yield*/, getExternalLabels('8', undefined, 'cancellationform')];
                    case 7: return [4 /*yield*/, _q.apply(_p, [_r.concat([
                                _s.sent()
                            ])])];
                    case 8:
                        _o = __read.apply(void 0, [_s.sent(), 6]), e = _o[0], g = _o[1], h = _o[2], a = _o[3], i = _o[4], t = _o[5];
                        this.isElectricityEnabled = !!((_b = (_a = e === null || e === void 0 ? void 0 : e.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.cancellationform);
                        this.isGasEnabled = !!((_d = (_c = g === null || g === void 0 ? void 0 : g.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.cancellationform);
                        this.isHeizstromEnabled = !!((_f = (_e = h === null || h === void 0 ? void 0 : h.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.cancellationform);
                        this.isAutostromEnabled = !!((_h = (_g = a === null || a === void 0 ? void 0 : a.data) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.cancellationform);
                        this.isTelcoEnabled = !!((_k = (_j = i === null || i === void 0 ? void 0 : i.data) === null || _j === void 0 ? void 0 : _j.data) === null || _k === void 0 ? void 0 : _k.cancellationform);
                        this.isTHGEnabled = !!((_m = (_l = t === null || t === void 0 ? void 0 : t.data) === null || _l === void 0 ? void 0 : _l.data) === null || _m === void 0 ? void 0 : _m.cancellationform);
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(CancellationForm.prototype, "showZaehlernummer", {
        get: function () {
            var _a, _b, _c;
            return ((_c = (_b = (_a = this.customLabelsProxyInput) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.functionscancellationform) === null || _c === void 0 ? void 0 : _c.showZaehlernummer) !== false;
        },
        enumerable: false,
        configurable: true
    });
    CancellationForm.prototype.externLink = function () {
        var _a;
        return (_a = this.anbieter) === null || _a === void 0 ? void 0 : _a.externeURL;
    };
    CancellationForm.prototype.kuendigungsGruende = function () {
        var _a, _b, _c;
        this.kuendigungsgrundOption = ((_a = this.anbieter) === null || _a === void 0 ? void 0 : _a.grund) || [{ id: 0, text: '' }];
        if (this.anbieter && ((_c = (_b = this.anbieter) === null || _b === void 0 ? void 0 : _b.grund) === null || _c === void 0 ? void 0 : _c.length) < 2) {
            // eslint-disable-next-line prefer-destructuring
            this.kuendigungsgrund = this.kuendigungsgrundOption[0];
        }
    };
    CancellationForm.prototype.checkkuendigungsgrund = function () {
        var _a;
        return this.anbieter && ((_a = this.anbieter) === null || _a === void 0 ? void 0 : _a.grund.length) < 1;
    };
    CancellationForm.prototype.checkanbieter = function () {
        return this.anbieterOptions.length < 2;
    };
    CancellationForm.prototype.showMail = function () {
        return !this.kontaktPerMail;
    };
    CancellationForm.prototype.getProxyLabels = function (tarifId) {
        return __awaiter(this, void 0, void 0, function () {
            var apikey, getExternalLabels, _a, _b, anbieter, e_2;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.getAPIKey()];
                    case 1:
                        apikey = _c.sent();
                        getExternalLabels = useLabels({ apikey: apikey }).getExternalLabels;
                        _c.label = 2;
                    case 2:
                        _c.trys.push([2, 4, 5, 6]);
                        _a = this;
                        return [4 /*yield*/, getExternalLabels(tarifId || this.tariffCalculatorTypeId, undefined, 'cancellationform')];
                    case 3:
                        _a.customLabelsProxyInput = (_c.sent()).data;
                        this.anbieterOptions = this.customLabelsProxyInput.data.functionscancellationform.anbieterauswahl;
                        if (this.anbieterOptions.length < 2) {
                            _b = __read(this.anbieterOptions, 1), anbieter = _b[0];
                            this.anbieter = anbieter;
                        }
                        return [3 /*break*/, 6];
                    case 4:
                        e_2 = _c.sent();
                        throw new Error(e_2.message);
                    case 5:
                        this.labelsReady = true;
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    CancellationForm.prototype.dateOptionsBirthday = function (date) {
        return (date <=
            dayjs()
                .add(-18, 'year')
                .format('YYYY/MM/DD'));
    };
    CancellationForm.prototype.dateOptionsCancelationDate = function (date) {
        return date >= dayjs().format('YYYY/MM/DD');
    };
    Object.defineProperty(CancellationForm.prototype, "stage", {
        get: function () {
            return stages[this.index % stages.length];
        },
        enumerable: false,
        configurable: true
    });
    // get age include leap year
    CancellationForm.prototype.getAge = function (birthDate) {
        var now = new Date();
        function isLeap(year) {
            return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
        }
        var days = Math.floor((now.getTime() - birthDate.getTime()) / 1000 / 60 / 60 / 24);
        var age = 0;
        for (var y = birthDate.getFullYear(); y <= now.getFullYear(); y += 1) {
            var daysInYear = isLeap(y) ? 366 : 365;
            if (days >= daysInYear) {
                days -= daysInYear;
                age += 1;
            }
        }
        return age;
    };
    CancellationForm.prototype.validateDate = function (val) {
        this.isDateForCancellationValid = false;
        if (!val || val.length < 10)
            return 'Bitte geben Sie ein gültiges Datum ein!';
        var pickedDate = val
            .split('.')
            .reverse()
            .join('-');
        var isPastDate = new Date() > new Date(pickedDate);
        if (isPastDate)
            return this.labels.errorFieldFutureYear;
        this.isDateForCancellationValid = true;
        return this.isDateForCancellationValid;
    };
    CancellationForm.prototype.validateBirthday = function (val) {
        this.birthdayValid = false;
        if (!val || val.length < 10)
            return 'Bitte geben Sie ein gültiges Datum ein!';
        // leap year
        var dateOfBirthday = val
            .split('.')
            .reverse()
            .toString()
            .replace(/,/g, '');
        var parts = dateOfBirthday.match(/(\d{4})(\d{2})(\d{2})/);
        if (!parts) {
            return 'Bitte geben Sie ein gültiges Datum ein!';
        }
        var dateObj = new Date(Number(parts[1]), Number(parts[2]) - 1, Number(parts[3]));
        var age = this.getAge(dateObj);
        if (age >= 18) {
            this.birthdayValid = true;
            return this.birthdayValid;
        }
        return this.labels.notEighteenYearsOld;
    };
    CancellationForm.prototype.nextStep = function () {
        this.labelID = 0;
        this.index += 1;
    };
    CancellationForm.prototype.backStep = function () {
        this.labelID = 0;
        this.index -= 1;
    };
    Object.defineProperty(CancellationForm.prototype, "labels", {
        get: function () {
            var _a, _b;
            return __assign({ kuendigungsdatum: 'Kündigungsdatum', zurückButton: 'zurück', errorFieldFutureYear: 'Bitte geben Sie ein zukünftiges Datum ein', kontaktheader: 'Kontaktdaten für Kündigungsbestätigung (Mehrfachauswahl möglich)', notEighteenYearsOld: 'Sie müssen mindestens 18 Jahre alt sein.' }, (_b = (_a = this.customLabelsProxyInput) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.cancellationform);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CancellationForm.prototype, "isFormValid", {
        get: function () {
            var _a = this, name = _a.name, vorname = _a.vorname, isDateForCancellationValid = _a.isDateForCancellationValid, kontaktPerPost = _a.kontaktPerPost, birthdayValid = _a.birthdayValid, zipCode = _a.zipCode, street = _a.street, houseNumber = _a.houseNumber, city = _a.city, anbieter = _a.anbieter, kundennummer = _a.kundennummer, kuendigungsgrund = _a.kuendigungsgrund, hasAgreed = _a.hasAgreed, zeitPunkt = _a.zeitPunkt, kontaktPerMail = _a.kontaktPerMail;
            if (!kuendigungsgrund)
                return false;
            return (anbieter &&
                name &&
                vorname &&
                birthdayValid &&
                zipCode &&
                street &&
                houseNumber &&
                city &&
                kundennummer &&
                kuendigungsgrund.id &&
                hasAgreed &&
                (zeitPunkt === 'ordentlich' || (zeitPunkt === 'wunschtermin' && isDateForCancellationValid)) &&
                ((kontaktPerMail && this.validEmail() && !kontaktPerPost) || (kontaktPerPost && !kontaktPerMail) || (kontaktPerMail && this.validEmail() && kontaktPerPost)));
        },
        enumerable: false,
        configurable: true
    });
    CancellationForm.prototype.isValidEmail = function () {
        if (!this.kontaktPerMail)
            return;
        this.hasEmailError = !this.validEmail();
    };
    CancellationForm.prototype.validEmail = function () {
        if (!this.kontaktPerMail)
            return false;
        var emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
        return emailPattern.test(this.mail);
    };
    CancellationForm.prototype.sendForm = function () {
        return __awaiter(this, void 0, void 0, function () {
            var apikey, body, resp, e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getAPIKey()];
                    case 1:
                        apikey = _a.sent();
                        body = this.formInputData();
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 6, , 7]);
                        if (!body) return [3 /*break*/, 4];
                        return [4 /*yield*/, sendMailData(body, apikey)];
                    case 3:
                        resp = _a.sent();
                        this.PDF_generatetUrl = resp.data.pdfurl;
                        _a.label = 4;
                    case 4:
                        this.$toast({ message: 'Ihre Kündigung wurde erfolgreich gesendet', duration: 2000, type: 'success' });
                        this.$q.cookies.set('allowed', JSON.stringify(true), { expires: '5m' });
                        return [4 /*yield*/, delay(1200)];
                    case 5:
                        _a.sent();
                        this.nextStep();
                        return [3 /*break*/, 7];
                    case 6:
                        e_3 = _a.sent();
                        this.$toast({ duration: 2000, message: e_3.toString(), type: 'error' });
                        throw new Error(e_3.message);
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    CancellationForm.prototype.getAPIKey = function () {
        return __awaiter(this, void 0, void 0, function () {
            var apikey, newAPIKey;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        apikey = this.$q.cookies.get('adserv');
                        if (!!apikey) return [3 /*break*/, 2];
                        return [4 /*yield*/, createAPIKey()];
                    case 1:
                        newAPIKey = _a.sent();
                        this.$q.cookies.set('adserv', newAPIKey, { expires: '4m' });
                        return [2 /*return*/, newAPIKey];
                    case 2: return [2 /*return*/, apikey];
                }
            });
        });
    };
    CancellationForm.prototype.formInputData = function () {
        var _a, _b, _c, _d, _e, _f, _g;
        if (!this.kuendigungsgrund) {
            throw new Error('kuendigungsgrund is undefined');
        }
        var confi = {
            backupmail: ((_a = this.anbieter) === null || _a === void 0 ? void 0 : _a.backupmail) || '',
            betreff: ((_b = this.anbieter) === null || _b === void 0 ? void 0 : _b.betreff) || '',
            sendermail: ((_c = this.anbieter) === null || _c === void 0 ? void 0 : _c.sendermail) || '',
            template: ((_d = this.anbieter) === null || _d === void 0 ? void 0 : _d.template) || '',
            zielmail: ((_e = this.anbieter) === null || _e === void 0 ? void 0 : _e.zielmail) || '',
            urlId: ((_f = this.anbieter) === null || _f === void 0 ? void 0 : _f.urlId) || ''
        };
        var product = '';
        switch (this.tarifId) {
            case '1':
                product = 'Strom';
                break;
            case '2':
                product = 'Gas';
                break;
            case '3':
                product = 'Strom Heizstrom (Wärmepumpe oder Speicherheizung)';
                break;
            case '4':
                product = 'Strom Autostrom';
                break;
            case '5':
                product = 'Strom Energiepreis SLP';
                break;
            case '6':
                product = 'Gas Energiepreis SLP';
                break;
            case '7':
                product = 'Telekommunikation / Internet ( DSL, Glasfaser)';
                break;
            case '8':
                product = 'THG Quote';
                break;
            default:
                product = '';
                break;
        }
        var body = {
            Emailadresse: this.mail,
            Geburtsdatum: this.geburtsdatum,
            Grund: this.kuendigungsgrund.text,
            Hausnummer: this.houseNumber,
            Kundennummer: this.kundennummer,
            MarktlokationsID: this.marktlokationsid,
            Name: this.name,
            Ort: this.city,
            perMail: this.kontaktPerMail ? 'Ja' : 'Nein',
            perPost: this.kontaktPerPost ? 'Ja' : 'Nein',
            PLZ: this.zipCode,
            Strasse: this.street,
            Timestamp: dateNow('DD.MM.YYYY, hh:mm:ss'),
            Vertragsende: this.zeitPunkt === 'wunschtermin' ? this.cancellationDate : 'nächstmöglicher Termin',
            Vertragsnummer: this.vertragsnummer,
            Vorname: this.vorname,
            Zaehlernummer: this.zaehlernummer,
            Versorger: ((_g = this.anbieter) === null || _g === void 0 ? void 0 : _g.name) || '',
            Produkt: product
        };
        return { body: body, konfig: confi };
    };
    CancellationForm.prototype.downloadPDF = function () {
        var value = this.$q.cookies.get('allowed');
        if (value) {
            return window.open(this.PDF_generatetUrl, '_blank');
        }
        return this.$q.dialog({
            title: 'Ihre Session ist abgelaufen',
            message: 'Ein Download ist nun nicht mehr möglich.',
            cancel: false,
            persistent: true
        });
    };
    CancellationForm.prototype.goBackToTR = function () { };
    __decorate([
        Prop({ default: function () { return ({}); } })
    ], CancellationForm.prototype, "styles", void 0);
    __decorate([
        PropSync('labelID', { type: Number })
    ], CancellationForm.prototype, "syncLabelID", void 0);
    __decorate([
        Prop({ required: false, default: '' })
    ], CancellationForm.prototype, "externalCss", void 0);
    __decorate([
        Prop({ required: false, default: '1' })
    ], CancellationForm.prototype, "tariffCalculatorTypeId", void 0);
    __decorate([
        Watch('anbieter')
    ], CancellationForm.prototype, "kuendigungsGruende", null);
    __decorate([
        Emit()
    ], CancellationForm.prototype, "goBackToTR", null);
    CancellationForm = __decorate([
        Component({
            components: {
                QCard: QCard,
                QCardSection: QCardSection,
                QIcon: QIcon,
                QItem: QItem,
                QItemLabel: QItemLabel,
                QItemSection: QItemSection,
                QInput: QInput,
                QBtn: QBtn,
                QBtnGroup: QBtnGroup,
                QSelect: QSelect,
                QCheckbox: QCheckbox,
                QDate: QDate,
                QSeparator: QSeparator,
                QRadio: QRadio,
                LoadingWrapper: LoadingWrapper
            },
            directives: {
                ClosePopup: ClosePopup
            }
        })
    ], CancellationForm);
    return CancellationForm;
}(Vue));
export default CancellationForm;
