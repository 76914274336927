var labels = {
    unitUsage: 'kWh/Jahr',
    extraChange: 'Aufschlag',
    strommix: 'Strommix',
    onlyGasEco: 'Nur Ökogas',
    onlyElectricityEco: 'Nur Ökostrom',
    showTariffDetail: 'Tarifdetails anzeigen',
    unitPerYear: '/ Jahr',
    unitPerMonth: '/ Monat',
    tryAgain: 'Über den Button "Suchkriterien ändern" können Sie diese anpassen.',
    provider: 'Netzbetreiber',
    merchant: 'Grundversorger',
    tariffType: 'Zählerart',
    oneTariff: 'Eintarif',
    twoTariffs: 'Zweitarif',
    customerType: 'Kundenart',
    business: 'Gewerbe',
    private: 'Privat',
    usageInvalidMessage: 'dieser Wert ist nicht erlaubt',
    usageNT: 'Verbrauch (NT)',
    usageHT: 'Verbrauch (HT)',
    usage: 'Verbrauch',
    person: 'Personen',
    header: 'Ergebnisliste',
    headerOptions: 'Produktoptionen',
    headerSub: 'Ihre Suchkriterien:',
    saving: 'Ersparnis zum Vergleichstarif:',
    savingTariff: 'Ihr Vergleichstarif:',
    cancellationperiod: 'Kündigungfrist',
    priceInformation: 'Preisinformation',
    priceguarantee: 'Preisgarantie',
    contractperiod: 'Vertragslaufzeit',
    contractextension: 'Vertragsverlängerung',
    totalprice: 'Gesamtpreis',
    totalpriceAfterBonus: '(nach Abzug aller Boni)',
    totalpriceBeforeBonus: '(vor Abzug aller Boni, inklusive Aufschläge)',
    baseprice: 'Grundpreis',
    workingprice: 'Arbeitspreis',
    workingpriceNT: 'Arbeitspreis NT',
    workingpriceHT: 'Arbeitspreis HT',
    buttontext: 'Vertrag abschließen',
    unit: '€',
    basepriceunit: '/ Monat',
    workingpriceunit: 'ct/kWh',
    tariffdescription: 'Tarifbeschreibung',
    isecotariff: 'Öko',
    co2: 'CO2-Emmision',
    co2unit: 'g/kWh',
    renewable: 'Erneuerbare Energien nach EEG',
    renewableunit: '%',
    fossil: 'Fossile Energien',
    fossilunit: '%',
    nuclear: 'Kernkraft',
    nuclearunit: '%',
    radioactiv: 'radioaktiver Abfall',
    radioactivunit: 'g/kWh',
    stand: 'Stand',
    isprepaidtariff: 'Vorkassetarif',
    ispackagetariff: 'Pakettarif',
    isprepaidtarifftrue: 'ja',
    isprepaidtarifffalse: 'nein',
    ispackagetarifftrue: 'ja',
    ispackagetarifffalse: 'nein',
    yourDiscount: 'Abschlag (geschätzt):',
    discountText: '(Der Versorger erhebt 11 Abschläge im Jahr.)',
    ecoTarif: 'Ökotarif',
    bonus: 'Boni',
    bonusChange: 'Wechselbonus',
    condition: 'Bedingung',
    backToSearchBtnText: 'Suchkriterien ändern',
    MSB: 'Im Gesamtpreis sind MSB-Entgelte in Höhe von',
    contain: 'enthalten',
    filter: 'Filtern',
    search: 'Suchen',
    noListProduct: 'Leider wurden zu Ihren Suchkriterien keine passenden Angebote gefunden.',
    yes: 'ja',
    no: 'nein',
    irrelevant: 'egal',
    waermepumpe: 'Wärmepumpe',
    speicherheizung: 'Speicherheizung',
    tarifftypeName: 'Tariftyp',
    autoStromGemeinsameMessung: 'Autostrom gemeinsame Messung',
    autoStromGetrennteMessung: 'Autostrom getrennte Messung',
    internetSpeedUnit: 'Mbit/s',
    priceMonthly: 'Gesamt monatlich',
    priceOnce: 'Gesamt einmalig',
    setupCost: 'Aktivierungskosten',
    periodOnce: 'einmalig',
    periodMonthly: ' monatlich',
    periodOnceSummary: 'Ihre einmaligen Kosten',
    periodMonthlySummary: 'Ihre monatlichen Kosten',
    backToOptionsBtnText: 'Zurück zur Tarifauswahl',
    optionInfo: 'Produktinformationsblatt (PDF)',
    shippingCosts: 'Versandkosten',
    tariffDetails: 'Tarifdetails',
    productDescription: 'Produktbeschreibung',
    missingProductDescription: 'Dieses Produkt hat keine Beschreibung',
    missingTariffDetails: ' Dieses Produkt hat kein Tarifdetails',
    downloadSpeedLabel: 'Download mit bis zu ',
    uploadSpeedLabel: 'und Upload mit bis zu ',
    infinityPeriod: 'auf unbestimmte Zeit',
    TCS_Btn: 'ändern und neu berechnen'
};
export default labels;
