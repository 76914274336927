// eslint-disable-next-line import/no-cycle
import bake from './bake';
import Toast from './Toast.vue';
var plugin = {
    install: function (Vue) {
        Vue.component('toast', Toast);
        // eslint-disable-next-line no-param-reassign
        Vue.prototype.$toast = bake;
    }
};
export default plugin;
