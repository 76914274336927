import { __decorate, __extends } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import { QCheckbox } from 'quasar';
import store from '../../../store';
import BaseControl from './BaseControl';
var CheckboxControl = /** @class */ (function (_super) {
    __extends(CheckboxControl, _super);
    function CheckboxControl() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(CheckboxControl.prototype, "isOwnerSameAsCustomerProps", {
        get: function () {
            return this.baseInputProps.name === 'ownerSameAsCustomer';
        },
        enumerable: false,
        configurable: true
    });
    CheckboxControl.prototype.onHandleCheck = function (val) {
        if (this.isOwnerSameAsCustomerProps) {
            store.states.ownerSameAsCustomer = val;
        }
        this.emitInput(val);
    };
    Object.defineProperty(CheckboxControl.prototype, "disabled", {
        get: function () {
            var _a;
            return (_a = this.props.disabled) !== null && _a !== void 0 ? _a : false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CheckboxControl.prototype, "html", {
        get: function () {
            var _a;
            return (_a = this.props.html) !== null && _a !== void 0 ? _a : false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CheckboxControl.prototype, "inputProps", {
        get: function () {
            return this.baseInputProps;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop({ required: true })
    ], CheckboxControl.prototype, "props", void 0);
    CheckboxControl = __decorate([
        Component({
            components: { QCheckbox: QCheckbox }
        })
    ], CheckboxControl);
    return CheckboxControl;
}(BaseControl));
export default CheckboxControl;
