import { __assign, __awaiter, __generator } from "tslib";
import { ServerValidationError } from '../widgets/UniversalForm';
import { createClient, PROXY_BASE_URL } from './serviceProxy';
var ENDPOINT_PATH_CUSTOMER = '/mail/sendMailCustomer';
var ENDPOINT_PATH_DISTRIBUTOR = '/mail/sendMailDistribution';
// eslint-disable-next-line import/prefer-default-export
export var sendMailData = function (data, apiKey, s3s3FilePath, customer) { return __awaiter(void 0, void 0, void 0, function () {
    var body, client, formData, response, errors;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                body = {
                    abschlussDaten: [data]
                };
                client = createClient({ url: PROXY_BASE_URL, apikey: apiKey });
                formData = {
                    jsonDaten: body
                };
                return [4 /*yield*/, client.post((customer ? ENDPOINT_PATH_CUSTOMER : ENDPOINT_PATH_DISTRIBUTOR)
                        .concat('?calculatorType=')
                        .concat("" + data.portalDaten.tariffCalculatorTypeId)
                        .concat('&s3FilePath=')
                        .concat("" + s3s3FilePath)
                        .concat('&supplierId=')
                        .concat("" + data.portalDaten.vertriebspartner_id)
                        .concat('&s3PresignedDownloadFilePath=')
                        .concat('&s3PresignedDownloadFileName='), formData)];
            case 1:
                response = _a.sent();
                if (response.data.additionalData.verbindlicher_abschluss && response.data.data.crm.response.abschluesse_FEHLER) {
                    errors = response.data.data.crm.response.abschluesse_FEHLER[0].fehler.reduce(function (acc, nextError) {
                        var _a;
                        var fieldName = nextError.schluessel;
                        var errorMessage = nextError.meldung;
                        return __assign(__assign({}, acc), (_a = {}, _a[fieldName] = errorMessage, _a));
                    }, {});
                    // TODO: handle displaying server errors
                    throw new ServerValidationError(errors);
                }
                return [2 /*return*/, response];
        }
    });
}); };
export var sendMailDataPresigned = function (data, apiKey, s3PresignedDownloadFilePath, s3PresignedDownloadFileName, externFileList, customer) { return __awaiter(void 0, void 0, void 0, function () {
    var body, client, formData, response, errors;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                body = {
                    abschlussDaten: [data]
                };
                client = createClient({ url: PROXY_BASE_URL, apikey: apiKey });
                formData = {
                    jsonDaten: body
                };
                return [4 /*yield*/, client.post((customer ? ENDPOINT_PATH_CUSTOMER : ENDPOINT_PATH_DISTRIBUTOR)
                        .concat('?calculatorType=')
                        .concat("" + data.portalDaten.tariffCalculatorTypeId)
                        .concat('&s3PresignedDownloadFilePath=')
                        .concat("" + s3PresignedDownloadFilePath)
                        .concat('&supplierId=')
                        .concat("" + data.portalDaten.vertriebspartner_id)
                        .concat('&s3PresignedDownloadFileName=')
                        .concat("" + s3PresignedDownloadFileName)
                        .concat('&externFileList=')
                        .concat("" + externFileList), formData)];
            case 1:
                response = _a.sent();
                if (response.data.additionalData.verbindlicher_abschluss && response.data.data.crm.response.abschluesse_FEHLER) {
                    errors = response.data.data.crm.response.abschluesse_FEHLER[0].fehler.reduce(function (acc, nextError) {
                        var _a;
                        var fieldName = nextError.schluessel;
                        var errorMessage = nextError.meldung;
                        return __assign(__assign({}, acc), (_a = {}, _a[fieldName] = errorMessage, _a));
                    }, {});
                    // TODO: handle displaying server errors
                    throw new ServerValidationError(errors);
                }
                return [2 /*return*/, response];
        }
    });
}); };
