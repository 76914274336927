import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { QCard, QCardSection, QSeparator } from 'quasar';
var FormCard = /** @class */ (function (_super) {
    __extends(FormCard, _super);
    function FormCard() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(FormCard.prototype, "title", {
        get: function () {
            return this.props.title;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop({ default: function () { return ({}); } })
    ], FormCard.prototype, "props", void 0);
    FormCard = __decorate([
        Component({
            components: { QCard: QCard, QCardSection: QCardSection, QSeparator: QSeparator }
        })
    ], FormCard);
    return FormCard;
}(Vue));
export default FormCard;
