import { __decorate, __extends, __read, __spread } from "tslib";
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { QBtn } from 'quasar';
import store from '../../../store';
var FormSkipButton = /** @class */ (function (_super) {
    __extends(FormSkipButton, _super);
    function FormSkipButton() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(FormSkipButton.prototype, "classes", {
        get: function () {
            if (this.props.classes) {
                if (this.props.classes.includes('tr-mobile')) {
                    var arrClasses = this.props.classes.split(' ');
                    var addNewClass = __spread(arrClasses, ['full-width']);
                    return addNewClass.join(' ');
                }
                return this.props.classes;
            }
            return '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FormSkipButton.prototype, "isShow", {
        get: function () {
            return store.states[this.props.name];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FormSkipButton.prototype, "disable", {
        get: function () {
            return false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FormSkipButton.prototype, "title", {
        get: function () {
            return this.props.title;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Inject()
    ], FormSkipButton.prototype, "skipForm", void 0);
    __decorate([
        Prop({ required: true })
    ], FormSkipButton.prototype, "formMetadata", void 0);
    __decorate([
        Prop({ required: true })
    ], FormSkipButton.prototype, "props", void 0);
    FormSkipButton = __decorate([
        Component({ components: { QBtn: QBtn } })
    ], FormSkipButton);
    return FormSkipButton;
}(Vue));
export default FormSkipButton;
