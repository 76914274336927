import { __assign, __extends, __read } from "tslib";
import { boolean, date, number, object, string, ValidationError } from 'yup';
import { resolveDateOperations } from './dateOperations';
import { EQUALS_TRUE_RULE_NAME, MAX_RULE_NAME, MIN_RULE_NAME, REGEX_RULE_NAME, REQUIRED_RULE_NAME } from './models/ValidationRules';
function createRule(ruleName, ruleParams) {
    var _a;
    var argsArray = (_a = (!Array.isArray(ruleParams) ? [ruleParams] : ruleParams)) !== null && _a !== void 0 ? _a : [];
    switch (ruleName) {
        case REQUIRED_RULE_NAME:
            return {
                name: REQUIRED_RULE_NAME,
                message: argsArray[0],
                condition: argsArray[1]
            };
        case REGEX_RULE_NAME:
            return {
                name: ruleName,
                regex: new RegExp(argsArray[0]),
                message: argsArray[1],
                condition: argsArray[2]
            };
        case MIN_RULE_NAME:
            return {
                name: ruleName,
                min: argsArray[0],
                message: argsArray[1]
            };
        case MAX_RULE_NAME:
            return {
                name: ruleName,
                max: argsArray[0],
                message: argsArray[1]
            };
        case EQUALS_TRUE_RULE_NAME:
            return {
                name: ruleName,
                message: argsArray[0],
                condition: argsArray[1]
            };
        default:
            return null;
    }
}
var createFieldSchema = function (fieldDefinition) {
    var _a;
    var baseSchema = string();
    if (fieldDefinition.type === 'number') {
        baseSchema = number();
    }
    if (fieldDefinition.type === 'boolean') {
        baseSchema = boolean();
    }
    if (fieldDefinition.type === 'date') {
        baseSchema = date();
    }
    baseSchema = baseSchema.nullable();
    var rules = (_a = fieldDefinition.validation) !== null && _a !== void 0 ? _a : {};
    var schema = Object.entries(rules).reduce(function (acc, _a) {
        var _b = __read(_a, 2), ruleName = _b[0], ruleParams = _b[1];
        var rule = createRule(ruleName, ruleParams);
        switch (rule === null || rule === void 0 ? void 0 : rule.name) {
            case REQUIRED_RULE_NAME: {
                if (rule.condition) {
                    return acc.when("$" + rule.condition, {
                        is: true,
                        then: baseSchema.required(rule.message)
                    });
                }
                return acc.required(rule.message);
            }
            case REGEX_RULE_NAME:
                if (!(acc instanceof string) || !rule.regex)
                    return acc;
                if (rule.condition && baseSchema instanceof string) {
                    return acc.when("$" + rule.condition, {
                        is: true,
                        then: baseSchema.matches(rule.regex, { message: rule.message, excludeEmptyString: true })
                    });
                }
                return acc.matches(rule.regex, { message: rule.message, excludeEmptyString: true });
            case MIN_RULE_NAME: {
                if (acc instanceof date) {
                    if (typeof rule.min === 'string') {
                        return acc.min(rule.min, rule.message);
                    }
                    if (typeof rule.min === 'object') {
                        var dateResolved = resolveDateOperations(rule.min.operations);
                        return acc.min(dateResolved, rule.message);
                    }
                    return acc;
                }
                if ((acc instanceof string || acc instanceof number) && typeof rule.min === 'number') {
                    return acc.min(rule.min, rule.message);
                }
                return acc;
            }
            case MAX_RULE_NAME: {
                if (acc instanceof date) {
                    if (typeof rule.max === 'string') {
                        return acc.max(rule.max, rule.message);
                    }
                    if (typeof rule.max === 'object') {
                        var dateResolved = resolveDateOperations(rule.max.operations);
                        return acc.max(dateResolved, rule.message);
                    }
                    return acc;
                }
                if ((acc instanceof string || acc instanceof number) && typeof rule.max === 'number') {
                    return acc.max(rule.max, rule.message);
                }
                return acc;
            }
            case EQUALS_TRUE_RULE_NAME: {
                if (!(acc instanceof boolean)) {
                    return acc;
                }
                if (rule.condition && baseSchema instanceof boolean) {
                    return acc.when("$" + rule.condition, {
                        is: true,
                        then: baseSchema.equals([true], rule.message)
                    });
                }
                return acc.equals([true], rule.message);
            }
            default: {
                // eslint-disable-next-line no-console
                console.warn("UF validation: unsupported rule \"" + ruleName + "\" for field definition " + JSON.stringify(fieldDefinition));
                return acc;
            }
        }
    }, baseSchema);
    return schema;
};
export function createObjectSchema(fieldsDefinition) {
    var entries = Object.entries(fieldsDefinition);
    var shape = entries.reduce(function (acc, _a) {
        var _b;
        var _c = __read(_a, 2), fieldName = _c[0], definition = _c[1];
        return __assign(__assign({}, acc), (_b = {}, _b[fieldName] = createFieldSchema(definition), _b));
    }, {});
    var objectSchema = object().shape(shape);
    return objectSchema;
}
var serverValidationErrorName = 'ServerValidationError';
var ServerValidationError = /** @class */ (function (_super) {
    __extends(ServerValidationError, _super);
    function ServerValidationError(errors) {
        var _this = _super.call(this, 'Server-side validation error.') || this;
        _this.name = serverValidationErrorName;
        _this.errorInfo = errors;
        return _this;
    }
    return ServerValidationError;
}(Error));
export { ServerValidationError };
export function isServerValidationError(error) {
    return error.name === serverValidationErrorName;
}
var validate = function (schema, formValue, conditionsResolved) {
    var result = null;
    try {
        schema.validateSync(formValue, { abortEarly: false, context: conditionsResolved });
    }
    catch (error) {
        if (error instanceof ValidationError) {
            var errorInfo = error.inner.reduce(function (accumulated, nextError) {
                var _a;
                // multiple errors for the same field are separate list entries
                // this applies only the first error message (most general)
                if (Object.prototype.hasOwnProperty.call(accumulated, nextError.path)) {
                    return accumulated;
                }
                return __assign(__assign({}, accumulated), (_a = {}, _a[nextError.path] = nextError.errors[0], _a));
            }, {});
            result = errorInfo;
        }
    }
    return result;
};
export default validate;
