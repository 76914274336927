import { __decorate, __extends } from "tslib";
import { Component, Emit, Prop, PropSync, Vue } from 'vue-property-decorator';
import { ClosePopup, QBtn, QCard, QCardActions, QCardSection, QExpansionItem, QIcon, QItem, QItemLabel, QItemSection, QList, QSeparator, QSpace, QTooltip } from 'quasar';
import Modal from '../../components/modal/Modal.vue';
import Tooltip from '../../components/tooltip/Tooltip.vue';
import { formatMoney } from '../../helpers';
import { isTwoTariffValue } from '../../models/TariffCalculatorInputResult';
import labels from './labels';
var TariffSelectedBox = /** @class */ (function (_super) {
    __extends(TariffSelectedBox, _super);
    function TariffSelectedBox() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.openModal = false;
        _this.id = 'tr-web-component';
        _this.PRIMA_DOKUMENTTYP_LEISTUNGSBESCHREIBUNG_ID = 6;
        _this.PRIMA_DOKUMENTTYP_PREISLISTE_ID = 5;
        _this.PRIMA_DOKUMENTTYP_INFOBLATT_ID = 3;
        _this.kundenart = 0;
        return _this;
    }
    TariffSelectedBox.prototype.created = function () {
        this.kundenart = this.inputData.customerType ? 1 : 0;
        this.totalOneTimePrice = this.baseTelcoPrice || 0;
    };
    TariffSelectedBox.prototype.sumpPricePerMonth = function (index) {
        var _a, _b, _c, _d, _e, _f;
        var sum = 0;
        (_b = (_a = this.selectedTariff) === null || _a === void 0 ? void 0 : _a.optionGroups) === null || _b === void 0 ? void 0 : _b.forEach(function (g) {
            var _a;
            (_a = g.entries) === null || _a === void 0 ? void 0 : _a.forEach(function (options) {
                var _a, _b, _c, _d, _e;
                if (options.isSelected) {
                    if (((_a = options.monthlyPrices) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                        if (index) {
                            var pricePerMonth = (_b = options.monthlyPrices[index]) === null || _b === void 0 ? void 0 : _b.pricePerMonth;
                            if (pricePerMonth) {
                                if (pricePerMonth !== 0)
                                    sum += pricePerMonth * (options.chooseAmount ? options.chooseAmount : 1);
                            }
                            else {
                                var optionsPrice = (_c = options.monthlyPrices[0]) === null || _c === void 0 ? void 0 : _c.pricePerMonth;
                                var to = (_d = options.monthlyPrices[0]) === null || _d === void 0 ? void 0 : _d.to;
                                if (optionsPrice !== 0 && (to === 0 || to === undefined || to === null)) {
                                    sum += optionsPrice * (options.chooseAmount ? options.chooseAmount : 1);
                                }
                            }
                        }
                        else {
                            var pricePerMonth = (_e = options.monthlyPrices[0]) === null || _e === void 0 ? void 0 : _e.pricePerMonth;
                            if (pricePerMonth !== 0) {
                                sum += pricePerMonth * (options.chooseAmount ? options.chooseAmount : 1);
                            }
                        }
                    }
                }
            });
        });
        (_d = (_c = this.selectedTariff) === null || _c === void 0 ? void 0 : _c.hardwareGroups) === null || _d === void 0 ? void 0 : _d.forEach(function (g) {
            var _a;
            (_a = g.entries) === null || _a === void 0 ? void 0 : _a.forEach(function (options) {
                var _a, _b, _c, _d;
                if (options.isSelected) {
                    if (((_a = options.monthlyPrices) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                        if (index) {
                            var pricePerMonth = (_b = options.monthlyPrices[index]) === null || _b === void 0 ? void 0 : _b.pricePerMonth;
                            if (pricePerMonth) {
                                if (pricePerMonth !== 0)
                                    sum += pricePerMonth * (options.chooseAmount ? options.chooseAmount : 1);
                            }
                            else {
                                var optionsPrice = (_c = options.monthlyPrices[0]) === null || _c === void 0 ? void 0 : _c.pricePerMonth;
                                if (optionsPrice !== 0) {
                                    sum += optionsPrice * (options.chooseAmount ? options.chooseAmount : 1);
                                }
                            }
                        }
                        else {
                            var pricePerMonth = (_d = options.monthlyPrices[0]) === null || _d === void 0 ? void 0 : _d.pricePerMonth;
                            if (pricePerMonth !== 0) {
                                sum += pricePerMonth * (options.chooseAmount ? options.chooseAmount : 1);
                            }
                        }
                    }
                }
            });
        });
        if ((_e = this.selectedTariff) === null || _e === void 0 ? void 0 : _e.monthlyPrices) {
            if (index && this.selectedTariff.monthlyPrices.length >= index + 1) {
                var pricePerMonth = this.selectedTariff.monthlyPrices[index].pricePerMonth;
                sum += pricePerMonth || 0;
            }
            else {
                sum += this.monthlyPriceNumber((_f = this.selectedTariff) === null || _f === void 0 ? void 0 : _f.monthlyPrices);
            }
        }
        if (!index) {
            this.totalReducePrice = sum;
        }
        else {
            this.totalNormalPrice = sum;
        }
        return sum;
    };
    Object.defineProperty(TariffSelectedBox.prototype, "maxStaffeln", {
        get: function () {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            var maxST = (_a = this.selectedTariff) === null || _a === void 0 ? void 0 : _a.monthlyPrices;
            if (((_c = (_b = this.selectedTariff) === null || _b === void 0 ? void 0 : _b.monthlyPrices) === null || _c === void 0 ? void 0 : _c.length) === 2)
                maxST = (_d = this.selectedTariff) === null || _d === void 0 ? void 0 : _d.monthlyPrices;
            else {
                (_f = (_e = this.selectedTariff) === null || _e === void 0 ? void 0 : _e.optionGroups) === null || _f === void 0 ? void 0 : _f.forEach(function (g) {
                    var _a;
                    (_a = g.entries) === null || _a === void 0 ? void 0 : _a.forEach(function (options) {
                        if (options.isSelected && options.monthlyPrices.length >= 2)
                            maxST = options.monthlyPrices;
                    });
                });
                (_h = (_g = this.selectedTariff) === null || _g === void 0 ? void 0 : _g.hardwareGroups) === null || _h === void 0 ? void 0 : _h.forEach(function (g) {
                    var _a;
                    (_a = g.entries) === null || _a === void 0 ? void 0 : _a.forEach(function (options) {
                        if (options.isSelected && options.monthlyPrices.length >= 2)
                            maxST = options.monthlyPrices;
                    });
                });
            }
            return maxST;
        },
        enumerable: false,
        configurable: true
    });
    TariffSelectedBox.prototype.readOnly = function (item, group) {
        var readOnly = item.readonly;
        if (item.relConditions.length > 0) {
            readOnly = true;
            item.relConditions.forEach(function (con) {
                if (group.entries.find(function (s) { return s.refRootId.toString() === con.split("#")[1] && s.isSelected; })) {
                    readOnly = false;
                    // eslint-disable-next-line no-param-reassign
                    //         if(item.preselected)item.isSelected=true;
                }
            });
            // eslint-disable-next-line no-param-reassign
            //    if(readOnly)item.isSelected=false; // wenn Option eine bedingungen hat und die nicht erfüllt, dann auswahl deselekten
        }
        else {
            readOnly = item.readonly;
        }
        return readOnly;
    };
    TariffSelectedBox.prototype.sumPriceOneTime = function () {
        var _a, _b, _c, _d, _e;
        var sum = 0;
        var maxShippingCost = 0;
        (_b = (_a = this.selectedTariff) === null || _a === void 0 ? void 0 : _a.optionGroups) === null || _b === void 0 ? void 0 : _b.forEach(function (g) {
            var _a;
            (_a = g.entries) === null || _a === void 0 ? void 0 : _a.forEach(function (options) {
                if (options.isSelected) {
                    if (options.priceOneTime)
                        sum += options.priceOneTime * (options.chooseAmount ? options.chooseAmount : 1);
                    // if (options.shippingCosts && options.shippingCosts > maxShippingCost) maxShippingCost = options.shippingCosts;
                }
            });
        });
        (_d = (_c = this.selectedTariff) === null || _c === void 0 ? void 0 : _c.hardwareGroups) === null || _d === void 0 ? void 0 : _d.forEach(function (g) {
            var _a;
            (_a = g.entries) === null || _a === void 0 ? void 0 : _a.forEach(function (options) {
                if (options.isSelected) {
                    if (options.priceOneTime)
                        sum += options.priceOneTime * (options.chooseAmount ? options.chooseAmount : 1);
                    if (options.shippingCosts && options.shippingCosts > maxShippingCost)
                        maxShippingCost = options.shippingCosts;
                }
            });
        });
        if ((_e = this.selectedTariff) === null || _e === void 0 ? void 0 : _e.installCost)
            sum += this.selectedTariff.installCost;
        if (maxShippingCost) {
            sum += maxShippingCost;
        }
        return sum;
    };
    Object.defineProperty(TariffSelectedBox.prototype, "usageHT", {
        get: function () {
            return this.inputData.usageHT;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffSelectedBox.prototype, "tarifInfo", {
        get: function () {
            return this.selectedTariff.tarifInfo;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffSelectedBox.prototype, "usageNT", {
        get: function () {
            if (isTwoTariffValue(this.inputData))
                return this.inputData.usageNT;
            return null;
        },
        enumerable: false,
        configurable: true
    });
    TariffSelectedBox.prototype.getKey = function (was, index) {
        return "" + was + index;
    };
    Object.defineProperty(TariffSelectedBox.prototype, "versorgerName", {
        get: function () {
            var _a;
            return (_a = this.selectedTariff) === null || _a === void 0 ? void 0 : _a.versorgerName;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffSelectedBox.prototype, "tariffName", {
        get: function () {
            var _a;
            return (_a = this.selectedTariff) === null || _a === void 0 ? void 0 : _a.tarifName;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffSelectedBox.prototype, "totalPrice", {
        get: function () {
            var _a, _b, _c, _d;
            switch (this.inputData.anzeigeNettoBrutto) {
                case 1:
                default:
                    return (_b = (_a = this.selectedTariff) === null || _a === void 0 ? void 0 : _a.gesamtpreisBrutto) === null || _b === void 0 ? void 0 : _b.toFixed(2).replace('.', ',');
                case 0:
                    return (_d = (_c = this.selectedTariff) === null || _c === void 0 ? void 0 : _c.gesamtpreisNetto) === null || _d === void 0 ? void 0 : _d.toFixed(2).replace('.', ',');
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffSelectedBox.prototype, "basePrice", {
        get: function () {
            var _a, _b, _c, _d;
            switch (this.inputData.anzeigeNettoBrutto) {
                case 1:
                default:
                    return (_b = (_a = this.selectedTariff) === null || _a === void 0 ? void 0 : _a.grundpreisBrutto) === null || _b === void 0 ? void 0 : _b.toFixed(2).replace('.', ',');
                case 0:
                    return (_d = (_c = this.selectedTariff) === null || _c === void 0 ? void 0 : _c.grundpreisNetto) === null || _d === void 0 ? void 0 : _d.toFixed(2).replace('.', ',');
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffSelectedBox.prototype, "hasworkingpriceNT", {
        get: function () {
            var selectedTariff = this.selectedTariff;
            return !!(selectedTariff.arbeitspreisNTCtKWhBrutto && selectedTariff.arbeitspreisHTCtKWhBrutto);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffSelectedBox.prototype, "baseTelcoPrice", {
        get: function () {
            var _a, _b, _c;
            if (((_a = this.selectedTariff) === null || _a === void 0 ? void 0 : _a.shippingCost) && ((_b = this.selectedTariff) === null || _b === void 0 ? void 0 : _b.setupCost))
                return this.selectedTariff.shippingCost + ((_c = this.selectedTariff) === null || _c === void 0 ? void 0 : _c.setupCost);
            return this.selectedTariff.setupCost;
        },
        enumerable: false,
        configurable: true
    });
    TariffSelectedBox.prototype.formatValue = function (value) {
        return formatMoney(value);
    };
    TariffSelectedBox.prototype.formatNumber = function (number) {
        return number.toFixed(2).replace('.', ',');
    };
    TariffSelectedBox.prototype.monthlyPrice = function (item) {
        if ((item === null || item === void 0 ? void 0 : item.length) > 0) {
            return formatMoney(this.monthlyPriceNumber(item));
        }
        return formatMoney(0);
    };
    TariffSelectedBox.prototype.monthlyPriceNumber = function (item) {
        if ((item === null || item === void 0 ? void 0 : item.length) > 0) {
            var pricePerMonth = item[0].pricePerMonth;
            return pricePerMonth;
        }
        return 0;
    };
    Object.defineProperty(TariffSelectedBox.prototype, "hasworkingpriceHT", {
        get: function () {
            var selectedTariff = this.selectedTariff;
            if (selectedTariff.arbeitspreisHTCtKWhBrutto)
                return true;
            return false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffSelectedBox.prototype, "workingPrice", {
        get: function () {
            var selectedTariff = this.selectedTariff;
            switch (this.inputData.anzeigeNettoBrutto) {
                case 1:
                default:
                    if (selectedTariff.arbeitspreisHTCtKWhBrutto)
                        return selectedTariff.arbeitspreisHTCtKWhBrutto.toFixed(2).replace('.', ',');
                    return selectedTariff.arbeitspreisCtKWhBrutto && selectedTariff.arbeitspreisCtKWhBrutto.toFixed(2).replace('.', ',');
                case 0:
                    if (selectedTariff.arbeitspreisHTCtKWhNetto)
                        return selectedTariff.arbeitspreisHTCtKWhNetto.toFixed(2).replace('.', ',');
                    return selectedTariff.arbeitspreisCtKWhNetto && selectedTariff.arbeitspreisCtKWhNetto.toFixed(2).replace('.', ',');
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffSelectedBox.prototype, "workingPriceNT", {
        get: function () {
            var selectedTariff = this.selectedTariff;
            switch (this.inputData.anzeigeNettoBrutto) {
                case 1:
                default:
                    if (selectedTariff.arbeitspreisNTCtKWhBrutto)
                        return selectedTariff.arbeitspreisNTCtKWhBrutto.toFixed(2).replace('.', ',');
                    break;
                case 0:
                    if (selectedTariff.arbeitspreisNTCtKWhNetto)
                        return selectedTariff.arbeitspreisNTCtKWhNetto.toFixed(2).replace('.', ',');
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    TariffSelectedBox.prototype.getCalcWertBN = function (boni) {
        switch (this.inputData.anzeigeNettoBrutto) {
            case 0:
                return boni.calcWertNetto;
            case 1:
                return boni.calcWertBrutto;
            default:
                return boni.calcWertBrutto;
        }
    };
    Object.defineProperty(TariffSelectedBox.prototype, "vertragslaufzeit", {
        get: function () {
            return this.selectedTariff.vertragslaufzeit;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffSelectedBox.prototype, "kuendigungsFrist", {
        get: function () {
            return this.selectedTariff.kuendigungsfrist;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffSelectedBox.prototype, "ersparnis", {
        get: function () {
            var _a, _b;
            switch (this.inputData.anzeigeNettoBrutto) {
                case 1:
                default:
                    return (_a = this.selectedTariff.ersparnisBrutto) === null || _a === void 0 ? void 0 : _a.toFixed(2).replace('.', ',');
                case 0:
                    return (_b = this.selectedTariff.ersparnisNetto) === null || _b === void 0 ? void 0 : _b.toFixed(2).replace('.', ',');
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffSelectedBox.prototype, "priceGuarantee", {
        get: function () {
            var _a, _b, _c;
            return (_c = (_b = (_a = this.selectedTariff.informationen) === null || _a === void 0 ? void 0 : _a.find(function (a) { return a.bedingungID === 101; })) === null || _b === void 0 ? void 0 : _b.beschreibungstext) !== null && _c !== void 0 ? _c : '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffSelectedBox.prototype, "priceGuaranteeComponents", {
        get: function () {
            var _a, _b, _c;
            return (_c = (_b = (_a = this.selectedTariff.informationen) === null || _a === void 0 ? void 0 : _a.find(function (a) { return a.bedingungID === 101; })) === null || _b === void 0 ? void 0 : _b.preisgarantieBestandteile) !== null && _c !== void 0 ? _c : '';
        },
        enumerable: false,
        configurable: true
    });
    TariffSelectedBox.prototype.changeTariff = function () { };
    TariffSelectedBox.prototype.changeProduct = function () { };
    __decorate([
        Prop({ required: true })
    ], TariffSelectedBox.prototype, "inputData", void 0);
    __decorate([
        Prop({ required: true })
    ], TariffSelectedBox.prototype, "widgetLabels", void 0);
    __decorate([
        Prop({ required: true })
    ], TariffSelectedBox.prototype, "selectedTariff", void 0);
    __decorate([
        Prop({ required: true })
    ], TariffSelectedBox.prototype, "isTelco", void 0);
    __decorate([
        PropSync('totalReducePrice')
    ], TariffSelectedBox.prototype, "totalReducePrice", void 0);
    __decorate([
        PropSync('totalNormalPrice')
    ], TariffSelectedBox.prototype, "totalNormalPrice", void 0);
    __decorate([
        PropSync('totalOneTimePrice')
    ], TariffSelectedBox.prototype, "totalOneTimePrice", void 0);
    __decorate([
        Emit()
    ], TariffSelectedBox.prototype, "changeTariff", null);
    __decorate([
        Emit()
    ], TariffSelectedBox.prototype, "changeProduct", null);
    TariffSelectedBox = __decorate([
        Component({
            filters: {
                convertPlural: function (value) {
                    if (value === 0) {
                        return labels.infinityPeriod;
                    }
                    if (value <= 1) {
                        return value + " Monat";
                    }
                    return value + " Monate";
                },
                telephoneFlat: function (value) {
                    return value ? '' : null;
                }
            },
            components: {
                QBtn: QBtn,
                QCard: QCard,
                QCardActions: QCardActions,
                QCardSection: QCardSection,
                QList: QList,
                QIcon: QIcon,
                QItem: QItem,
                QItemSection: QItemSection,
                QExpansionItem: QExpansionItem,
                QItemLabel: QItemLabel,
                QSeparator: QSeparator,
                QSpace: QSpace,
                QTooltip: QTooltip,
                Modal: Modal,
                Tooltip: Tooltip
            },
            directives: {
                ClosePopup: ClosePopup
            }
        })
    ], TariffSelectedBox);
    return TariffSelectedBox;
}(Vue));
export default TariffSelectedBox;
