import { __assign, __decorate, __extends } from "tslib";
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { QSelect } from 'quasar';
import store from '../../../store';
import BaseControl from './BaseControl';
var SelectControl = /** @class */ (function (_super) {
    __extends(SelectControl, _super);
    function SelectControl() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.filterKey = '';
        return _this;
    }
    SelectControl.prototype.updated = function () {
        var _a, _b;
        if (this.isContinueAsOffer) {
            (_a = this.selectInputBase) === null || _a === void 0 ? void 0 : _a.resetValidation();
            (_b = this.selectInputBase) === null || _b === void 0 ? void 0 : _b.resetValidation();
        }
    };
    Object.defineProperty(SelectControl.prototype, "selectInput", {
        get: function () {
            if (this.filterable) {
                return this.selectInputFilterable;
            }
            return this.selectInputBase;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SelectControl.prototype, "clearable", {
        get: function () {
            var _a;
            return (_a = this.props.clearable) !== null && _a !== void 0 ? _a : false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SelectControl.prototype, "disabled", {
        get: function () {
            var _a;
            return (_a = this.props.disabled) !== null && _a !== void 0 ? _a : false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SelectControl.prototype, "filterable", {
        get: function () {
            var _a;
            return (_a = this.props.filterable) !== null && _a !== void 0 ? _a : false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SelectControl.prototype, "readonly", {
        get: function () {
            var _a;
            return (_a = this.props.readonly) !== null && _a !== void 0 ? _a : false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SelectControl.prototype, "debounce", {
        get: function () {
            var _a;
            if (this.filterable) {
                return (_a = this.props.debounce) !== null && _a !== void 0 ? _a : 400;
            }
            return undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SelectControl.prototype, "filterableProps", {
        get: function () {
            var _a, _b;
            if (this.filterable) {
                return {
                    filterable: true,
                    'use-input': true,
                    'fill-input': (_a = this.props['fill-input']) !== null && _a !== void 0 ? _a : true,
                    'hide-selected': (_b = this.props['hide-selected']) !== null && _b !== void 0 ? _b : true
                };
            }
            return {};
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SelectControl.prototype, "isContinueAsOffer", {
        get: function () {
            return store.states.isContinueAsOffer;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SelectControl.prototype, "controlProps", {
        get: function () {
            var _a = this, baseInputProps = _a.baseInputProps, clearable = _a.clearable, debounce = _a.debounce, disabled = _a.disabled, readonly = _a.readonly, filterableProps = _a.filterableProps;
            if (this.isContinueAsOffer) {
                return __assign(__assign({}, filterableProps), { ref: baseInputProps.name, label: baseInputProps.label.replace('*', ''), value: baseInputProps.value, name: baseInputProps.name, outlined: baseInputProps.outlined, square: baseInputProps.square, color: 'primary', disable: disabled, readonly: readonly, style: {
                        marginBottom: '20px'
                    } });
            }
            return __assign(__assign(__assign({}, baseInputProps), filterableProps), { clearable: clearable, disable: disabled, readonly: readonly, 'input-debounce': debounce });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SelectControl.prototype, "optionsFiltered", {
        get: function () {
            var filterKey = this.filterKey;
            if (!filterKey) {
                return this.options;
            }
            return this.options.filter(function (v) { return v.label.toLowerCase().indexOf(filterKey) > -1; });
        },
        enumerable: false,
        configurable: true
    });
    SelectControl.prototype.onOptionsUpdate = function (newOptions) {
        var _this = this;
        if (!this.props.popupOnOptionsChange) {
            return;
        }
        if (newOptions && newOptions.length >= 2) {
            setTimeout(function () {
                var _a;
                (_a = _this.selectInput) === null || _a === void 0 ? void 0 : _a.showPopup();
            }, 300);
        }
    };
    SelectControl.prototype.filterFn = function (val, update) {
        var _this = this;
        return update(function () {
            _this.filterKey = val.toLowerCase();
        });
    };
    __decorate([
        Ref('select-input-base')
    ], SelectControl.prototype, "selectInputBase", void 0);
    __decorate([
        Ref('select-input-filterable')
    ], SelectControl.prototype, "selectInputFilterable", void 0);
    __decorate([
        Prop({ required: true })
    ], SelectControl.prototype, "props", void 0);
    __decorate([
        Prop({ required: true })
    ], SelectControl.prototype, "options", void 0);
    __decorate([
        Watch('options')
    ], SelectControl.prototype, "onOptionsUpdate", null);
    SelectControl = __decorate([
        Component({
            components: { QSelect: QSelect }
        })
    ], SelectControl);
    return SelectControl;
}(BaseControl));
export default SelectControl;
