import { __decorate, __extends, __read } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
var sizes = ['xs', 'sm', 'md', 'lg', 'xl'];
var FormRow = /** @class */ (function (_super) {
    __extends(FormRow, _super);
    function FormRow() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(FormRow.prototype, "classes", {
        get: function () {
            var entries = Object.entries(this.props);
            var sizeClasses = entries
                .filter(function (_a) {
                var _b = __read(_a, 1), entryKey = _b[0];
                return sizes.includes(entryKey);
            })
                .map(function (_a) {
                var _b = __read(_a, 2), key = _b[0], value = _b[1];
                return "tr-col-" + key + "-" + value;
            })
                .join(' ');
            var gutter = this.props.gutter;
            var gutterClass = '';
            if (gutter && typeof gutter === 'string') {
                gutterClass = "q-col-gutter-x-" + gutter;
            }
            if (gutter && gutter instanceof Array) {
                var _a = __read(gutter, 2), gutterSize = _a[0], gutterLayout = _a[1];
                gutterClass = "q-col-gutter-" + gutterLayout + "-" + gutterSize;
            }
            return [sizeClasses, gutterClass];
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop({ default: function () { return ({}); } })
    ], FormRow.prototype, "props", void 0);
    FormRow = __decorate([
        Component
    ], FormRow);
    return FormRow;
}(Vue));
export default FormRow;
