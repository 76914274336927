import { __assign, __decorate, __extends, __read } from "tslib";
import { Component, Prop, Provide, Vue, Watch } from 'vue-property-decorator';
import { resolveDateOperations } from './dateOperations';
import FormElement from './FormElement';
import { isDateOperationsInitialValue } from './models/guards';
import SubmissionState from './models/SubmissionState';
import resolveConditions, { resolveErrorConditions } from './resolveConditions';
import validate, { createObjectSchema, isServerValidationError } from './validation';
var UniversalForm = /** @class */ (function (_super) {
    __extends(UniversalForm, _super);
    function UniversalForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // data
        _this.formValue = {};
        _this.formSchema = createObjectSchema({});
        _this.serverErrors = null;
        _this.externalErrors = null;
        _this.submissionState = SubmissionState.INITIAL;
        _this.eventHandlerCancellers = [];
        return _this;
    }
    Object.defineProperty(UniversalForm.prototype, "structure", {
        // getters
        get: function () {
            return this.form.structure;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UniversalForm.prototype, "baseConditions", {
        get: function () {
            var _a;
            return resolveConditions((_a = this.form.conditions) !== null && _a !== void 0 ? _a : {}, this.formValue, this.externalValues);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UniversalForm.prototype, "errorConditions", {
        get: function () {
            var _a, _b;
            return resolveErrorConditions((_a = this.form.conditions) !== null && _a !== void 0 ? _a : {}, (_b = this.formErrors) !== null && _b !== void 0 ? _b : {});
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UniversalForm.prototype, "formConditions", {
        get: function () {
            var _a = this, baseConditions = _a.baseConditions, errorConditions = _a.errorConditions;
            return Object.entries(baseConditions).reduce(function (acc, _a) {
                var _b;
                var _c = __read(_a, 2), nextKey = _c[0], nextCondition = _c[1];
                var nextValue = nextCondition || acc[nextKey];
                return __assign(__assign({}, acc), (_b = {}, _b[nextKey] = nextValue, _b));
            }, errorConditions);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UniversalForm.prototype, "formErrors", {
        get: function () {
            if (this.serverErrors) {
                return this.serverErrors;
            }
            if (this.externalErrors) {
                return this.externalErrors;
            }
            var clientErrors = validate(this.formSchema, this.formValue, this.baseConditions);
            return clientErrors;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UniversalForm.prototype, "formMetadata", {
        get: function () {
            var _a = this, submissionState = _a.submissionState, isSubmitting = _a.isSubmitting, formErrors = _a.formErrors, externalErrors = _a.externalErrors, serverErrors = _a.serverErrors, formConditions = _a.formConditions;
            var hasPersistentError = !!externalErrors || !!serverErrors;
            var hasSubmissionError = submissionState === SubmissionState.ERROR;
            var hasSubmissionSuccess = submissionState === SubmissionState.SUCCESS;
            return {
                hasPersistentError: hasPersistentError,
                hasSubmissionError: hasSubmissionError,
                hasSubmissionSuccess: hasSubmissionSuccess,
                isSubmitting: isSubmitting,
                isValid: formErrors === null,
                submissionState: submissionState,
                errors: formErrors !== null && formErrors !== void 0 ? formErrors : {},
                conditions: formConditions
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UniversalForm.prototype, "isSubmitting", {
        get: function () {
            return this.submissionState === SubmissionState.LOADING;
        },
        enumerable: false,
        configurable: true
    });
    // provided methods
    UniversalForm.prototype.clearSubmissionState = function () {
        this.submissionState = SubmissionState.INITIAL;
    };
    UniversalForm.prototype.submitForm = function () {
        var _this = this;
        var _a;
        var probablyPromise = (_a = this.onSubmit) === null || _a === void 0 ? void 0 : _a.call(this, this.formValue);
        if (probablyPromise instanceof Promise) {
            this.submissionState = SubmissionState.LOADING;
            probablyPromise
                .then(function () {
                _this.submissionState = SubmissionState.SUCCESS;
            })
                .catch(function (error) {
                // TODO: handle other errors
                if (isServerValidationError(error)) {
                    _this.serverErrors = error.errorInfo;
                }
                _this.submissionState = SubmissionState.ERROR;
            });
        }
    };
    UniversalForm.prototype.backForm = function () {
        var _this = this;
        var _a;
        var probablyPromise = (_a = this.onBack) === null || _a === void 0 ? void 0 : _a.call(this, this.formValue);
        if (probablyPromise instanceof Promise) {
            this.submissionState = SubmissionState.LOADING;
            probablyPromise
                .then(function () {
                _this.submissionState = SubmissionState.SUCCESS;
            })
                .catch(function (error) {
                // TODO: handle other errors
                if (isServerValidationError(error)) {
                    _this.serverErrors = error.errorInfo;
                }
                _this.submissionState = SubmissionState.ERROR;
            });
        }
    };
    UniversalForm.prototype.skipForm = function () {
        var _this = this;
        var _a;
        var probablyPromise = (_a = this.onSkip) === null || _a === void 0 ? void 0 : _a.call(this, this.formValue);
        if (probablyPromise instanceof Promise) {
            this.submissionState = SubmissionState.LOADING;
            probablyPromise
                .then(function () {
                _this.submissionState = SubmissionState.SUCCESS;
            })
                .catch(function (error) {
                // TODO: handle other errors
                if (isServerValidationError(error)) {
                    _this.serverErrors = error.errorInfo;
                }
                _this.submissionState = SubmissionState.ERROR;
            });
        }
    };
    UniversalForm.prototype.clickForm = function () {
        var _this = this;
        var _a;
        var probablyPromise = (_a = this.onClick) === null || _a === void 0 ? void 0 : _a.call(this, this.formValue);
        if (probablyPromise instanceof Promise) {
            this.submissionState = SubmissionState.LOADING;
            probablyPromise
                .then(function () {
                _this.submissionState = SubmissionState.SUCCESS;
            })
                .catch(function (error) {
                // TODO: handle other errors
                if (isServerValidationError(error)) {
                    _this.serverErrors = error.errorInfo;
                }
                _this.submissionState = SubmissionState.ERROR;
            });
        }
    };
    UniversalForm.prototype.clickSecondaryForm = function () {
        var _this = this;
        var _a;
        var probablyPromise = (_a = this.onClickSecondary) === null || _a === void 0 ? void 0 : _a.call(this, this.formValue);
        if (probablyPromise instanceof Promise) {
            this.submissionState = SubmissionState.LOADING;
            probablyPromise
                .then(function () {
                _this.submissionState = SubmissionState.SUCCESS;
            })
                .catch(function (error) {
                // TODO: handle other errors
                if (isServerValidationError(error)) {
                    _this.serverErrors = error.errorInfo;
                }
                _this.submissionState = SubmissionState.ERROR;
            });
        }
    };
    UniversalForm.prototype.updateFieldValue = function (name, value) {
        this.formValue[name] = value;
        this.serverErrors = null;
        this.externalErrors = null;
        this.submissionState = SubmissionState.INITIAL;
    };
    // watchers
    UniversalForm.prototype.handleFormReinitialization = function () {
        this.initialize();
    };
    UniversalForm.prototype.handleExternalFormErrorsUpdate = function (nextErrors) {
        this.externalErrors = nextErrors;
    };
    UniversalForm.prototype.handleExternalFormValueUpdate = function (nextValue) {
        this.formValue = __assign(__assign({}, this.formValue), nextValue);
    };
    UniversalForm.prototype.setupEventHandlers = function (eventHandlers) {
        var _this = this;
        // cancel all watchers
        this.eventHandlerCancellers.forEach(function (canceller) {
            canceller();
        });
        this.eventHandlerCancellers = [];
        // attach new watchers
        Object.entries(eventHandlers).forEach(function (_a) {
            var _b = __read(_a, 2), fieldName = _b[0], handler = _b[1];
            if (fieldName && handler) {
                var canceller = _this.$watch("formValue." + fieldName, function (value, previousValue) { return handler(value, _this.formValue, previousValue); });
                _this.eventHandlerCancellers.push(canceller);
            }
        });
    };
    // lifecycle methods
    UniversalForm.prototype.created = function () {
        this.initialize();
        this.setupEventHandlers(this.eventHandlers);
    };
    // other methods
    UniversalForm.prototype.initialize = function () {
        var _a = this.form, fields = _a.fields, initialValues = _a.initialValues;
        this.submissionState = SubmissionState.INITIAL;
        this.formSchema = createObjectSchema(fields);
        var initialFromValue = Object.entries(fields).reduce(function (accumulated, _a) {
            var _b;
            var _c = __read(_a, 2), fieldName = _c[0], fieldDefinition = _c[1];
            var initialValue = initialValues[fieldName];
            var valueResolved;
            if (typeof initialValue !== 'undefined') {
                if (isDateOperationsInitialValue(initialValue)) {
                    valueResolved = resolveDateOperations(initialValue);
                }
                else {
                    valueResolved = initialValue;
                }
            }
            else {
                // TODO: consider proper initialization values
                switch (fieldDefinition.type) {
                    case 'number':
                        valueResolved = 0;
                        break;
                    case 'boolean':
                        valueResolved = false;
                        break;
                    default:
                        valueResolved = null;
                }
            }
            return __assign(__assign({}, accumulated), (_b = {}, _b[fieldName] = valueResolved, _b));
        }, {});
        this.formValue = initialFromValue;
    };
    __decorate([
        Prop({ default: function () { return ({}); } })
    ], UniversalForm.prototype, "externalFormValue", void 0);
    __decorate([
        Prop({ default: function () { return ({}); } })
    ], UniversalForm.prototype, "externalFormErrors", void 0);
    __decorate([
        Prop({ default: function () { return ({}); } })
    ], UniversalForm.prototype, "externalValues", void 0);
    __decorate([
        Prop({ default: function () { return ({}); } })
    ], UniversalForm.prototype, "eventHandlers", void 0);
    __decorate([
        Prop({ required: true, type: Function })
    ], UniversalForm.prototype, "onSubmit", void 0);
    __decorate([
        Prop({ required: false, type: Function })
    ], UniversalForm.prototype, "onBack", void 0);
    __decorate([
        Prop({ required: false, type: Function })
    ], UniversalForm.prototype, "onSkip", void 0);
    __decorate([
        Prop({ required: false, type: Function })
    ], UniversalForm.prototype, "onClick", void 0);
    __decorate([
        Prop({ required: false, type: Function })
    ], UniversalForm.prototype, "onClickSecondary", void 0);
    __decorate([
        Prop({ default: false })
    ], UniversalForm.prototype, "debug", void 0);
    __decorate([
        Prop({ required: true })
    ], UniversalForm.prototype, "form", void 0);
    __decorate([
        Provide()
    ], UniversalForm.prototype, "clearSubmissionState", null);
    __decorate([
        Provide()
    ], UniversalForm.prototype, "submitForm", null);
    __decorate([
        Provide()
    ], UniversalForm.prototype, "backForm", null);
    __decorate([
        Provide()
    ], UniversalForm.prototype, "skipForm", null);
    __decorate([
        Provide()
    ], UniversalForm.prototype, "clickForm", null);
    __decorate([
        Provide()
    ], UniversalForm.prototype, "clickSecondaryForm", null);
    __decorate([
        Provide()
    ], UniversalForm.prototype, "updateFieldValue", null);
    __decorate([
        Watch('form.initialValues', { deep: true }),
        Watch('form.fields')
    ], UniversalForm.prototype, "handleFormReinitialization", null);
    __decorate([
        Watch('externalFormErrors')
    ], UniversalForm.prototype, "handleExternalFormErrorsUpdate", null);
    __decorate([
        Watch('externalFormValue')
    ], UniversalForm.prototype, "handleExternalFormValueUpdate", null);
    __decorate([
        Watch('eventHandlers', { deep: true })
    ], UniversalForm.prototype, "setupEventHandlers", null);
    UniversalForm = __decorate([
        Component({
            components: { FormElement: FormElement }
        })
    ], UniversalForm);
    return UniversalForm;
}(Vue));
export default UniversalForm;
