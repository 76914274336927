import { __awaiter, __decorate, __extends, __generator } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import setup from '../../setup';
import TariffCalculator from './TariffCalculator.vue';
var WebComponentWrapper = /** @class */ (function (_super) {
    __extends(WebComponentWrapper, _super);
    function WebComponentWrapper() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.div = document.getElementById('tarifrechner');
        return _this;
    }
    WebComponentWrapper.prototype.beforeCreate = function () {
        var _a, _b, _c, _d, _e, _f, _g;
        return __awaiter(this, void 0, void 0, function () {
            var htmlHead, div, viewport, charset, externalCSS, metaViewport, metaCharset, standardColorPalette, additionalStyling, standardFont, tariffCalculatorTypeId, authToken, authTokenRefresh, optbaiextendedWhiteList, optmarkenIDs, optstichtag, opttarifRootIDs, opttarifRootIDsMindestfilter, opttarifcode, optversorgerIDs, optbonus, opthandlingTarife, optoeko, optplz, optort, optstrasse, opthausnummer, optverbrauchStrom, optverbrauchGas, optonlyInput, optonlyInputTarget, optkundenart, productOverview, customerID, vppSummary, primaAddressID, vppAuthTokenUrl, optexternalOfferID;
            return __generator(this, function (_h) {
                setup(Vue);
                htmlHead = document.querySelector('html>head');
                div = document.getElementById('tarifrechner');
                viewport = document.querySelector('meta[name="viewport"]');
                charset = document.querySelector('meta[charset="utf-8"]');
                externalCSS = div === null || div === void 0 ? void 0 : div.getAttribute('externalcss');
                metaViewport = "<meta name=\"viewport\" content=\"width=device-width, initial-scale=1\">";
                metaCharset = "<meta charset=\"utf-8\">";
                standardColorPalette = "<style>:root{--q-color-primary:#1976d2;--q-color-secondary:#26a69a;--q-color-accent:#9c27b0;--q-color-positive:#21ba45;--q-color-negative:#c10015;--q-color-info:#31ccec;--q-color-warning:#f2c037;--q-color-dark:#1d1d1d;--q-color-headline:#000}html{overflow-y:scroll}</style>";
                additionalStyling = "<style>.q-tabs--horizontal.q-tabs__arrow--start{left:0}.q-tabs--horizontal.q-tabs__arrow--end{right:0}.q-select__autocomplete-input,.q-select__focus-target{position:absolute;outline:0!important;width:0;height:0;padding:0;border:0;opacity:0}.q-menu{position:fixed;display:inline-block;max-width:95vw;box-shadow:0 1px 5px rgba(0,0,0,.2),0 2px 2px rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.12);background:#fff;border-radius:4px;overflow-y:auto;overflow-x:hidden;outline:0;max-height:65vh;z-index:6000}.q-menu>.q-virtual-scroll__content>.q-item{padding:8px 24px;font-family:Roboto,-apple-system,Helvetica,sans-serif;font-size:14px;cursor:pointer;margin:0}.q-menu>.q-virtual-scroll__content>.q-item:hover{background:#f5f5f5}</style>";
                standardFont = "<style>@font-face{font-family:Roboto;font-style:normal;font-weight:100;src:url(https://prod-tr4-proxy-config-public.s3.eu-central-1.amazonaws.com/tr4.getag-cloud.com/fonts/KFOkCnqEu92Fr1MmgVxIIzQ.5cb7edfc.woff) format('woff')}@font-face{font-family:Roboto;font-style:normal;font-weight:300;src:url(https://prod-tr4-proxy-config-public.s3.eu-central-1.amazonaws.com/tr4.getag-cloud.com/fonts/KFOlCnqEu92Fr1MmSU5fBBc-.b00849e0.woff) format('woff')}@font-face{font-family:Roboto;font-style:normal;font-weight:400;src:url(https://prod-tr4-proxy-config-public.s3.eu-central-1.amazonaws.com/tr4.getag-cloud.com/fonts/KFOmCnqEu92Fr1Mu4mxM.60fa3c06.woff) format('woff')}@font-face{font-family:Roboto;font-style:normal;font-weight:500;src:url(https://prod-tr4-proxy-config-public.s3.eu-central-1.amazonaws.com/tr4.getag-cloud.com/fonts/KFOlCnqEu92Fr1MmEU9fBBc-.87284894.woff) format('woff')}@font-face{font-family:Roboto;font-style:normal;font-weight:700;src:url(https://prod-tr4-proxy-config-public.s3.eu-central-1.amazonaws.com/tr4.getag-cloud.com/fonts/KFOlCnqEu92Fr1MmWUlfBBc-.adcde98f.woff) format('woff')}@font-face{font-family:Roboto;font-style:normal;font-weight:900;src:url(https://prod-tr4-proxy-config-public.s3.eu-central-1.amazonaws.com/tr4.getag-cloud.com/fonts/KFOlCnqEu92Fr1MmYUtfBBc-.bb1e4dc6.woff) format('woff')}@font-face{font-family:Material Icons;font-style:normal;font-weight:400;font-display:block;src:url(https://prod-tr4-proxy-config-public.s3.eu-central-1.amazonaws.com/tr4.getag-cloud.com/fonts/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.703cf8f2.woff2) format('woff2'),url(https://prod-tr4-proxy-config-public.s3.eu-central-1.amazonaws.com/tr4.getag-cloud.com/fonts/flUhRq6tzZclQEJ-Vdg-IuiaDsNa.84a37de8.woff) format('woff')}</style>";
                if (htmlHead) {
                    if (!viewport) {
                        htmlHead.insertAdjacentHTML('afterbegin', metaViewport);
                    }
                    if (!charset) {
                        /* Fallback: if no Content-Type in Server and Client */
                        htmlHead.insertAdjacentHTML('afterbegin', metaCharset);
                    }
                    if (!externalCSS) {
                        // Default Standard CI
                        htmlHead.insertAdjacentHTML('beforeend', standardColorPalette);
                    }
                    htmlHead.insertAdjacentHTML('beforeend', standardFont);
                    htmlHead.insertAdjacentHTML('beforeend', additionalStyling);
                }
                // get url
                this.cssUrl = div === null || div === void 0 ? void 0 : div.getAttribute('externalcss');
                tariffCalculatorTypeId = div === null || div === void 0 ? void 0 : div.getAttribute('tariffCalculatorTypeId');
                authToken = div === null || div === void 0 ? void 0 : div.getAttribute('authToken');
                authTokenRefresh = div === null || div === void 0 ? void 0 : div.getAttribute('authTokenRefresh');
                optbaiextendedWhiteList = div === null || div === void 0 ? void 0 : div.getAttribute('baiextendedWhiteList');
                optmarkenIDs = div === null || div === void 0 ? void 0 : div.getAttribute('markenIDs');
                optstichtag = div === null || div === void 0 ? void 0 : div.getAttribute('stichtag');
                opttarifRootIDs = div === null || div === void 0 ? void 0 : div.getAttribute('tarifRootIDs');
                opttarifRootIDsMindestfilter = div === null || div === void 0 ? void 0 : div.getAttribute('tarifRootIDsMindestfilter');
                opttarifcode = div === null || div === void 0 ? void 0 : div.getAttribute('tarifcode');
                optversorgerIDs = div === null || div === void 0 ? void 0 : div.getAttribute('versorgerIDs');
                optbonus = Number((_a = div === null || div === void 0 ? void 0 : div.getAttribute('bonus')) !== null && _a !== void 0 ? _a : undefined);
                opthandlingTarife = Number((_b = div === null || div === void 0 ? void 0 : div.getAttribute('handlingTarife')) !== null && _b !== void 0 ? _b : undefined);
                optoeko = Number((_c = div === null || div === void 0 ? void 0 : div.getAttribute('oeko')) !== null && _c !== void 0 ? _c : undefined);
                optplz = div === null || div === void 0 ? void 0 : div.getAttribute('plz');
                optort = div === null || div === void 0 ? void 0 : div.getAttribute('ort');
                optstrasse = div === null || div === void 0 ? void 0 : div.getAttribute('strasse');
                opthausnummer = div === null || div === void 0 ? void 0 : div.getAttribute('hausnummer');
                optverbrauchStrom = Number((_d = div === null || div === void 0 ? void 0 : div.getAttribute('verbrauchStrom')) !== null && _d !== void 0 ? _d : undefined);
                optverbrauchGas = Number((_e = div === null || div === void 0 ? void 0 : div.getAttribute('verbrauchGas')) !== null && _e !== void 0 ? _e : undefined);
                optonlyInput = Number((_f = div === null || div === void 0 ? void 0 : div.getAttribute('onlyInput')) !== null && _f !== void 0 ? _f : undefined);
                optonlyInputTarget = div === null || div === void 0 ? void 0 : div.getAttribute('onlyInputTarget');
                optkundenart = Number((_g = div === null || div === void 0 ? void 0 : div.getAttribute('kundenart')) !== null && _g !== void 0 ? _g : undefined);
                productOverview = div === null || div === void 0 ? void 0 : div.getAttribute('product-overview');
                customerID = div === null || div === void 0 ? void 0 : div.getAttribute('vppCustomerID');
                vppSummary = div === null || div === void 0 ? void 0 : div.getAttribute('vppSummaryUrl');
                primaAddressID = div === null || div === void 0 ? void 0 : div.getAttribute('primaAddressID');
                vppAuthTokenUrl = div === null || div === void 0 ? void 0 : div.getAttribute('vppAuthTokenUrl');
                optexternalOfferID = div === null || div === void 0 ? void 0 : div.getAttribute('externalOfferID');
                if (!tariffCalculatorTypeId || tariffCalculatorTypeId.length === 0) {
                    //   console.error('ID and tariffCalculatorTypeId in <tariff-calculator id="tarifrechner" tariffCalculatorTypeId="1"></tariff-calculator> is required! By default it will render Strom');
                    this.tariffCalculatorTypeId = '1';
                }
                else
                    this.tariffCalculatorTypeId = tariffCalculatorTypeId !== null && tariffCalculatorTypeId !== void 0 ? tariffCalculatorTypeId : '1'; // if tariffCalculatorTypeId is null, render default value 1
                if (authToken)
                    this.authToken = authToken;
                if (authTokenRefresh)
                    this.authTokenRefresh = authTokenRefresh;
                if (optbaiextendedWhiteList)
                    this.baiextendedWhiteList = optbaiextendedWhiteList;
                if (optbonus)
                    this.bonus = optbonus;
                if (opthandlingTarife)
                    this.handlingTarife = opthandlingTarife;
                if (optmarkenIDs)
                    this.markenIDs = optmarkenIDs;
                if (optstichtag)
                    this.stichtag = optstichtag;
                if (opttarifRootIDs)
                    this.tarifRootIDs = opttarifRootIDs;
                if (opttarifRootIDsMindestfilter)
                    this.tarifRootIDsMindestfilter = opttarifRootIDsMindestfilter;
                if (opttarifcode)
                    this.tarifcode = opttarifcode;
                if (optversorgerIDs)
                    this.versorgerIDs = optversorgerIDs;
                if (optoeko)
                    this.oeko = optoeko;
                if (optplz)
                    this.plz = optplz;
                if (optort)
                    this.ort = optort === null || optort === void 0 ? void 0 : optort.replace('slash', '|');
                if (optstrasse)
                    this.strasse = optstrasse;
                if (opthausnummer)
                    this.hausnummer = opthausnummer;
                if (optverbrauchStrom)
                    this.verbrauchStrom = optverbrauchStrom;
                if (optverbrauchGas)
                    this.verbrauchGas = optverbrauchGas;
                if (optonlyInput)
                    this.onlyInput = optonlyInput;
                if (optonlyInputTarget)
                    this.onlyInputTarget = optonlyInputTarget;
                if (optkundenart)
                    this.kundenart = optkundenart;
                if (productOverview === 'carousel')
                    this.productOverview = productOverview;
                if (customerID)
                    this.vppCustomerID = customerID;
                if (vppSummary)
                    this.vppSummary = vppSummary;
                if (primaAddressID)
                    this.primaAddressID = primaAddressID;
                if (vppAuthTokenUrl)
                    this.vppAuthTokenUrl = vppAuthTokenUrl;
                if (optexternalOfferID)
                    this.externalOfferID = optexternalOfferID;
                return [2 /*return*/];
            });
        });
    };
    WebComponentWrapper = __decorate([
        Component({ components: { TariffCalculator: TariffCalculator } })
    ], WebComponentWrapper);
    return WebComponentWrapper;
}(Vue));
export default WebComponentWrapper;
