import '@quasar/extras/roboto-font/roboto-font.css';
import '@quasar/extras/material-icons/material-icons.css';
import './styles/quasar.scss';
import './styles/fixes.scss';
import { Cookies, Loading, Quasar } from 'quasar';
import iconSet from 'quasar/icon-set/material-icons';
import langDe from 'quasar/lang/de';
import VueSignature from 'vue-signature-pad';
import ToastPlugin from './components/toast/plugin';
var setup = function (vueInstance) {
    vueInstance
        .use(Quasar, {
        plugins: {
            Cookies: Cookies,
            Loading: Loading
        },
        lang: langDe,
        iconSet: iconSet
    })
        .use(VueSignature);
    vueInstance.use(ToastPlugin);
};
export default setup;
