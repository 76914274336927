import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
var FormConditionalElement = /** @class */ (function (_super) {
    __extends(FormConditionalElement, _super);
    function FormConditionalElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(FormConditionalElement.prototype, "isConditionSatisfied", {
        get: function () {
            var _a = this.props, conditionName = _a.conditionName, revert = _a.revert;
            var conditions = this.formMetadata.conditions;
            var isSatisfied = conditions[conditionName];
            if (revert) {
                return !isSatisfied;
            }
            return isSatisfied;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FormConditionalElement.prototype, "transitionName", {
        get: function () {
            if (this.props.transition === false) {
                return '';
            }
            if (typeof this.props.transition === 'undefined') {
                return 'slide-fade';
            }
            return this.props.transition;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop({ required: true })
    ], FormConditionalElement.prototype, "formMetadata", void 0);
    __decorate([
        Prop({ required: true })
    ], FormConditionalElement.prototype, "props", void 0);
    FormConditionalElement = __decorate([
        Component
    ], FormConditionalElement);
    return FormConditionalElement;
}(Vue));
export default FormConditionalElement;
