import { __decorate, __extends } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import BaseControl from './BaseControl';
var LabelControl = /** @class */ (function (_super) {
    __extends(LabelControl, _super);
    function LabelControl() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.inputValue = '';
        return _this;
    }
    Object.defineProperty(LabelControl.prototype, "classes", {
        get: function () {
            var _a;
            return (_a = this.props.classes) !== null && _a !== void 0 ? _a : '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LabelControl.prototype, "html", {
        get: function () {
            var _a;
            return (_a = this.props.html) !== null && _a !== void 0 ? _a : false;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop({ default: function () { return ({}); } })
    ], LabelControl.prototype, "props", void 0);
    LabelControl = __decorate([
        Component
    ], LabelControl);
    return LabelControl;
}(BaseControl));
export default LabelControl;
