import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { QInnerLoading, QSpinnerBall, QSpinnerDots, QSpinnerPuff, QSpinnerTail } from 'quasar';
var FormLoader = /** @class */ (function (_super) {
    __extends(FormLoader, _super);
    function FormLoader() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(FormLoader.prototype, "isSubmitting", {
        get: function () {
            return this.formMetadata.isSubmitting;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FormLoader.prototype, "loaderName", {
        get: function () {
            var _a;
            return "q-spinner-" + ((_a = this.props.icon) !== null && _a !== void 0 ? _a : 'dots');
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop({ required: true })
    ], FormLoader.prototype, "formMetadata", void 0);
    __decorate([
        Prop({ required: true })
    ], FormLoader.prototype, "props", void 0);
    FormLoader = __decorate([
        Component({
            components: {
                QInnerLoading: QInnerLoading,
                QSpinnerBall: QSpinnerBall,
                QSpinnerDots: QSpinnerDots,
                QSpinnerPuff: QSpinnerPuff,
                QSpinnerTail: QSpinnerTail
            }
        })
    ], FormLoader);
    return FormLoader;
}(Vue));
export default FormLoader;
