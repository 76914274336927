import { __assign, __decorate, __extends } from "tslib";
import { Component, Prop, Ref } from 'vue-property-decorator';
import { QCard } from 'quasar';
import BaseControl from './BaseControl';
var SignatureControl = /** @class */ (function (_super) {
    __extends(SignatureControl, _super);
    function SignatureControl() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isEmpty = true;
        _this.inputValue = '';
        return _this;
    }
    SignatureControl.prototype.mounted = function () {
        this.signaturePad.resizeCanvas();
    };
    SignatureControl.prototype.saveSignature = function () {
        this.emitInput(this.inputValue);
    };
    Object.defineProperty(SignatureControl.prototype, "controlProps", {
        get: function () {
            var baseInputProps = this.baseInputProps;
            return __assign(__assign({}, baseInputProps), { value: this.inputValue });
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop({ default: function () { return ({}); } })
    ], SignatureControl.prototype, "props", void 0);
    __decorate([
        Ref('signaturePad')
    ], SignatureControl.prototype, "signaturePad", void 0);
    SignatureControl = __decorate([
        Component({
            components: {
                QCard: QCard
            }
        })
    ], SignatureControl);
    return SignatureControl;
}(BaseControl));
export default SignatureControl;
