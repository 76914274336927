import { __awaiter, __generator } from "tslib";
import { createClient, PROXY_BASE_URL } from './serviceVPPClient';
var ENDPOINT_CUSTOMER = '/prima/address/list/filter';
// eslint-disable-next-line import/prefer-default-export
export var getLocationFilterData = function (authToken) { return __awaiter(void 0, void 0, void 0, function () {
    var client, response, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                client = createClient({ url: PROXY_BASE_URL, apikey: '', authToken: authToken });
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, client.post(ENDPOINT_CUSTOMER)];
            case 2:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 3:
                e_1 = _a.sent();
                return [2 /*return*/, null];
            case 4: return [2 /*return*/];
        }
    });
}); };
