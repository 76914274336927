import { __decorate, __extends, __read, __spread } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
var FromText = /** @class */ (function (_super) {
    __extends(FromText, _super);
    function FromText() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(FromText.prototype, "text", {
        get: function () {
            return this.props.text;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FromText.prototype, "wrapperElement", {
        get: function () {
            // TODO: handle other elements
            return 'div';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FromText.prototype, "classes", {
        get: function () {
            var _a = this.props, level = _a.level, weight = _a.weight, align = _a.align, styles = _a.styles;
            var weightClasses = weight ? [weight] : [];
            var alignClasses = align ? [align] : [];
            var allClasses = __spread([level], weightClasses, alignClasses, (styles !== null && styles !== void 0 ? styles : []));
            return allClasses.map(function (cls) { return "text-" + cls; });
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop({ required: true })
    ], FromText.prototype, "props", void 0);
    FromText = __decorate([
        Component
    ], FromText);
    return FromText;
}(Vue));
export default FromText;
