import { __assign, __decorate, __extends } from "tslib";
import { Component, Emit, Prop, PropSync, Ref, Vue } from 'vue-property-decorator';
import { QBtn, QCard, QCardSection, QCheckbox, QExpansionItem, QForm, QIcon, QImg, QInput, QItem, QItemLabel, QItemSection, QList, QRadio, QSelect, QSeparator, QSlideTransition, QToggle, QTooltip } from 'quasar';
import Tooltip from '../../components/tooltip/Tooltip.vue';
import { formatMoney } from '../../helpers';
import store from '../../store';
import TcUsage from '../TariffCalculatorInput/TcUsage.vue';
import labels from './labels';
var TelcoResultObject = /** @class */ (function (_super) {
    __extends(TelcoResultObject, _super);
    function TelcoResultObject() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.PRIMA_DOKUMENTTYP_LEISTUNGSBESCHREIBUNG_ID = 6;
        _this.PRIMA_DOKUMENTTYP_PREISLISTE_ID = 5;
        _this.PRIMA_DOKUMENTTYP_INFOBLATT_ID = 3;
        _this.selectedIndex = 0;
        _this.shippingCost = 0;
        _this.id = 'tr-web-component';
        _this.cardBody = 'card-body';
        _this.mouseDown = false;
        _this.startX = 0;
        _this.scrollLeft = 0;
        _this.productView = _this.productOverview;
        _this.scrollAmount = 0;
        return _this;
    }
    Object.defineProperty(TelcoResultObject.prototype, "isSmallBox", {
        get: function () {
            if (!store.states.clientWidth)
                return false;
            return store.states.clientWidth <= 1140;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TelcoResultObject.prototype, "labels", {
        /*
        handleBruttoNetto(){
      
          switch(this.inputData.customerType)
          {
            case 0:this.inputData.anzeigeNettoBrutto=1;break;
            case 1:this.inputData.anzeigeNettoBrutto=0;break;
            default:this.inputData.anzeigeNettoBrutto=1;break;
          }
      
        } */
        // Computed
        get: function () {
            return __assign(__assign({}, labels), this.customLabels);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TelcoResultObject.prototype, "sortedResultListTelco", {
        get: function () {
            return this.syncResTelcoList.sort(function (a, b) {
                var _a;
                var cmp0 = 0;
                if (a.landlineInternetDownloadSpeed && b.landlineInternetDownloadSpeed) {
                    cmp0 = b.landlineInternetDownloadSpeed - a.landlineInternetDownloadSpeed;
                    if (cmp0 !== 0)
                        return cmp0;
                }
                if (a.productName && b.productName) {
                    cmp0 = (_a = a.productName) === null || _a === void 0 ? void 0 : _a.localeCompare(b.productName);
                    if (cmp0 !== 0)
                        return cmp0;
                }
                return 0;
            });
        },
        enumerable: false,
        configurable: true
    });
    TelcoResultObject.prototype.sumPriceOneTime = function () {
        var _this = this;
        var _a, _b, _c, _d, _e;
        var sum = 0;
        var maxShippingCost = 0;
        (_b = (_a = this.selectedTariff) === null || _a === void 0 ? void 0 : _a.optionGroups) === null || _b === void 0 ? void 0 : _b.forEach(function (g) {
            var _a;
            (_a = g.entries) === null || _a === void 0 ? void 0 : _a.forEach(function (options) {
                if (options.isSelected && (options.preselectedAndReadonly === true || !_this.readOnly(options, g))) {
                    if (options.priceOneTime)
                        sum += options.priceOneTime * (options.chooseAmount ? options.chooseAmount : 1);
                    // if (options.shippingCosts && options.shippingCosts > maxShippingCost) maxShippingCost = options.shippingCosts;
                }
            });
        });
        (_d = (_c = this.selectedTariff) === null || _c === void 0 ? void 0 : _c.hardwareGroups) === null || _d === void 0 ? void 0 : _d.forEach(function (g) {
            var _a;
            (_a = g.entries) === null || _a === void 0 ? void 0 : _a.forEach(function (options) {
                if (options.isSelected && (options.preselectedAndReadonly === true || !_this.readOnly(options, g))) {
                    if (options.priceOneTime)
                        sum += options.priceOneTime * (options.chooseAmount ? options.chooseAmount : 1);
                    if (options.shippingCosts && options.shippingCosts > maxShippingCost)
                        maxShippingCost = options.shippingCosts;
                }
            });
        });
        if ((_e = this.selectedTariff) === null || _e === void 0 ? void 0 : _e.setupCost)
            sum += this.selectedTariff.setupCost;
        if (maxShippingCost) {
            sum += this.shippingCost;
            this.shippingCost = maxShippingCost;
        }
        return sum;
    };
    Object.defineProperty(TelcoResultObject.prototype, "maxStaffeln", {
        get: function () {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            var maxST = (_a = this.selectedTariff) === null || _a === void 0 ? void 0 : _a.monthlyPrices;
            if (((_b = this.selectedTariff) === null || _b === void 0 ? void 0 : _b.monthlyPrices) && ((_d = (_c = this.selectedTariff) === null || _c === void 0 ? void 0 : _c.monthlyPrices) === null || _d === void 0 ? void 0 : _d.length) > 0)
                maxST = (_e = this.selectedTariff) === null || _e === void 0 ? void 0 : _e.monthlyPrices;
            (_g = (_f = this.selectedTariff) === null || _f === void 0 ? void 0 : _f.optionGroups) === null || _g === void 0 ? void 0 : _g.forEach(function (g) {
                var _a;
                (_a = g.entries) === null || _a === void 0 ? void 0 : _a.forEach(function (options) {
                    if (maxST && options.monthlyPrices.length >= maxST.length && options.isSelected)
                        maxST = options.monthlyPrices;
                });
            });
            (_j = (_h = this.selectedTariff) === null || _h === void 0 ? void 0 : _h.hardwareGroups) === null || _j === void 0 ? void 0 : _j.forEach(function (g) {
                var _a;
                (_a = g.entries) === null || _a === void 0 ? void 0 : _a.forEach(function (options) {
                    if (maxST && options.monthlyPrices.length >= maxST.length && options.isSelected)
                        maxST = options.monthlyPrices;
                });
            });
            return maxST;
        },
        enumerable: false,
        configurable: true
    });
    TelcoResultObject.prototype.optionsAmount = function (maxAnzahl) {
        var allOptions = [{ label: '1', value: 1 }];
        // eslint-disable-next-line no-plusplus
        for (var i = 2; i <= maxAnzahl; i++) {
            allOptions.push({ label: "" + i, value: i });
        }
        return allOptions;
    };
    TelcoResultObject.prototype.sumpPricePerMonth = function (index) {
        var _this = this;
        var _a, _b, _c, _d, _e, _f;
        var sum = 0;
        (_b = (_a = this.selectedTariff) === null || _a === void 0 ? void 0 : _a.optionGroups) === null || _b === void 0 ? void 0 : _b.forEach(function (g) {
            var _a;
            (_a = g.entries) === null || _a === void 0 ? void 0 : _a.forEach(function (options) {
                var _a, _b, _c, _d, _e;
                if (options.isSelected && (options.preselectedAndReadonly === true || !_this.readOnly(options, g))) {
                    if (((_a = options.monthlyPrices) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                        if (index) {
                            var pricePerMonth = (_b = options.monthlyPrices[index]) === null || _b === void 0 ? void 0 : _b.pricePerMonth;
                            if (pricePerMonth) {
                                if (pricePerMonth !== 0)
                                    sum += pricePerMonth * (options.chooseAmount ? options.chooseAmount : 1);
                            }
                            else {
                                var optionsPrice = (_c = options.monthlyPrices[0]) === null || _c === void 0 ? void 0 : _c.pricePerMonth;
                                var to = (_d = options.monthlyPrices[0]) === null || _d === void 0 ? void 0 : _d.to;
                                if (optionsPrice !== 0 && (to === 0 || to === undefined || to === null)) {
                                    sum += optionsPrice * (options.chooseAmount ? options.chooseAmount : 1);
                                }
                            }
                        }
                        else {
                            var pricePerMonth = (_e = options.monthlyPrices[0]) === null || _e === void 0 ? void 0 : _e.pricePerMonth;
                            if (pricePerMonth !== 0) {
                                sum += pricePerMonth * (options.chooseAmount ? options.chooseAmount : 1);
                            }
                        }
                    }
                }
            });
        });
        (_d = (_c = this.selectedTariff) === null || _c === void 0 ? void 0 : _c.hardwareGroups) === null || _d === void 0 ? void 0 : _d.forEach(function (g) {
            var _a;
            (_a = g.entries) === null || _a === void 0 ? void 0 : _a.forEach(function (options) {
                var _a, _b, _c, _d;
                if (options.isSelected) {
                    if (options.isSelected && (options.preselectedAndReadonly === true || !_this.readOnly(options, g))) {
                        if (((_a = options.monthlyPrices) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                            if (index) {
                                var pricePerMonth = (_b = options.monthlyPrices[index]) === null || _b === void 0 ? void 0 : _b.pricePerMonth;
                                if (pricePerMonth) {
                                    if (pricePerMonth !== 0)
                                        sum += pricePerMonth * (options.chooseAmount ? options.chooseAmount : 1);
                                }
                                else {
                                    var optionsPrice = (_c = options.monthlyPrices[0]) === null || _c === void 0 ? void 0 : _c.pricePerMonth;
                                    if (optionsPrice !== 0) {
                                        sum += optionsPrice * (options.chooseAmount ? options.chooseAmount : 1);
                                    }
                                }
                            }
                            else {
                                var pricePerMonth = (_d = options.monthlyPrices[0]) === null || _d === void 0 ? void 0 : _d.pricePerMonth;
                                if (pricePerMonth !== 0) {
                                    sum += pricePerMonth * (options.chooseAmount ? options.chooseAmount : 1);
                                }
                            }
                        }
                    }
                }
            });
        });
        if ((_e = this.selectedTariff) === null || _e === void 0 ? void 0 : _e.monthlyPrices) {
            if (index && this.selectedTariff.monthlyPrices.length >= index + 1) {
                var pricePerMonth = this.selectedTariff.monthlyPrices[index].pricePerMonth;
                sum += pricePerMonth || 0;
            }
            else {
                sum += this.monthlyPriceNumber((_f = this.selectedTariff) === null || _f === void 0 ? void 0 : _f.monthlyPrices);
            }
        }
        return sum;
    };
    TelcoResultObject.prototype.slider = function (orientation) {
        if (orientation === 'left') {
            if (this.scrollAmount === 0)
                return;
            this.scrollWrapper.scrollTo({
                left: this.scrollAmount -= this.productBox[0].offsetWidth,
                behavior: 'smooth'
            });
        }
        if (orientation === 'right') {
            if (this.scrollAmount % this.sortedResultListTelco.length === 1)
                return;
            this.scrollWrapper.scrollTo({
                left: this.scrollAmount += this.productBox[0].offsetWidth,
                behavior: 'smooth'
            });
        }
    };
    TelcoResultObject.prototype.startDragging = function (e) {
        this.mouseDown = true;
        this.startX = e.pageX - this.scrollWrapper.offsetLeft;
        this.scrollLeft = this.scrollWrapper.scrollLeft;
    };
    TelcoResultObject.prototype.stopDragging = function () {
        this.mouseDown = false;
    };
    TelcoResultObject.prototype.mouseMove = function (e) {
        e.preventDefault();
        if (!this.mouseDown) {
            return;
        }
        var x = e.pageX - this.scrollWrapper.offsetLeft;
        var scroll = x - this.startX;
        this.scrollWrapper.scrollLeft = this.scrollLeft - scroll;
    };
    TelcoResultObject.prototype.readOnly = function (item, group) {
        var readOnly = item.readonly;
        if (item.preselectedAndReadonly) {
            // eslint-disable-next-line no-param-reassign
            item.isSelected = true;
        }
        if (item.relConditions && item.relConditions.length > 0) {
            readOnly = true;
            item.relConditions.forEach(function (con) {
                if (group.entries.find(function (s) { return s.refRootId.toString() === con.split("#")[1] && s.isSelected; })) {
                    readOnly = false;
                    // eslint-disable-next-line no-param-reassign
                    //     if(item.preselected)item.isSelected=true;
                }
            });
            // eslint-disable-next-line no-param-reassign
            // if(readOnly)item.isSelected=false; // wenn Option eine bedingungen hat und die nicht erfüllt, dann auswahl deselekten
            // eslint-disable-next-line no-param-reassign
            item.readonly = readOnly;
        }
        else {
            readOnly = item.readonly;
        }
        return readOnly;
    };
    TelcoResultObject.prototype.addAdditionalCosts = function (item) {
        var _a, _b;
        this.syncCountChoose += 1;
        if (!item.readonly) {
            if (item.isSelected) {
                this.syncAdditionCost += item.priceOneTime;
                if (((_a = item.monthlyPrices) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                    var pricePerMonth = item.monthlyPrices[0].pricePerMonth;
                    if (pricePerMonth !== 0) {
                        this.syncAdditionCostMonthly += pricePerMonth;
                    }
                }
            }
            else {
                this.syncAdditionCost -= item.priceOneTime;
                if (((_b = item.monthlyPrices) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                    var pricePerMonth = item.monthlyPrices[0].pricePerMonth;
                    if (pricePerMonth !== 0) {
                        this.syncAdditionCostMonthly -= pricePerMonth;
                    }
                }
            }
        }
        else {
            // eslint-disable-next-line no-param-reassign
            item.isSelected = true;
        }
    };
    TelcoResultObject.prototype.updateForm = function () {
        this.syncCountChoose += 1;
    };
    TelcoResultObject.prototype.setDisabled = function (item) {
        if (item.isSelected) {
            // eslint-disable-next-line no-param-reassign
            item.isSelected = false;
        }
        else { // eslint-disable-next-line no-param-reassign
            item.isSelected = true;
        }
    };
    TelcoResultObject.prototype.addAdditionalCostsOnceSelectionAllGoup = function (item, produkt) {
        var _a, _b;
        console.log('addAdditionalCostsOnceSelectionAllGoup');
        this.syncCountChoose += 1;
        if (!item.readonly) {
            (_a = produkt.hardwareGroups) === null || _a === void 0 ? void 0 : _a.forEach(function (list) {
                list.entries.forEach(function (o) {
                    if (o.relConditions.length > 0 && item.relConditions.length > 0) {
                        if (o.isSelected && o.refRootId !== item.refRootId) {
                            // eslint-disable-next-line no-param-reassign
                            o.isSelected = false;
                        }
                        else if (o.refRootId === item.refRootId) {
                            // eslint-disable-next-line no-param-reassign
                        }
                    }
                    if (o.relConditions.length < 1 && item.relConditions.length < 1) {
                        if (o.isSelected && o.refRootId !== item.refRootId) {
                            // eslint-disable-next-line no-param-reassign
                            o.isSelected = false;
                        }
                        else if (o.refRootId === item.refRootId) {
                            // eslint-disable-next-line no-param-reassign
                            o.isSelected = true;
                        }
                    }
                });
            });
            (_b = produkt.optionGroups) === null || _b === void 0 ? void 0 : _b.forEach(function (list) {
                list.entries.forEach(function (o) {
                    if (o.relConditions.length > 0 && item.relConditions.length > 0) {
                        if (o.isSelected && o.refRootId !== item.refRootId) {
                            // eslint-disable-next-line no-param-reassign
                            o.isSelected = false;
                        }
                        else if (o.refRootId === item.refRootId) {
                            // eslint-disable-next-line no-param-reassign
                        }
                    }
                    if (o.relConditions.length < 1 && item.relConditions.length < 1) {
                        if (o.isSelected && o.refRootId !== item.refRootId) {
                            // eslint-disable-next-line no-param-reassign
                            o.isSelected = false;
                        }
                        else if (o.refRootId === item.refRootId) {
                            // eslint-disable-next-line no-param-reassign
                            o.isSelected = true;
                        }
                    }
                });
            });
        }
        else {
            // eslint-disable-next-line no-param-reassign
            item.isSelected = true;
        }
    };
    TelcoResultObject.prototype.addAdditionalCostsOnceSelection = function (item, list) {
        this.syncCountChoose += 1;
        if (!item.readonly) {
            list.forEach(function (o) {
                if (o.relConditions.length > 0 && item.relConditions.length > 0) {
                    if (o.isSelected && o.refRootId !== item.refRootId) {
                        // eslint-disable-next-line no-param-reassign
                        o.isSelected = false;
                    }
                    else if (o.refRootId === item.refRootId) {
                        // eslint-disable-next-line no-param-reassign
                    }
                }
                if (o.relConditions.length < 1 && item.relConditions.length < 1) {
                    if (o.isSelected && o.refRootId !== item.refRootId) {
                        // eslint-disable-next-line no-param-reassign
                        o.isSelected = false;
                    }
                    else if (o.refRootId === item.refRootId) {
                        // eslint-disable-next-line no-param-reassign
                        o.isSelected = true;
                    }
                }
            });
        }
        else {
            // eslint-disable-next-line no-param-reassign
            item.isSelected = true;
        }
    };
    TelcoResultObject.prototype.addAdditionalCostsOnceMaybeSelection = function (item, list) {
        this.syncCountChoose += 1;
        if (!item.readonly) {
            list.forEach(function (o) {
                if (o.relConditions.length < 1 && item.relConditions.length < 1) {
                    if (o.isSelected && o.refRootId !== item.refRootId) {
                        // eslint-disable-next-line no-param-reassign
                        o.isSelected = false;
                    }
                    else if (o.refRootId === item.refRootId) {
                        // eslint-disable-next-line no-param-reassign
                        // o.isSelected = true;
                    }
                }
                if (o.relConditions.length > 0 && item.relConditions.length > 0) {
                    if (o.isSelected && o.refRootId !== item.refRootId) {
                        // eslint-disable-next-line no-param-reassign
                    }
                    else if (o.refRootId === item.refRootId) {
                        // eslint-disable-next-line no-param-reassign
                        // o.isSelected = true;
                    }
                }
            });
        }
        else {
            // eslint-disable-next-line no-param-reassign
            item.isSelected = true;
        }
    };
    TelcoResultObject.prototype.addHardwareAdditionalCosts = function (options, list) {
        var _this = this;
        this.syncCountChoose += 1;
        list.forEach(function (o) {
            if (o.isSelected && o.refRootId !== options.refRootId) {
                // eslint-disable-next-line no-param-reassign
                if (!o.relConditions)
                    o.isSelected = false;
            }
            else if (o.refRootId === options.refRootId) {
                if (o.shippingCosts > 0)
                    _this.shippingCost = o.shippingCosts;
                // eslint-disable-next-line no-param-reassign
                o.isSelected = true;
            }
        });
    };
    TelcoResultObject.prototype.documentURL = function (item) {
        if (item.productDocuments) {
            var productDocument = item.productDocuments.find(function (d) { return d.categoryId === 4; });
            return productDocument === null || productDocument === void 0 ? void 0 : productDocument.uRL;
        }
        return '';
    };
    TelcoResultObject.prototype.productInfoURL = function (item) {
        var _this = this;
        if (item.productDocuments) {
            var productDocument = item.productDocuments.find(function (d) { return d.categoryId === _this.PRIMA_DOKUMENTTYP_INFOBLATT_ID; });
            return productDocument === null || productDocument === void 0 ? void 0 : productDocument.uRL;
        }
        return '';
    };
    TelcoResultObject.prototype.PriceListURL = function (item) {
        var _this = this;
        if (item.productDocuments) {
            var productDocument = item.productDocuments.find(function (d) { return d.categoryId === _this.PRIMA_DOKUMENTTYP_PREISLISTE_ID; });
            return productDocument === null || productDocument === void 0 ? void 0 : productDocument.uRL;
        }
        return '';
    };
    TelcoResultObject.prototype.LeistungsbeschreibungURL = function (item) {
        var _this = this;
        if (item.productDocuments) {
            var productDocument = item.productDocuments.find(function (d) { return d.categoryId === _this.PRIMA_DOKUMENTTYP_LEISTUNGSBESCHREIBUNG_ID; });
            return productDocument === null || productDocument === void 0 ? void 0 : productDocument.uRL;
        }
        return '';
    };
    TelcoResultObject.prototype.getKey = function (was, index) {
        return "" + was + index;
    };
    TelcoResultObject.prototype.monthlyPrice = function (item) {
        if ((item === null || item === void 0 ? void 0 : item.length) > 0) {
            return formatMoney(this.monthlyPriceNumber(item));
        }
        return formatMoney(0);
    };
    TelcoResultObject.prototype.monthlyPriceNumber = function (item) {
        if ((item === null || item === void 0 ? void 0 : item.length) > 0) {
            var pricePerMonth = item[0].pricePerMonth;
            return pricePerMonth;
        }
        return 0;
    };
    TelcoResultObject.prototype.formatDate = function (datum) {
        var formatDate;
        var month = null;
        var day = null;
        if (datum) {
            if (datum === null || datum === void 0 ? void 0 : datum.dayOfMonth) {
                if (datum.dayOfMonth.toString().length < 2) {
                    day = "0" + datum.dayOfMonth;
                }
                else {
                    day = "" + datum.dayOfMonth;
                }
            }
            if (datum === null || datum === void 0 ? void 0 : datum.monthValue) {
                if (datum.monthValue.toString().length < 2) {
                    month = "0" + datum.monthValue;
                }
                else {
                    month = "" + datum.monthValue;
                }
            }
            var isNotValidFormat = ((datum === null || datum === void 0 ? void 0 : datum.year) + "-" + month + "-" + day).includes('undefined');
            if (datum && isNotValidFormat) {
                formatDate = datum;
            }
            else {
                formatDate = (datum === null || datum === void 0 ? void 0 : datum.year) + "-" + month + "-" + day;
            }
        }
        else
            return '';
        return formatDate;
    };
    TelcoResultObject.prototype.formatValue = function (value) {
        return formatMoney(value);
    };
    TelcoResultObject.prototype.formatNumber = function (number) {
        return number.toFixed(2).replace('.', ',');
    };
    TelcoResultObject.prototype.getChooseSelectName = function (index) {
        return "SelectTariff_" + index;
    };
    TelcoResultObject.prototype.getChooseSelectOption = function (indexOption) {
        return "SelectOption_" + indexOption;
    };
    TelcoResultObject.prototype.getChooseSelectHardware = function (indexOption) {
        return "SelectHardware_" + indexOption;
    };
    TelcoResultObject.prototype.isLandlineTelephone = function (result) {
        return result.landlineTelephone;
    };
    TelcoResultObject.prototype.getChooseClassName = function (result) {
        return "TR-Result_" + result.rootId;
    };
    TelcoResultObject.prototype.selectProductForConfig = function (index) {
        var _this = this;
        var _a, _b;
        this.syncAdditionCostMonthly = 0;
        this.syncAdditionCost = 0;
        this.syncAdditionCostHardWareMonthly = 0;
        this.syncAdditionCostHardWare = 0;
        this.selectedTariff = undefined;
        this.syncShowOptions = false;
        this.selectedTariff = this.syncResTelcoList[index];
        (_a = this.selectedTariff.optionGroups) === null || _a === void 0 ? void 0 : _a.forEach(function (g) {
            var _a;
            (_a = g.entries) === null || _a === void 0 ? void 0 : _a.forEach(function (o) {
                if (o.preselected) {
                    if (o.readonly) {
                        // eslint-disable-next-line no-param-reassign
                        o.preselectedAndReadonly = true;
                    }
                    // eslint-disable-next-line no-param-reassign
                    o.isSelected = true;
                }
                else {
                    // eslint-disable-next-line no-param-reassign
                    o.isSelected = false;
                }
                // eslint-disable-next-line no-param-reassign
                o.chooseAmount = 1;
            });
        });
        (_b = this.selectedTariff.hardwareGroups) === null || _b === void 0 ? void 0 : _b.forEach(function (g) {
            var _a;
            (_a = g.entries) === null || _a === void 0 ? void 0 : _a.forEach(function (o) {
                if (o.preselected) {
                    if (o.readonly) {
                        // eslint-disable-next-line no-param-reassign
                        o.preselectedAndReadonly = true;
                    }
                    if (o.shippingCosts > 0)
                        _this.shippingCost = o.shippingCosts;
                    // eslint-disable-next-line no-param-reassign
                    o.isSelected = true;
                }
                else {
                    // eslint-disable-next-line no-param-reassign
                    o.isSelected = false;
                }
            });
        });
        this.selectedIndex = index;
        this.syncShowOptions = true;
        setTimeout(function () { return window.scrollTo({ top: 10, behavior: 'smooth' }); }, 300);
    };
    TelcoResultObject.prototype.selectProduct = function () { };
    TelcoResultObject.prototype.goTo = function () { };
    __decorate([
        Prop({ default: function () { return ({}); } })
    ], TelcoResultObject.prototype, "customLabels", void 0);
    __decorate([
        Prop({ required: false })
    ], TelcoResultObject.prototype, "productOverview", void 0);
    __decorate([
        Prop({ required: true })
    ], TelcoResultObject.prototype, "customerType", void 0);
    __decorate([
        PropSync('resultTelcoList', { default: function () { return []; } })
    ], TelcoResultObject.prototype, "syncResTelcoList", void 0);
    __decorate([
        PropSync('additionCost', { type: Number, required: false })
    ], TelcoResultObject.prototype, "syncAdditionCost", void 0);
    __decorate([
        PropSync('additionCostMonthly', { type: Number, required: false })
    ], TelcoResultObject.prototype, "syncAdditionCostMonthly", void 0);
    __decorate([
        PropSync('additionCostHardWareMonthly', { type: Number, required: false })
    ], TelcoResultObject.prototype, "syncAdditionCostHardWareMonthly", void 0);
    __decorate([
        PropSync('additionCostHardWare', { type: Number, required: false })
    ], TelcoResultObject.prototype, "syncAdditionCostHardWare", void 0);
    __decorate([
        PropSync('showOptions', { type: Boolean, required: false })
    ], TelcoResultObject.prototype, "syncShowOptions", void 0);
    __decorate([
        PropSync('countChoose', { type: Number, required: false })
    ], TelcoResultObject.prototype, "syncCountChoose", void 0);
    __decorate([
        Ref('scrolling-wrapper')
    ], TelcoResultObject.prototype, "scrollWrapper", void 0);
    __decorate([
        Ref('dragBox')
    ], TelcoResultObject.prototype, "section", void 0);
    __decorate([
        Ref('wrapper')
    ], TelcoResultObject.prototype, "wrapper", void 0);
    __decorate([
        Ref('product-box')
    ], TelcoResultObject.prototype, "productBox", void 0);
    __decorate([
        Emit()
    ], TelcoResultObject.prototype, "selectProduct", null);
    __decorate([
        Emit()
    ], TelcoResultObject.prototype, "goTo", null);
    TelcoResultObject = __decorate([
        Component({
            filters: {
                convertPlural: function (value) {
                    if (value === 0) {
                        return labels.infinityPeriod;
                    }
                    if (value <= 1) {
                        return value + " Monat";
                    }
                    return value + " Monate";
                },
                telephoneFlat: function (value) {
                    return value ? '' : null;
                },
                formatCurrency: function (value) {
                    return value ? formatMoney(value) : formatMoney(0);
                }
            },
            components: {
                Tooltip: Tooltip,
                QBtn: QBtn,
                QCard: QCard,
                QIcon: QIcon,
                QItem: QItem,
                QItemLabel: QItemLabel,
                QSeparator: QSeparator,
                QTooltip: QTooltip,
                QList: QList,
                QItemSection: QItemSection,
                QExpansionItem: QExpansionItem,
                QForm: QForm,
                QInput: QInput,
                QCardSection: QCardSection,
                QToggle: QToggle,
                QSelect: QSelect,
                QSlideTransition: QSlideTransition,
                TcUsage: TcUsage,
                QRadio: QRadio,
                QCheckbox: QCheckbox,
                QImg: QImg
            }
        })
    ], TelcoResultObject);
    return TelcoResultObject;
}(Vue));
export default TelcoResultObject;
