export var usageOptionsElectricity = [
    {
        label: 1700,
        value: 1700,
        description: '1',
        icon: 'person'
    },
    {
        label: 2500,
        value: 2500,
        description: '2',
        icon: 'person'
    },
    {
        label: 3200,
        value: 3200,
        description: '3',
        icon: 'person'
    },
    {
        label: 4000,
        value: 4000,
        description: '4',
        icon: 'person'
    }
];
export var usageOptionsGas = [
    {
        label: 13000,
        value: 13000,
        description: 's',
        icon: 'house'
    },
    {
        label: 20000,
        value: 20000,
        description: 'm',
        icon: 'house'
    },
    {
        label: 27000,
        value: 27000,
        description: 'l',
        icon: 'house'
    }
];
