import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { QSeparator } from 'quasar';
var FormSeparator = /** @class */ (function (_super) {
    __extends(FormSeparator, _super);
    function FormSeparator() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FormSeparator = __decorate([
        Component({
            components: { QSeparator: QSeparator }
        })
    ], FormSeparator);
    return FormSeparator;
}(Vue));
export default FormSeparator;
