import { __awaiter, __generator } from "tslib";
import { createClient, PROXY_BASE_URL } from './serviceProxy';
var ENDPOINT_PATH_CONTACT_FORM = '/termination';
var ENPOINTCONFI = '/config/contactform';
export var sendMailData = function (body, apiKey) { return __awaiter(void 0, void 0, void 0, function () {
    var client, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                client = createClient({ url: PROXY_BASE_URL, apikey: apiKey });
                return [4 /*yield*/, client.post(ENDPOINT_PATH_CONTACT_FORM, body)];
            case 1:
                response = _a.sent();
                if (response.data.data.status !== 1) {
                    console.error(response.data.data.statustext);
                }
                return [2 /*return*/, response.data];
        }
    });
}); };
export var getSubject = function (tariffCalculatorTypeId, status, apiKey) { return __awaiter(void 0, void 0, void 0, function () {
    var client, response;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                client = createClient({ url: PROXY_BASE_URL, apikey: apiKey });
                return [4 /*yield*/, client.get(ENPOINTCONFI.concat('?calculatorType=').concat("" + tariffCalculatorTypeId))];
            case 1:
                response = _b.sent();
                return [2 /*return*/, (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data];
        }
    });
}); };
