var labels = {
    existCustomerNum: '',
    umzug: 'Umzug',
    adressdaten: 'Adressdaten',
    ihrFirma: 'Ihr Firma',
    auftragsbestaetigung: 'Auftragsbestätigung',
    afterChange: '(nach Abzug aller Boni)',
    gesamtpreis: 'Gesamtpreis',
    preisinformation: 'Preisinformation',
    unit: '€ ',
    perYear: '/ Jahr',
    perMonth: '/ Monat',
    perOnce: ' einmalig',
    arbeitspreis: 'Arbeitspreis',
    arbeitspreisHT: 'Arbeitspreis (HT)',
    arbeitspreisNT: 'Arbeitspreis (NT)',
    grundpreis: 'Grundpreis',
    centPerKwh: 'ct / kWh',
    ihrTariff: 'Ihr Tarif',
    textConfirm: 'Vielen Dank für Ihren Auftrag! Wir werden uns in Kürze mit Ihnen in Verbindung setzen.',
    btnText: 'Auftragsbestätigung ausdrucken',
    backToHome: 'Zurück zum Tarifrechner',
    ersparnis: 'Ersparnis vs. Vergleichstarif',
    vertragsInformation: 'Vertragsinformation',
    vertragslaufzeit: 'Vertragslaufzeit',
    ihrVerbrauch: 'Ihr Jahresverbrauch',
    kuendigungsfrist: 'Kündigungsfrist',
    verbrauch: 'Verbrauch',
    verbrauchHT: 'Verbrauch (HT)',
    verbrauchNT: 'Verbrauch (NT)',
    lieferanschrift: 'Lieferanschrift',
    rechnungsanschrift: 'abweichende Rechnungsanschrift',
    abwlieferanschrift: 'abweichende Lieferadresse',
    geburtsdatum: 'Geburtsdatum',
    ihrVorversorger: 'Ihr Vorversorger',
    kundennummer: 'Kundennummer',
    vorversorger: 'Vorversorger',
    bankverbindung: 'Bankverbindung',
    iban: 'IBAN',
    methode: 'Methode',
    sepa_lastschrift: 'SEPA Lastschrift',
    bic: 'BIC',
    bankinstitute: 'Bankinstitut',
    ueberweisung: 'Überweisung',
    kontaktdaten: 'Kontaktdaten',
    mailadresse: 'E-Mail',
    telefonnummer: 'Telefonnummer',
    faxnummer: 'Faxnummer',
    handynummer: 'Handynummer',
    zaehlernummer: 'Zählernummer',
    einzugsdatum: 'Einzugsdatum',
    bisherige: 'bisherige',
    zaehlerstand: 'Zählerstand',
    zaehlerstandHT: 'Zählerstand HT',
    zaehlerstandNT: 'Zählerstand NT',
    anschlussdaten: 'Anschlussdaten',
    wechselgrund: 'Grund für den Wechsel',
    lieferantenwechsel: 'Lieferantenwechsel',
    download: 'Download',
    tarifInfo: 'Tarifinformation',
    ecoTarif: 'Ökotarif',
    yes: 'ja',
    irrelevant: 'egal',
    tariffType: 'Zählerart',
    customerType: 'Kundenart',
    business: 'Gewerbe',
    private: 'Privat',
    tarifftypeName: 'Tariftyp',
    waermepumpe: 'Wärmepumpe',
    speicherheizung: 'Speicherheizung',
    eintarif: 'Eintarif',
    zweitarif: 'Zweitarif',
    autoStromGemeinsameMessung: 'Autostrom gemeinsame Messung',
    autoStromGetrennteMessung: 'Autostrom getrennte Messung',
    EVN: 'Einzelverbindungsnachweis',
    nextPossibleDateText: 'zum nächstmöglichen Termin',
    startOfDelivery: 'Gewünschter Lieferbeginn',
    newText: 'neue Rufnummer',
    oldText: 'Bestehende Rufnummer(n) portieren',
    numberPortability: 'Rufnummer(n)',
    installLocationTitle: 'Installationsort/Stockwerk',
    isprepaidtarifftrue: 'ja',
    isprepaidtarifffalse: 'nein',
    bonusChange: 'Wechselbonus',
    waiverCancellationPolicy: 'Hiermit verzichte auf mein Widerrufsrecht'
};
export default labels;
