import { __decorate, __extends, __read, __spread } from "tslib";
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { QBtn } from 'quasar';
var FormBackButton = /** @class */ (function (_super) {
    __extends(FormBackButton, _super);
    function FormBackButton() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(FormBackButton.prototype, "classes", {
        get: function () {
            if (this.props.classes) {
                if (this.props.classes.includes('tr-mobile')) {
                    var arrClasses = this.props.classes.split(' ');
                    var addNewClass = __spread(arrClasses, ['full-width']);
                    return addNewClass.join(' ');
                }
                return this.props.classes;
            }
            return '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FormBackButton.prototype, "disable", {
        get: function () {
            return false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FormBackButton.prototype, "title", {
        get: function () {
            return this.props.title;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Inject()
    ], FormBackButton.prototype, "backForm", void 0);
    __decorate([
        Prop({ required: true })
    ], FormBackButton.prototype, "formMetadata", void 0);
    __decorate([
        Prop({ required: true })
    ], FormBackButton.prototype, "props", void 0);
    FormBackButton = __decorate([
        Component({ components: { QBtn: QBtn } })
    ], FormBackButton);
    return FormBackButton;
}(Vue));
export default FormBackButton;
