import { render, staticRenderFns } from "./LabelControl.vue?vue&type=template&id=9ac91ccc&scoped=true&"
import script from "./LabelControl.vue?vue&type=script&lang=ts&"
export * from "./LabelControl.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ac91ccc",
  null
  
)

export default component.exports