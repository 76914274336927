var labels = {
    tariffNotFound: 'Zur gewählten Örtlichkeit konnten leider keine Tarife für Sie gefunden werden!',
    header: 'Tarifrechner',
    private: 'Privat',
    business: 'Gewerbe',
    oneTariff: 'Eintarif',
    twoTariffs: 'Zweitarif',
    zipCode: 'Postleitzahl',
    city: 'Ort',
    street: 'Straße',
    houseNumber: 'Hausnummer',
    locationNotUnique: 'Standort nicht eindeutig ',
    locationNotFound: 'Standort nicht gefunden',
    usage: 'kWh/Jahr',
    usageAlternative: 'oder',
    usageOptions: 'Anzahl der Personen',
    onlyEco: '',
    search: '',
    tarifTyp: 'Tariftyp',
    selectTariff: 'Zählerart',
    customerType: 'Kundenart',
    labelHT: 'Verbrauch (HT)',
    labelNT: 'Verbrauch (NT)',
    usageInvalidMessage: 'dieser Wert ist nicht erlaubt',
    zipCodeInvalidMessage: 'Postleitzahl ist ungültig',
    labelHeaderPersonNumber: 'Personen im Haushalt',
    electricity: 'Strom',
    gas: 'Gas',
    waermepumpe: 'Wärmepumpe',
    speicherheizung: 'Speicherheizung',
    heizstrom: 'Heizstrom',
    autostrom: 'Autostrom',
    telco: 'Internet',
    gemeinsameMessung: 'gemeinsame Messung',
    getrennteMessung: 'getrennte Messung',
    thgURL: '',
    thgAGBText: 'Ein Service der GET AG.',
    gemeinsameMessungToolTip: 'Ihre Wallbox ist am Haushaltszähler angeschlossen oder Sie laden mit der Schuko Steckdose ohne separaten Zähler.',
    getrennteMessungToolTip: 'Sie laden Ihr Auto über einen separaten Zähler, zusätzlich zum Haushaltsstromzähler.',
    consumption: 'Verbrauch',
    tooltippZählerart: ' Bei einem Eintarifzähler geben Sie bitte nur den Verbrauch (HT) an. Ist bei Ihnen ein Zweitarifzähler verbaut, wird der Verbrauch zu bestimmten Zeiten differenziert erfasst, getrennt nach Tag\n' +
        '                    (Hauptzeit - HT) und Nacht (Nebenzeit - NT).',
    tooltippVerbrauch: ' Bei einem Eintarifzähler geben Sie bitte nur den Verbrauch (HT) an. Ist bei Ihnen ein Zweitarifzähler verbaut, wird der Verbrauch zu bestimmten Zeiten differenziert erfasst, getrennt nach Tag\n' +
        '                    (Hauptzeit - HT) und Nacht (Nebenzeit - NT).'
};
export default labels;
