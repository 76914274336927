import { __assign, __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { QCard } from 'quasar';
import EventBus from '../../utils/eventBus';
var Toast = /** @class */ (function (_super) {
    __extends(Toast, _super);
    function Toast() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.toastList = [];
        return _this;
    }
    Toast.prototype.mounted = function () {
        var _this = this;
        EventBus.$on('DATA_TOAST', function (config) {
            _this.extendConfig(config);
        });
    };
    Toast.prototype.beforeDestroy = function () {
        var _this = this;
        EventBus.$off('DATA_TOAST', function (config) {
            _this.extendConfig(config);
        });
    };
    Toast.prototype.addToast = function (config) {
        this.toastList.push(config);
        this.setTimer(config);
    };
    Toast.prototype.extendConfig = function (defaultConfig) {
        var extConfig = __assign({ id: Date.now() }, defaultConfig);
        this.addToast(extConfig);
    };
    Toast.prototype.setTimer = function (config) {
        var _this = this;
        setTimeout(function (id) { return _this.removeToast(id); }, config.duration, config.id);
    };
    Toast.prototype.removeToast = function (id) {
        var index = this.toastList.findIndex(function (value) { return value.id === id; });
        this.toastList.splice(index, 1);
    };
    Toast = __decorate([
        Component({
            components: {
                QCard: QCard
            }
        })
    ], Toast);
    return Toast;
}(Vue));
export default Toast;
