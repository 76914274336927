import { __awaiter, __generator } from "tslib";
import Axios from 'axios';
import { createClient } from './serviceProxy';
var ENDPOINT_MALO = '/view';
// const ENDPOINT_MALO = '/config/view';
export default (function (_a) {
    var apikey = _a.apikey;
    var client = createClient({ apikey: apikey });
    var getExternalLabels = function (calculatorType, versorgerId, page) { return __awaiter(void 0, void 0, Promise, function () {
        var response, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, client.get("" + ENDPOINT_MALO, {
                            params: {
                                calculatorType: calculatorType,
                                supplierId: versorgerId,
                                page: page
                            }
                        })];
                case 1:
                    response = _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    if (Axios.isCancel(e_1))
                        return [2 /*return*/, null];
                    return [2 /*return*/, e_1.message];
                case 3: return [2 /*return*/, response ? response.data : null];
            }
        });
    }); };
    return { getExternalLabels: getExternalLabels };
});
