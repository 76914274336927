import { __decorate, __extends } from "tslib";
import { Component, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
import { QField, QIcon, QInput, QItem, QItemSection, QSelect, QSpinner, QTooltip } from 'quasar';
var TcAddress = /** @class */ (function (_super) {
    __extends(TcAddress, _super);
    function TcAddress() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cityOptions = [];
        _this.streetOptions = [];
        _this.houseNumberOptions = [];
        _this.initStreetOpt = [];
        _this.initHouseNumberOpt = [];
        _this.cityFilterKey = null;
        _this.streetFilterKey = null;
        _this.houseNumberFilterKey = null;
        _this.streetEntity = 'Stra&szlig;e';
        return _this;
    }
    Object.defineProperty(TcAddress.prototype, "isOnlyZipCode", {
        get: function () {
            return this.onlyZipCode;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TcAddress.prototype, "streetOptionsFiltered", {
        get: function () {
            var _a = this, streetOptions = _a.streetOptions, streetFilterKey = _a.streetFilterKey;
            return this.getFilteredOptions(streetOptions, streetFilterKey);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TcAddress.prototype, "cityOptionsFiltered", {
        get: function () {
            var _a = this, cityFilterKey = _a.cityFilterKey, cityOptions = _a.cityOptions;
            return this.getFilteredOptions(cityOptions, cityFilterKey);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TcAddress.prototype, "houseNumberOptionsFiltered", {
        get: function () {
            var _a = this, houseNumberFilterKey = _a.houseNumberFilterKey, houseNumberOptions = _a.houseNumberOptions;
            return this.getFilteredOptions(houseNumberOptions, houseNumberFilterKey);
        },
        enumerable: false,
        configurable: true
    });
    TcAddress.prototype.getFilteredOptions = function (options, key) {
        if (!key)
            return options;
        return options.filter(function (option) { return option.label.toLowerCase().includes(key.toLowerCase()); });
    };
    Object.defineProperty(TcAddress.prototype, "locationFieldState", {
        get: function () {
            var cityOptions = this.cityOptions;
            var hasOneOption = cityOptions && cityOptions.length === 1;
            var isZipCodeInvalid = !!this.zipCodeError;
            return {
                disabled: hasOneOption || isZipCodeInvalid
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TcAddress.prototype, "locationIndicatorProps", {
        get: function () {
            var _a = this, isLocationInvalid = _a.isLocationInvalid, isLocationUnique = _a.isLocationUnique;
            if (isLocationInvalid) {
                return { name: 'wrong_location', color: 'negative' };
            }
            if (!isLocationUnique) {
                return { name: 'place', color: 'grey' };
            }
            return { name: 'place', color: 'positive' };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TcAddress.prototype, "locationIndicatorHint", {
        get: function () {
            if (this.isLocationInvalid) {
                return this.labels.locationNotFound;
            }
            if (!this.isLocationUnique) {
                return this.labels.locationNotUnique;
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TcAddress.prototype, "isLocationUnique", {
        get: function () {
            return this.locationInfo.unique;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TcAddress.prototype, "isLocationInvalid", {
        get: function () {
            return !!this.locationInfo.invalid;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TcAddress.prototype, "cityLabel", {
        get: function () {
            return this.labels.city;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TcAddress.prototype, "houseNumberLabel", {
        get: function () {
            return this.labels.houseNumber;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TcAddress.prototype, "streetLabel", {
        get: function () {
            return this.streetEntity;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TcAddress.prototype, "zipCodeLabel", {
        get: function () {
            return this.labels.zipCode;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TcAddress.prototype, "showStreetSelection", {
        get: function () {
            var locationInfo = this.locationInfo;
            if (!this.zipCodeSynced && !this.citySynced) {
                this.streetOptions = [];
            }
            return ((this.showCity && locationInfo.city) || !this.showCity) && this.initStreetOpt.length > 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TcAddress.prototype, "showHouseNumberSelection", {
        get: function () {
            var houseNumberOptions = this.houseNumberOptions;
            return houseNumberOptions.length > 0 && this.showHouseNumberSelect;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TcAddress.prototype, "showHouseNumberInput", {
        get: function () {
            return !!this.zipCodeSynced && !!this.citySynced && !!this.streetSynced && !this.showHouseNumberSelection;
        },
        enumerable: false,
        configurable: true
    });
    TcAddress.prototype.onZipCodeChange = function () {
        this.cityOptions = [];
        this.streetOptions = [];
        this.houseNumberOptions = [];
        this.initStreetOpt = [];
    };
    TcAddress.prototype.onCityChange = function () {
        this.streetOptions = [];
        this.houseNumberOptions = [];
        this.initStreetOpt = [];
        var hasOneOption = this.cityOptions && this.cityOptions.length === 1;
        if (hasOneOption) {
            this.citySynced = this.cityOptions[0].value.trim();
        }
    };
    TcAddress.prototype.onCityOptionsChange = function (newCityOptions) {
        var _this = this;
        if (newCityOptions) {
            this.cityOptions = newCityOptions;
        }
        if (newCityOptions && newCityOptions.length === 1) {
            this.citySynced = this.cityOptions[0].value.trim();
        }
        if (newCityOptions && newCityOptions.length >= 2) {
            this.citySynced = '';
            setTimeout(function () {
                var _a;
                (_a = _this.citySelect) === null || _a === void 0 ? void 0 : _a.showPopup();
            }, 100);
        }
    };
    TcAddress.prototype.onStreetOptionsChange = function (newStreetOptions) {
        var _this = this;
        if (newStreetOptions) {
            this.streetOptions = newStreetOptions;
            this.initStreetOpt = newStreetOptions;
        }
        if (newStreetOptions && newStreetOptions.length >= 2) {
            this.streetSynced = '';
            if (this.showCity) {
                setTimeout(function () {
                    var _a;
                    (_a = _this.streetSelect) === null || _a === void 0 ? void 0 : _a.showPopup();
                }, 100);
            }
        }
    };
    TcAddress.prototype.onHouseNumberOptionsChange = function (newHouseNumberOptions) {
        var _this = this;
        if (newHouseNumberOptions) {
            this.houseNumberOptions = newHouseNumberOptions;
            this.initHouseNumberOpt = newHouseNumberOptions;
        }
        if (newHouseNumberOptions && newHouseNumberOptions.length >= 2) {
            this.houseNumberSynced = '';
            setTimeout(function () {
                var _a;
                (_a = _this.houseNumberSelect) === null || _a === void 0 ? void 0 : _a.showPopup();
            }, 100);
        }
    };
    TcAddress.prototype.handleClearLocation = function () {
        var _this = this;
        this.$emit('clear-location');
        setTimeout(function () {
            var _a, _b;
            (_a = _this.zipCodeInput) === null || _a === void 0 ? void 0 : _a.resetValidation();
            (_b = _this.zipCodeInput) === null || _b === void 0 ? void 0 : _b.focus();
        }, 100);
    };
    TcAddress.prototype.handleCityOptionsFiltering = function (val, update, abort) {
        var _this = this;
        update(function () {
            if (val) {
                _this.cityFilterKey = val;
            }
            else {
                _this.cityFilterKey = null;
            }
        });
        abort(function () {
            _this.cityFilterKey = null;
        });
    };
    TcAddress.prototype.handleChangeStreetValue = function (val) {
        var _this = this;
        this.streetOptions = this.getFilteredOptions(this.streetOptions, val);
        if (!val) {
            this.streetSynced = '';
            this.streetOptions = this.initStreetOpt;
            setTimeout(function () {
                var _a;
                (_a = _this.streetSelect) === null || _a === void 0 ? void 0 : _a.showPopup();
            }, 100);
        }
    };
    TcAddress.prototype.handleHouseNumberOptionsFiltering = function (val, update, abort) {
        var _this = this;
        if (!val) {
            this.houseNumberOptions = this.initHouseNumberOpt;
            this.houseNumberSynced = '';
            abort();
        }
        update(function () {
            _this.houseNumberOptions = _this.getFilteredOptions(_this.houseNumberOptions, val);
        });
    };
    __decorate([
        Ref('zip-code-input')
    ], TcAddress.prototype, "zipCodeInput", void 0);
    __decorate([
        Ref('city-select')
    ], TcAddress.prototype, "citySelect", void 0);
    __decorate([
        Ref('street-select')
    ], TcAddress.prototype, "streetSelect", void 0);
    __decorate([
        Ref('house-number-select')
    ], TcAddress.prototype, "houseNumberSelect", void 0);
    __decorate([
        Prop({ required: false, default: false })
    ], TcAddress.prototype, "onlyZipCode", void 0);
    __decorate([
        Prop({ required: false, default: true })
    ], TcAddress.prototype, "showZipCode", void 0);
    __decorate([
        Prop({ required: false, default: true })
    ], TcAddress.prototype, "showCity", void 0);
    __decorate([
        Prop({ required: false, default: true })
    ], TcAddress.prototype, "showStreet", void 0);
    __decorate([
        Prop({ required: false, default: true })
    ], TcAddress.prototype, "showHouseNumberSelect", void 0);
    __decorate([
        PropSync('zipCode', { required: true })
    ], TcAddress.prototype, "zipCodeSynced", void 0);
    __decorate([
        PropSync('city', { type: String, required: true })
    ], TcAddress.prototype, "citySynced", void 0);
    __decorate([
        PropSync('street', { type: String })
    ], TcAddress.prototype, "streetSynced", void 0);
    __decorate([
        PropSync('houseNumber', { type: String })
    ], TcAddress.prototype, "houseNumberSynced", void 0);
    __decorate([
        Prop({ required: true })
    ], TcAddress.prototype, "labels", void 0);
    __decorate([
        Prop({ required: true })
    ], TcAddress.prototype, "isLocationLoading", void 0);
    __decorate([
        Prop({ required: true })
    ], TcAddress.prototype, "locationInfo", void 0);
    __decorate([
        Prop({ required: true })
    ], TcAddress.prototype, "zipCodeError", void 0);
    __decorate([
        Watch('zipCode')
    ], TcAddress.prototype, "onZipCodeChange", null);
    __decorate([
        Watch('city')
    ], TcAddress.prototype, "onCityChange", null);
    __decorate([
        Watch('locationInfo.cityOptions')
    ], TcAddress.prototype, "onCityOptionsChange", null);
    __decorate([
        Watch('locationInfo.streetOptions')
    ], TcAddress.prototype, "onStreetOptionsChange", null);
    __decorate([
        Watch('locationInfo.houseNumberOptions')
    ], TcAddress.prototype, "onHouseNumberOptionsChange", null);
    TcAddress = __decorate([
        Component({
            components: { QField: QField, QIcon: QIcon, QInput: QInput, QSelect: QSelect, QSpinner: QSpinner, QTooltip: QTooltip, QItem: QItem, QItemSection: QItemSection }
        })
    ], TcAddress);
    return TcAddress;
}(Vue));
export default TcAddress;
