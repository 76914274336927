var fieldsDefinition = {
    remoteSignature: {
        label: 'Die Unterschrift soll kontaktlos erfolgen (per E-Mail)',
        type: 'boolean'
    },
    digitalSignature: {
        label: '',
        type: 'signature'
    },
    fn_remoteSignature: {
        label: '',
        type: 'boolean'
    },
    showDigitalSignature: {
        type: 'boolean',
        label: ''
    },
    headerSignature: { label: 'Unterschrift:', type: 'label' },
    agreeSepaLabel: {
        label: 'SEPA-Lastschrift-Mandat: Der Kontoinhaber ermächtigt den Versorger,\n' +
            'fällige Zahlungs- und Rechnungsbeträge mittels Lastschrift einzuziehen. Das kontoführende Kreditinstitut wird hiermit\n' +
            'vom Kontoinhaber angewiesen, die auf dieses Konto gezogenen Lastschriften einzulösen.\n' +
            'Hinweis: Innerhalb von 8 Wochen kann der Kontoinhaber, beginnend mit dem Belastungsdatum, die Erstattung des\n' +
            'belasteten Betrages verlangen. Es gelten dabei die Bedingungen, die der Kontoinhaber mit seinem Kreditinstitut vereinbart hat.',
        type: 'label'
    },
    offerMode: {
        label: '',
        type: 'boolean'
    },
    switchAccountLandHolder: {
        label: '',
        type: 'boolean'
    },
    deAccountHolder: {
        label: '',
        type: 'boolean',
        options: [
            { value: true, label: 'Deutsches Kreditinstitut' },
            { value: false, label: 'Ausländisches Kreditinstitut' }
        ]
    },
    telco: {
        label: '',
        type: 'boolean'
    },
    deviateAccountHolderData: {
        type: 'boolean',
        label: '',
        options: [
            { value: false, label: 'Kontoinhaber identisch mit Lieferanschrift' },
            { value: true, label: 'Abweichender Kontoinhaber' }
        ],
        validation: { required: [null, 'showAdditionalBankData'] }
    },
    firstName: { type: 'text', label: 'Vorname' },
    lastName: { type: 'text', label: 'Nachname' },
    firstNameDeviated: { type: 'text', label: 'Vorname*' },
    lastNameDeviated: { type: 'text', label: 'Nachname*' },
    iban: {
        type: 'text',
        label: 'IBAN',
        validation: { required: [null, 'showAdditionalBankData'] }
    },
    bic: {
        type: 'text',
        label: 'SWIFT-Code (BIC)',
        validation: {
            min: [8, "Bitte geben Sie einen 8-11-stelligen BIC- bzw. SWIFT-Code ein!"],
            max: [11, "Bitte geben Sie einen 8-11-stelligen BIC- bzw. SWIFT-Code ein!"],
            required: [null, 'isNotDeAccountHolder']
        }
    },
    creditInstitution: {
        type: 'text',
        label: 'Kreditinstitut',
        validation: { required: [null, 'isNotDeAccountHolder'] }
    },
    paymentMethod: {
        type: 'text',
        label: '',
        options: [
            { value: 'directDebit', label: 'Bankeinzug (SEPA-Lastschrift)' },
            { value: 'bankTransfer', label: 'Überweisung' }
        ],
        validation: { required: [null, 'showAdditionalBankData'] }
    },
    agreeSepa: {
        type: 'boolean',
        label: 'Zustimmung Lastschriftverfahren ',
        validation: {
            required: true,
            equalsTrue: [null, 'showAdditionalBankData']
        }
    }
};
var bankDataForm = {
    title: 'Zahlungsweise',
    fields: fieldsDefinition,
    initialValues: {
        showDigitalSignature: false,
        fn_remoteSignature: false,
        remoteSignature: false,
        telco: false,
        paymentMethod: 'directDebit',
        deviateAccountHolderData: false,
        offerMode: false,
        deAccountHolder: true,
        switchAccountLandHolder: false
    },
    conditions: {
        showAdditionalBankData: { fieldName: 'paymentMethod', type: 'equals', value: 'directDebit' },
        deviateAccountHolderData: { fieldName: 'deviateAccountHolderData', type: 'equals', value: true },
        isTelco: { fieldName: 'telco', type: 'equals', value: true },
        isTariff: { fieldName: 'telco', type: 'equals', value: false },
        showOfferButton: { fieldName: 'offerMode', type: 'equals', value: true },
        showSwitchAccountLandHolder: { fieldName: 'switchAccountLandHolder', type: 'equals', value: true },
        isDeAccountHolder: { fieldName: 'deAccountHolder', type: 'equals', value: true },
        isNotDeAccountHolder: { fieldName: 'deAccountHolder', type: 'equals', value: false },
        showDigitalSignature: { fieldName: 'showDigitalSignature', type: 'equals', value: true },
        isRemoteSignature: { fieldName: 'fn_remoteSignature', type: 'equals', value: true },
        isDigitalSignatureRequired: { fieldName: 'remoteSignature', type: 'equals', value: false },
    },
    structure: [
        {
            type: 'FormCard',
            props: { title: 'Zahlungsweise' },
            children: [
                {
                    type: 'FormRow',
                    props: { classes: 'tr-flex-wrap text-left' },
                    children: [
                        {
                            type: 'FormCol',
                            props: { xs: 6 },
                            children: [
                                {
                                    type: 'FormField',
                                    input: 'radio',
                                    name: 'paymentMethod'
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            type: 'FormConditionalElement',
            props: { conditionName: 'showAdditionalBankData' },
            children: [
                { type: 'FormDiv', props: { class: 'q-my-lg' }, children: [] },
                {
                    type: 'FormCard',
                    props: { title: 'Bankverbindungen' },
                    children: [
                        {
                            type: 'FormRow',
                            props: { justify: 'center', classes: 'tr-flex-wrap text-left' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [{ type: 'FormField', input: 'radio', name: 'deviateAccountHolderData' }]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'deviateAccountHolderData', revert: true, transition: false },
                                            children: [{ type: 'FormField', input: 'text', name: 'firstName', props: { disabled: true } }]
                                        },
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'deviateAccountHolderData', revert: false, transition: false },
                                            children: [{ type: 'FormField', input: 'text', name: 'firstNameDeviated' }]
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'deviateAccountHolderData', revert: true, transition: false },
                                            children: [{ type: 'FormField', input: 'text', name: 'lastName', props: { disabled: true } }]
                                        },
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'deviateAccountHolderData', revert: false, transition: false },
                                            children: [{ type: 'FormField', input: 'text', name: 'lastNameDeviated' }]
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'showSwitchAccountLandHolder', revert: false, transition: false },
                                            children: [{ type: 'FormField', input: 'radio', name: 'deAccountHolder' }]
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [{
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'isDeAccountHolder', revert: true, transition: false },
                                            children: [{ type: 'FormField',
                                                    input: 'text',
                                                    name: 'iban',
                                                }]
                                        }]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [{
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'isDeAccountHolder', revert: false, transition: false },
                                            children: [{
                                                    type: 'FormField',
                                                    input: 'text',
                                                    name: 'iban',
                                                    props: { mask: 'AA## #### #### #### #### ##' }
                                                }]
                                        }]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'isDeAccountHolder', revert: true, transition: false },
                                            children: [{ type: 'FormField', input: 'text', name: 'bic', props: { disabled: false } }]
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'isDeAccountHolder', revert: false, transition: false },
                                            children: [{ type: 'FormField', input: 'text', name: 'bic', props: { disabled: true } }]
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'isDeAccountHolder', revert: true, transition: false },
                                            children: [{ type: 'FormField', input: 'text', name: 'creditInstitution', props: { disabled: false } }]
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'isDeAccountHolder', revert: false, transition: false },
                                            children: [{ type: 'FormField', input: 'text', name: 'creditInstitution', props: { disabled: true } }]
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [{
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'showDigitalSignature', revert: true },
                                            children: [
                                                { type: 'FormField', input: 'checkbox', name: 'agreeSepa', props: { html: true } }
                                            ]
                                        }]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [{
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'showDigitalSignature', revert: true },
                                            children: [
                                                { type: 'FormField', input: 'label', name: 'agreeSepaLabel', props: { classes: 'h1', html: true } }
                                            ]
                                        }]
                                },
                            ]
                        }
                    ]
                }
            ]
        },
        {
            type: 'FormConditionalElement',
            props: { conditionName: 'showAdditionalBankData' },
            children: [
                { type: 'FormDiv', props: { class: 'q-ma-lg' }, children: [] },
                {
                    type: 'FormConditionalElement',
                    props: { conditionName: 'showDigitalSignature' },
                    children: [
                        {
                            type: 'FormCard',
                            props: {},
                            children: [
                                {
                                    type: 'FormField',
                                    input: 'label',
                                    name: 'headerSignature',
                                    props: { classes: 'text-h6' }
                                },
                                {
                                    type: 'FormRow',
                                    props: { classes: 'flex tr-flex-wrap full-width' },
                                    children: [
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12 },
                                            children: [
                                                {
                                                    type: 'FormConditionalElement',
                                                    props: { conditionName: 'isRemoteSignature' },
                                                    children: [
                                                        { type: 'FormField', input: 'checkbox', name: 'remoteSignature' }
                                                    ]
                                                },
                                            ]
                                        }
                                    ]
                                },
                                {
                                    type: 'FormConditionalElement',
                                    props: { conditionName: 'isDigitalSignatureRequired' },
                                    children: [
                                        {
                                            type: 'FormDiv',
                                            props: { class: 'q-py-md' },
                                            children: [{ type: 'FormField', input: 'signature', name: 'digitalSignature' }]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            type: 'FormDiv',
            props: { class: 'q-pt-md q-px-sm' },
            children: [{ type: 'FormText', props: { text: 'Pflichtfelder sind mit einem Stern * gekennzeichnet.', level: 'body2' } }]
        },
        {
            type: 'FormRow',
            props: { justify: 'between', classes: 'q-py-md' },
            children: [
                {
                    type: 'FormCol',
                    props: { sm: 4, lg: 3 },
                    children: [{ type: 'FormBackButton', props: { title: 'zurück', classes: 'full-width' } }]
                },
                {
                    type: 'FormCol',
                    props: { sm: 4, lg: 3 },
                    children: [
                        {
                            type: 'FormConditionalElement',
                            props: { conditionName: 'showOfferButton', revert: false, transition: false },
                            children: [
                                {
                                    type: 'FormClickButton',
                                    props: { title: 'Angebot erstellen' }
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'FormCol',
                    props: { sm: 4, lg: 3 },
                    children: [{ type: 'FormSubmitButton', props: { title: 'Weiter', classes: 'full-width' } }]
                }
            ]
        }
    ]
};
export default bankDataForm;
