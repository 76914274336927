import { __awaiter, __generator } from "tslib";
import Axios from 'axios';
import { createClient } from './serviceProxy';
var ENDPOINT_BANK_VALIDATION = '/ws/bankdata';
export default (function (_a) {
    var apikey = _a.apikey;
    var client = createClient({ apikey: apikey });
    var checkIBANValidation = function (iban) { return __awaiter(void 0, void 0, Promise, function () {
        var response, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, client.get(ENDPOINT_BANK_VALIDATION + "/checkIBAN", {
                            params: {
                                iban: iban
                            }
                        })];
                case 1:
                    response = _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    if (Axios.isCancel(e_1))
                        return [2 /*return*/, null];
                    return [2 /*return*/, e_1.message];
                case 3: return [2 /*return*/, response ? response.data : null];
            }
        });
    }); };
    var checkBankCode = function (_a) {
        var bankleitzahl = _a.bankleitzahl, kontonummer = _a.kontonummer;
        return __awaiter(void 0, void 0, Promise, function () {
            var response, cancel, e_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, client.get(ENDPOINT_BANK_VALIDATION + "/checkBlzKonto", {
                                params: {
                                    bankleitzahl: bankleitzahl,
                                    kontonummer: kontonummer
                                },
                                cancelToken: new Axios.CancelToken(function (c) {
                                    cancel = c;
                                })
                            })];
                    case 1:
                        response = _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _b.sent();
                        if (Axios.isCancel(e_2))
                            return [2 /*return*/, null];
                        return [2 /*return*/, function () { return cancel(); }];
                    case 3: return [2 /*return*/, response ? response.data : null];
                }
            });
        });
    };
    return { checkIBANValidation: checkIBANValidation, checkBankCode: checkBankCode };
});
