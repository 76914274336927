import { __awaiter, __generator } from "tslib";
import { createClient, PROXY_BASE_URL } from './serviceProxy';
var ENDPOINT_PATH = '/crm/validate';
// eslint-disable-next-line import/prefer-default-export
export var sendDataToValidate = function (data, apiKey, schluessel) { return __awaiter(void 0, void 0, void 0, function () {
    var body, client, formData, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                body = {
                    abschlussDaten: [data]
                };
                client = createClient({ url: PROXY_BASE_URL, apikey: apiKey });
                formData = {
                    portalDaten: body.abschlussDaten[0].portalDaten,
                    tarifParameter: body.abschlussDaten[0].tarifParameter,
                    tarifErgebnis: body.abschlussDaten[0].tarifErgebnis,
                    schluessel: schluessel
                };
                return [4 /*yield*/, client.post(ENDPOINT_PATH.concat('?calculatorType=')
                        .concat("" + data.portalDaten.tariffCalculatorTypeId)
                        .concat('&supplierId=')
                        .concat("" + data.portalDaten.vertriebspartner_id), formData)];
            case 1:
                response = _a.sent();
                /*  if (!response.data.data.ok) {
                      const errors = response.data.data.fehler.reduce((acc, nextError) => {
                          const fieldName = nextError.schluessel;
                          const errorMessage = nextError.meldung;
              
                          return {
                              ...acc,
                              [fieldName]: errorMessage
                          };
                      }, {});
              
                      // TODO: handle displaying server errors
                     // throw new ServerValidationError(errors);
                  }
              */
                return [2 /*return*/, response];
        }
    });
}); };
