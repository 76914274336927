import { __decorate, __extends } from "tslib";
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { QBtn } from 'quasar';
import store from '../../../store';
var FormClickButtonSecondary = /** @class */ (function (_super) {
    __extends(FormClickButtonSecondary, _super);
    function FormClickButtonSecondary() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(FormClickButtonSecondary.prototype, "isContinueAsOffer", {
        get: function () {
            return store.states.isContinueAsOffer;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FormClickButtonSecondary.prototype, "isTabPanelSummary", {
        get: function () {
            return store.states.tabPanelName === 'summary';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FormClickButtonSecondary.prototype, "disable", {
        get: function () {
            if (this.isTabPanelSummary) {
                return false;
            }
            return !this.isContinueAsOffer;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FormClickButtonSecondary.prototype, "title", {
        get: function () {
            return this.props.title;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Inject()
    ], FormClickButtonSecondary.prototype, "clickSecondaryForm", void 0);
    __decorate([
        Prop({ required: true })
    ], FormClickButtonSecondary.prototype, "formMetadata", void 0);
    __decorate([
        Prop({ required: true })
    ], FormClickButtonSecondary.prototype, "props", void 0);
    FormClickButtonSecondary = __decorate([
        Component({ components: { QBtn: QBtn } })
    ], FormClickButtonSecondary);
    return FormClickButtonSecondary;
}(Vue));
export default FormClickButtonSecondary;
