// RequiredRule
export var REQUIRED_RULE_NAME = 'required';
// MinRule
export var MIN_RULE_NAME = 'min';
// MaxRule
export var MAX_RULE_NAME = 'max';
// RegexRule
export var REGEX_RULE_NAME = 'regex';
// EqualsTrueRule
export var EQUALS_TRUE_RULE_NAME = 'equalsTrue';
