import { __read } from "tslib";
import dayjs from 'dayjs';
export var resolveDateOperations = function (dateOperations) {
    var today = dayjs();
    var finalDate = dateOperations.reduce(function (acc, nextOperation) {
        if (nextOperation.type === 'round') {
            var roundTo = nextOperation.roundTo, unit = nextOperation.unit;
            switch (roundTo) {
                case 'start':
                    return acc.startOf(unit);
                case 'end':
                    return acc.endOf(unit);
                default:
                    return acc;
            }
        }
        if (nextOperation.type === 'shift') {
            var amount = nextOperation.amount, unit = nextOperation.unit;
            return acc.add(amount, unit);
        }
        return acc;
    }, today);
    return finalDate.format('YYYY-MM-DD');
};
export var resolveMinMaxDateRule = function (rule) {
    if (typeof rule === 'string') {
        return rule;
    }
    if (rule instanceof Array) {
        var _a = __read(rule, 1), min = _a[0];
        if (typeof min === 'string') {
            return min;
        }
        return resolveDateOperations(min.operations);
    }
    if (typeof rule === 'object') {
        return resolveDateOperations(rule.operations);
    }
    return null;
};
