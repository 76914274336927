import { __decorate, __extends } from "tslib";
import { Component, Emit, Model, Prop, Ref, Vue } from 'vue-property-decorator';
import { QBtn, QBtnGroup, QIcon, QInput, QItem, QItemLabel, QItemSection, QTooltip } from 'quasar';
import Tooltip from '../../components/tooltip/Tooltip.vue';
var EVENT_NAME = 'usage-change';
var TcUsage = /** @class */ (function (_super) {
    __extends(TcUsage, _super);
    function TcUsage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TcUsage.prototype.showTooltip = function () {
        var _a;
        (_a = this.tooltipRef.parentElement) === null || _a === void 0 ? void 0 : _a.classList.remove('ellipsis');
    };
    TcUsage.prototype.updateValue = function (newUsage) {
        return newUsage.value;
    };
    TcUsage.prototype.handleInput = function (newUsage) {
        this.inputUsage.focus();
        return parseInt(newUsage, 10);
    };
    __decorate([
        Prop()
    ], TcUsage.prototype, "usageOptions", void 0);
    __decorate([
        Prop({ default: true })
    ], TcUsage.prototype, "showUsageOptions", void 0);
    __decorate([
        Prop()
    ], TcUsage.prototype, "usageAlternativeLabel", void 0);
    __decorate([
        Prop()
    ], TcUsage.prototype, "usageInvalidError", void 0);
    __decorate([
        Prop()
    ], TcUsage.prototype, "labelTarif", void 0);
    __decorate([
        Prop()
    ], TcUsage.prototype, "nameHtml", void 0);
    __decorate([
        Prop({ default: false })
    ], TcUsage.prototype, "showCountPerson", void 0);
    __decorate([
        Model(EVENT_NAME)
    ], TcUsage.prototype, "usage", void 0);
    __decorate([
        Prop({ required: true })
    ], TcUsage.prototype, "labels", void 0);
    __decorate([
        Ref('inputUsage')
    ], TcUsage.prototype, "inputUsage", void 0);
    __decorate([
        Ref('tooltip')
    ], TcUsage.prototype, "tooltipRef", void 0);
    __decorate([
        Emit(EVENT_NAME)
    ], TcUsage.prototype, "updateValue", null);
    __decorate([
        Emit(EVENT_NAME)
    ], TcUsage.prototype, "handleInput", null);
    TcUsage = __decorate([
        Component({
            components: { QIcon: QIcon, QItem: QItem, QItemLabel: QItemLabel, QItemSection: QItemSection, QInput: QInput, QBtn: QBtn, QBtnGroup: QBtnGroup, QTooltip: QTooltip, Tooltip: Tooltip }
        })
    ], TcUsage);
    return TcUsage;
}(Vue));
export default TcUsage;
