import { __assign, __awaiter, __generator } from "tslib";
import { ServerValidationError } from '../widgets/UniversalForm';
import { createClient, PROXY_BASE_URL } from './serviceProxy';
var ENDPOINT_PATH = '/crm/sendData';
// eslint-disable-next-line import/prefer-default-export
export var sendData = function (data, apiKey) { return __awaiter(void 0, void 0, void 0, function () {
    var body, client, formData, response, errors, errors;
    var _a, _b, _c, _d, _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                body = {
                    abschlussDaten: [data]
                };
                client = createClient({ url: PROXY_BASE_URL, apikey: apiKey, authToken: data.portalDaten.authToken });
                formData = {
                    jsonDaten: body
                };
                return [4 /*yield*/, client.post(ENDPOINT_PATH.concat('?calculatorType=').concat("" + data.portalDaten.tariffCalculatorTypeId), formData)];
            case 1:
                response = _f.sent();
                if ((_c = (_b = (_a = response.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.crm) === null || _c === void 0 ? void 0 : _c.response.abschluesse_FEHLER) {
                    errors = response.data.data.crm.response.abschluesse_FEHLER[0].fehler.reduce(function (acc, nextError) {
                        var _a;
                        var fieldName = nextError.schluessel;
                        var errorMessage = nextError.meldung;
                        return __assign(__assign({}, acc), (_a = {}, _a[fieldName] = errorMessage, _a));
                    }, {});
                    // TODO: handle displaying server errors
                    throw new ServerValidationError(errors);
                }
                else if (response.data.additionalData.verbindlicher_abschluss && ((_e = (_d = response.data.data) === null || _d === void 0 ? void 0 : _d.cim) === null || _e === void 0 ? void 0 : _e.abschluss_FEHLER)) {
                    errors = response.data.data.cim.abschluss_FEHLER[0].fehler.reduce(function (acc, nextError) {
                        var _a;
                        var fieldName = nextError.schluessel;
                        var errorMessage = nextError.meldung;
                        return __assign(__assign({}, acc), (_a = {}, _a[fieldName] = errorMessage, _a));
                    }, {});
                    // TODO: handle displaying server errors
                    throw new ServerValidationError(errors);
                }
                return [2 /*return*/, response];
        }
    });
}); };
