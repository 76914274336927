var fieldsDefinition = {
    labelSecondDelivery: {
        label: 'Lieferanschrift',
        type: 'label'
    },
    offerMode: {
        label: '',
        type: 'boolean'
    },
    houseNumberInput: {
        label: '',
        type: 'boolean'
    },
    streetInput: {
        label: '',
        type: 'boolean'
    },
    additionInput: {
        label: '',
        type: 'boolean'
    },
    zipCode: {
        label: 'PLZ',
        type: 'text',
        validation: {
            required: true,
            regex: ['^\\d{5}$', 'Ungültige Eingabe. Die PLZ muss 5-stellig sein']
        }
    },
    city: {
        label: 'Ort',
        type: 'text',
        validation: {
            required: true
        },
        options: []
    },
    street: {
        label: 'Straße',
        type: 'text',
        validation: {
            required: [null, 'isStreetInput']
        },
        options: []
    },
    houseNumber: {
        label: 'Hausnummer',
        type: 'text',
        validation: {
            required: [null, 'isHouseNumberInput']
        }
    },
    installationLocation: {
        label: 'Wohnungs-/Briefkastennummer, Installationsort-Stockwerk',
        type: 'text'
    },
    addition: {
        label: 'Adresszusatz',
        type: 'text'
    },
    billingSameAsDelivery: {
        label: 'Rechnungsanschrift identisch mit Lieferanschrift',
        type: 'boolean'
    },
    secondDeliverySameAsDelivery: {
        label: 'Lieferadresse identisch mit Lieferanschrift',
        type: 'boolean'
    },
    telco: {
        label: '',
        type: 'boolean'
    },
    // Billing Definitions
    // TODO: handle optional validation for Billing part
    zipCodeBilling: {
        label: 'PLZ',
        type: 'text',
        validation: {
            required: [null, 'deviateBillingAddress'],
            regex: ['^\\d{5}$', 'Ungültige Eingabe. Die PLZ muss 5-stellig sein', 'deviateBillingAddress']
        }
    },
    cityBilling: {
        label: 'Ort',
        type: 'text',
        validation: {
            required: [null, 'deviateBillingAddress']
        },
        options: []
    },
    streetBilling: {
        label: 'Straße',
        type: 'text',
        validation: {
            required: [null, 'deviateBillingAddress']
        },
        options: []
    },
    houseNumberBilling: {
        label: 'Hausnummer',
        type: 'text',
        validation: {
            required: [null, 'deviateBillingAddress']
        }
    },
    additionBilling: {
        label: 'Adresszusatz',
        type: 'text'
    },
    mailboxBilling: {
        label: 'Postfach',
        type: 'text',
        validation: {
            regex: ['^\\d{5}$', 'Ungültige Eingabe. Das Postfach muss 5-stellig sein', 'deviateBillingAddress']
        }
    },
    zipCodeSecondDelivery: {
        label: 'PLZ',
        type: 'text',
        validation: {
            required: [null, 'deviateSecondDeliveryAddress'],
            regex: ['^\\d{5}$', 'Ungültige Eingabe. Die PLZ muss 5-stellig sein', 'deviateSecondDeliveryAddress']
        }
    },
    citySecondDelivery: {
        label: 'Ort',
        type: 'text',
        validation: {
            required: [null, 'deviateSecondDeliveryAddress']
        },
        options: []
    },
    streetSecondDelivery: {
        label: 'Straße',
        type: 'text',
        validation: {
            required: [null, 'deviateSecondDeliveryAddress']
        },
        options: []
    },
    houseNumberSecondDelivery: {
        label: 'Hausnummer',
        type: 'text',
        validation: {
            required: [null, 'deviateSecondDeliveryAddress']
        }
    },
    additionSecondDelivery: {
        label: 'Adresszusatz',
        type: 'text'
    },
    mailboxSecondDelivery: {
        label: 'Postfach',
        type: 'text',
        validation: {
            regex: ['^\\d{5}$', 'Ungültige Eingabe. Das Postfach muss 5-stellig sein', 'deviateSecondDeliveryAddress']
        }
    },
    salutation: {
        type: 'text',
        label: 'Anrede',
        options: [
            { label: 'Herr', value: 'Herr' },
            { label: 'Frau', value: 'Frau' },
            { label: 'Divers', value: 'Divers' },
            { label: 'Firma', value: 'Firma' },
            { label: 'Eheleute', value: 'Eheleute' },
            { label: 'Familie', value: 'Familie' }
        ],
        validation: {
            required: [null, 'deviateBillingAddress']
        }
    },
    title: {
        type: 'text',
        label: 'Titel',
        options: [
            { label: 'Dr.', value: 'Dr.' },
            { label: 'Prof.', value: 'Prof.' },
            { label: 'Prof. Dr.', value: 'Prof. Dr.' }
        ]
    },
    firstName: {
        type: 'text',
        label: 'Vorname',
        validation: {
            required: ['Bitte geben Sie einen Vorname ein.', 'deviateBillingAddress']
        }
    },
    lastName: {
        type: 'text',
        label: 'Nachname',
        validation: {
            required: ['Bitte geben Sie einen Nachname ein.', 'deviateBillingAddress']
        }
    },
    additionalPerson: {
        type: 'text',
        label: 'zusätzliche Person'
    },
    companyName: {
        label: 'Firma',
        type: 'text'
    },
    salutationSecondDelivery: {
        type: 'text',
        label: 'Anrede',
        options: [
            { label: 'Herr', value: 'Herr' },
            { label: 'Frau', value: 'Frau' },
            { label: 'Divers', value: 'Divers' },
            { label: 'Firma', value: 'Firma' },
            { label: 'Eheleute', value: 'Eheleute' },
            { label: 'Familie', value: 'Familie' }
        ],
        validation: {
            required: [null, 'deviateSecondDeliveryAddress']
        }
    },
    titleSecondDelivery: {
        type: 'text',
        label: 'Titel',
        options: [
            { label: 'Dr.', value: 'Dr.' },
            { label: 'Prof.', value: 'Prof.' },
            { label: 'Prof. Dr.', value: 'Prof. Dr.' }
        ]
    },
    firstNameSecondDelivery: {
        type: 'text',
        label: 'Vorname',
        validation: {
            required: ['Bitte geben Sie einen Vorname ein.', 'deviateSecondDeliveryAddress']
        }
    },
    lastNameSecondDelivery: {
        type: 'text',
        label: 'Nachname',
        validation: {
            required: ['Bitte geben Sie einen Nachname ein.', 'deviateSecondDeliveryAddress']
        }
    },
    companyNameSecondDelivery: {
        label: 'Firma',
        type: 'text'
    },
    adressLabel: {
        label: 'Adresse',
        type: 'label'
    },
    installLocationLabel: {
        label: 'Installastionsanschrift',
        type: 'label'
    },
    invoiceLocationLabel: {
        label: 'Rechnungsanschrift',
        type: 'label'
    }
};
var addressDataForm = {
    title: 'Address Data Form',
    fields: fieldsDefinition,
    initialValues: {
        billingSameAsDelivery: true,
        secondDeliverySameAsDelivery: true,
        telco: false,
        streetInput: false,
        additionInput: false,
        houseNumberInput: false,
        offerMode: false
    },
    conditions: {
        deviateBillingAddress: { fieldName: 'billingSameAsDelivery', type: 'equals', value: false },
        deviateSecondDeliveryAddress: { fieldName: 'secondDeliverySameAsDelivery', type: 'equals', value: false },
        isTelco: { fieldName: 'telco', type: 'equals', value: true },
        isTariff: { fieldName: 'telco', type: 'equals', value: false },
        isCompany: { fieldName: 'salutation', type: 'equals', value: 'Firma' },
        isCompanySecondDelivery: { fieldName: 'salutationSecondDelivery', type: 'equals', value: 'Firma' },
        isStreetSelected: { fieldName: 'street', type: 'equals', value: '' },
        isHouseNumberSelected: { fieldName: 'houseNumber', type: 'equals', value: '' },
        isStreetInput: { fieldName: 'streetInput', type: 'equals', value: false },
        isHouseNumberInput: { fieldName: 'houseNumberInput', type: 'equals', value: false },
        isAdditionInput: { fieldName: 'additionInput', type: 'equals', value: false },
        showOfferButton: { fieldName: 'offerMode', type: 'equals', value: true }
    },
    structure: [
        {
            type: 'FormConditionalElement',
            props: { conditionName: 'isTariff' },
            children: [
                {
                    type: 'FormCard',
                    props: { title: 'Lieferanschrift' },
                    children: [
                        {
                            type: 'FormRow',
                            props: { justify: 'around' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, sm: 6, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'zipCode',
                                            input: 'select',
                                            props: { readonly: true }
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, sm: 6, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'city',
                                            input: 'select',
                                            props: {
                                                filterable: true,
                                                'fill-input': false,
                                                'hide-selected': false,
                                                popupOnOptionsChange: true,
                                                readonly: true
                                            }
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, sm: 4, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'street',
                                            input: 'select',
                                            condProps: [['isStreetInput', { readonly: true }, true]],
                                            props: {
                                                filterable: true,
                                                'fill-input': false,
                                                'hide-selected': false,
                                                popupOnOptionsChange: true
                                            }
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, sm: 4, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'houseNumber',
                                            input: 'text',
                                            condProps: [['isHouseNumberInput', { readonly: true }, true]]
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, sm: 4, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'addition',
                                            input: 'text'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            type: 'FormConditionalElement',
            props: { conditionName: 'isTelco' },
            children: [
                {
                    type: 'FormCard',
                    props: { title: 'Installationsanschrift' },
                    children: [
                        // {
                        //   type: 'FormField',
                        //   input: 'label',
                        //   name: 'installLocationLabel',
                        //   props: { classes: 'text-h6' }
                        // },
                        {
                            type: 'FormRow',
                            props: { justify: 'around' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, sm: 6, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'zipCode',
                                            input: 'text',
                                            props: { readonly: true }
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, sm: 6, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'city',
                                            input: 'select',
                                            props: {
                                                filterable: true,
                                                'fill-input': false,
                                                'hide-selected': false,
                                                popupOnOptionsChange: true,
                                                readonly: true
                                            }
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, sm: 4, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'street',
                                            input: 'select',
                                            condProps: [['isStreetInput', { readonly: true }, true]],
                                            props: {
                                                filterable: true,
                                                'fill-input': false,
                                                'hide-selected': false,
                                                popupOnOptionsChange: true
                                            }
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, sm: 4, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'houseNumber',
                                            input: 'text',
                                            condProps: [['isHouseNumberInput', { readonly: true }, true]]
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, sm: 4, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'addition',
                                            input: 'text',
                                            condProps: [['isAdditionInput', { readonly: true }, true]]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            type: 'FormConditionalElement',
                            props: { conditionName: 'isTelco' },
                            children: [
                                {
                                    type: 'FormRow',
                                    props: { justify: 'around' },
                                    children: [
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12, gutter: ['md', 'x'] },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    name: 'installationLocation',
                                                    input: 'text'
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            type: 'FormDiv',
            props: { class: 'q-mx-sm q-my-md' },
            children: [
                {
                    type: 'FormRow',
                    props: {},
                    children: [
                        {
                            type: 'FormCol',
                            props: { sm: 8, md: 6 },
                            children: [{ type: 'FormField', name: 'billingSameAsDelivery', input: 'checkbox' }]
                        }
                    ]
                }
            ]
        },
        // Billing ContactAddress/AddressData part
        {
            type: 'FormConditionalElement',
            props: { conditionName: 'deviateBillingAddress' },
            children: [
                // Billing ContactData
                {
                    type: 'FormCard',
                    props: { title: 'Rechnungsadresse' },
                    children: [
                        // {
                        //   type: 'FormField',
                        //   input: 'label',
                        //   name: 'invoiceLocationLabel',
                        //   props: { classes: 'text-h6' }
                        // },
                        {
                            type: 'FormRow',
                            props: { justify: 'around' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, sm: 6, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'salutation',
                                            input: 'select'
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, sm: 6, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'title',
                                            input: 'select',
                                            props: { clearable: true }
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, sm: 4, gutter: ['md', 'x'] },
                                    children: [{ type: 'FormField', input: 'text', name: 'firstName' }]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, sm: 4, gutter: ['md', 'x'] },
                                    children: [{ type: 'FormField', input: 'text', name: 'lastName' }]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, sm: 4, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'companyName',
                                            input: 'text'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                { type: 'FormDiv', props: { class: 'q-ma-lg' }, children: [] },
                // Billing AddressData
                {
                    type: 'FormCard',
                    props: { title: 'Adresse' },
                    children: [
                        // {
                        //   type: 'FormField',
                        //   input: 'label',
                        //   name: 'adressLabel',
                        //   props: { classes: 'text-h6' }
                        // },
                        {
                            type: 'FormRow',
                            props: { justify: 'around' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, sm: 6, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'zipCodeBilling',
                                            input: 'text'
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, sm: 6, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'cityBilling',
                                            input: 'select',
                                            props: {
                                                filterable: true,
                                                'fill-input': false,
                                                'hide-selected': false,
                                                popupOnOptionsChange: true
                                            }
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, sm: 4, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'streetBilling',
                                            input: 'select',
                                            props: {
                                                filterable: true,
                                                'fill-input': false,
                                                'hide-selected': false,
                                                popupOnOptionsChange: true
                                            }
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, sm: 4, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'houseNumberBilling',
                                            input: 'text'
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, sm: 4, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'additionBilling',
                                            input: 'text'
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, gutter: ['md', 'x'] },
                                    children: [
                                        {
                                            type: 'FormField',
                                            name: 'mailboxBilling',
                                            input: 'text'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        // SecondDelivery
        {
            type: 'FormConditionalElement',
            props: { conditionName: 'isTelco' },
            children: [
                {
                    type: 'FormDiv',
                    props: { class: 'q-mx-sm q-my-md' },
                    children: [
                        {
                            type: 'FormRow',
                            props: {},
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { sm: 8, md: 6 },
                                    children: [{ type: 'FormField', name: 'secondDeliverySameAsDelivery', input: 'checkbox' }]
                                }
                            ]
                        }
                    ]
                },
                // Billing ContactAddress/AddressData part
                {
                    type: 'FormConditionalElement',
                    props: { conditionName: 'deviateSecondDeliveryAddress' },
                    children: [
                        // Billing ContactData
                        {
                            type: 'FormCard',
                            props: { title: '' },
                            children: [
                                {
                                    type: 'FormField',
                                    input: 'label',
                                    name: 'labelSecondDelivery',
                                    props: { classes: 'text-h6' }
                                },
                                {
                                    type: 'FormRow',
                                    props: { justify: 'around' },
                                    children: [
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12, sm: 6, gutter: ['md', 'x'] },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    name: 'salutationSecondDelivery',
                                                    input: 'select'
                                                }
                                            ]
                                        },
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12, sm: 6, gutter: ['md', 'x'] },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    name: 'titleSecondDelivery',
                                                    input: 'select',
                                                    props: { clearable: true }
                                                }
                                            ]
                                        },
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12, sm: 4, gutter: ['md', 'x'] },
                                            children: [{ type: 'FormField', input: 'text', name: 'firstNameSecondDelivery' }]
                                        },
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12, sm: 4, gutter: ['md', 'x'] },
                                            children: [{ type: 'FormField', input: 'text', name: 'lastNameSecondDelivery' }]
                                        },
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12, sm: 4, gutter: ['md', 'x'] },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    name: 'companyNameSecondDelivery',
                                                    input: 'text'
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        { type: 'FormDiv', props: { class: 'q-ma-lg' }, children: [] },
                        // SecondDelivery AddressData
                        {
                            type: 'FormCard',
                            props: { title: '' },
                            children: [
                                {
                                    type: 'FormField',
                                    input: 'label',
                                    name: 'adressLabel',
                                    props: { classes: 'text-h6 ' }
                                },
                                {
                                    type: 'FormRow',
                                    props: { justify: 'around' },
                                    children: [
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12, sm: 6, gutter: ['md', 'x'] },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    name: 'zipCodeSecondDelivery',
                                                    input: 'text'
                                                }
                                            ]
                                        },
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12, sm: 6, gutter: ['md', 'x'] },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    name: 'citySecondDelivery',
                                                    input: 'select',
                                                    props: {
                                                        filterable: true,
                                                        'fill-input': false,
                                                        'hide-selected': false,
                                                        popupOnOptionsChange: true
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12, sm: 4, gutter: ['md', 'x'] },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    name: 'streetSecondDelivery',
                                                    input: 'select',
                                                    props: {
                                                        filterable: true,
                                                        'fill-input': false,
                                                        'hide-selected': false,
                                                        popupOnOptionsChange: true
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12, sm: 4, gutter: ['md', 'x'] },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    name: 'houseNumberSecondDelivery',
                                                    input: 'text'
                                                }
                                            ]
                                        },
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12, sm: 4, gutter: ['md', 'x'] },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    name: 'additionSecondDelivery',
                                                    input: 'text'
                                                }
                                            ]
                                        },
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12, gutter: ['md', 'x'] },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    name: 'mailboxSecondDelivery',
                                                    input: 'text'
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            type: 'FormDiv',
            props: { class: 'q-mx-md q-my-sm' },
            children: [{ type: 'FormText', props: { text: 'Pflichtfelder sind mit einem Stern * gekennzeichnet.', level: 'body2' } }]
        },
        {
            type: 'FormRow',
            props: { justify: 'between' },
            children: [
                {
                    type: 'FormCol',
                    props: { sm: 4, lg: 3 },
                    children: [
                        {
                            type: 'FormBackButton',
                            props: { title: 'zurück', classes: 'full-width' }
                        }
                    ]
                },
                {
                    type: 'FormCol',
                    props: { sm: 4, lg: 3 },
                    children: [
                        {
                            type: 'FormConditionalElement',
                            props: { conditionName: 'showOfferButton', revert: false, transition: false },
                            children: [
                                {
                                    type: 'FormClickButton',
                                    props: { title: 'Angebot erstellen' }
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'FormCol',
                    props: { sm: 4, lg: 3 },
                    children: [
                        {
                            type: 'FormSubmitButton',
                            props: { title: 'Weiter', classes: 'full-width' }
                        }
                    ]
                }
            ]
        },
        { type: 'FormLoader', props: { icon: 'ball' } }
    ]
};
export default addressDataForm;
