import { __assign, __awaiter, __generator } from "tslib";
import axios from 'axios';
/**
 * Prod -> https://prod.tr4-proxy.getag-cloud.com/api/v2
 * Test -> https://test.tr4-proxy.getag-cloud.com/api/v2
 * Stage-> https://stage.tr4-proxy.getag-cloud.com/api/v2
 * local-> http://localhost:4999/api/v2
 */
export var PROXY_BASE_URL = 'https://prod.tr4-proxy.getag-cloud.com/api/v2';
export var createClient = function (_a) {
    var url = _a.url, apikey = _a.apikey, authToken = _a.authToken;
    return axios.create({
        method: 'GET',
        baseURL: url !== null && url !== void 0 ? url : PROXY_BASE_URL,
        headers: __assign({ 'X-API-KEY': apikey }, (authToken
            ? {
                Authorization: authToken
            }
            : {}))
    });
};
export var createAPIKey = function () { return __awaiter(void 0, void 0, void 0, function () {
    var hostname, isLocalhost, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                hostname = window.location.hostname;
                isLocalhost = hostname.includes('localhost');
                return [4 /*yield*/, axios.get('/adserv', {
                        baseURL: PROXY_BASE_URL,
                        headers: __assign({}, (isLocalhost
                            ? {
                                'x-origin': 'localhost-karsten' // localhost-karsten
                            }
                            : {}))
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response === null || response === void 0 ? void 0 : response.data.data.apikey];
        }
    });
}); };
