import { render, staticRenderFns } from "./TariffSelectedBox.vue?vue&type=template&id=baabd4ec&scoped=true&class=col-12%20col-sm-4%20q-pa-sm%20sticky&style=margin-bottom%3A%2016px%3B%20position%3A%20sticky%3B%20top%3A%200px%3B%20height%3A%20100%25&"
import script from "./TariffSelectedBox.vue?vue&type=script&lang=ts&"
export * from "./TariffSelectedBox.vue?vue&type=script&lang=ts&"
import style0 from "./TariffSelectedBox.vue?vue&type=style&index=0&id=baabd4ec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "baabd4ec",
  null
  
)

export default component.exports