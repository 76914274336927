import { __decorate, __extends, __read, __spread } from "tslib";
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { QBtn } from 'quasar';
import store from '../../../store';
import handleValidation from '../../../utils/handleValidation';
var FormSubmitButton = /** @class */ (function (_super) {
    __extends(FormSubmitButton, _super);
    function FormSubmitButton() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(FormSubmitButton.prototype, "classes", {
        get: function () {
            if (this.props.classes) {
                if (this.props.classes.includes('tr-mobile')) {
                    var arrClasses = this.props.classes.split(' ');
                    var addNewClass = __spread(arrClasses, ['full-width']);
                    return addNewClass.join(' ');
                }
                return this.props.classes;
            }
            return '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FormSubmitButton.prototype, "disabledOptInsButton", {
        get: function () {
            var _a = store.states.forms, preSupplierData = _a.preSupplierData, addressData = _a.addressData, contactData = _a.contactData, bankData = _a.bankData;
            var isContactDataValid = handleValidation(contactData, 'contactData');
            var isAddressDataValid = handleValidation(addressData, 'addressData');
            var isPreSupplierDataValid = handleValidation(preSupplierData, 'preSupplierData');
            var isBankDataValid = handleValidation(bankData, 'bankData');
            var formMetadata = this.formMetadata;
            return formMetadata.isValid && isContactDataValid && isAddressDataValid && isPreSupplierDataValid && isBankDataValid;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FormSubmitButton.prototype, "isTabPanelNameSummaryInOfferMode", {
        get: function () {
            return this.isContinueAsOffer && store.states.tabPanelName === 'summary';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FormSubmitButton.prototype, "isContinueAsOffer", {
        get: function () {
            return store.states.isContinueAsOffer;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FormSubmitButton.prototype, "disable", {
        get: function () {
            return this.isContinueAsOffer ? false : !this.formMetadata.isValid || this.formMetadata.isSubmitting;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FormSubmitButton.prototype, "title", {
        get: function () {
            return this.props.title;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Inject()
    ], FormSubmitButton.prototype, "submitForm", void 0);
    __decorate([
        Prop({ required: true })
    ], FormSubmitButton.prototype, "formMetadata", void 0);
    __decorate([
        Prop({ required: true })
    ], FormSubmitButton.prototype, "props", void 0);
    FormSubmitButton = __decorate([
        Component({ components: { QBtn: QBtn } })
    ], FormSubmitButton);
    return FormSubmitButton;
}(Vue));
export default FormSubmitButton;
