import { __awaiter, __generator, __read } from "tslib";
import { createClient } from './serviceProxy';
var BASE_ENDPOINT_PATH = '/ws/locationinfo';
var useLocationInfo = function (_a) {
    var apikey = _a.apikey;
    var client = createClient({ apikey: apikey });
    var getStreetByZipCodeAndCity = function (_a) {
        var plz = _a.plz, ort = _a.ort;
        return __awaiter(void 0, void 0, Promise, function () {
            var response, _b, city, data, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        _b = __read(ort.split(' | '), 1), city = _b[0];
                        data = {
                            plz: plz,
                            ort: city
                        };
                        return [4 /*yield*/, client.post(BASE_ENDPOINT_PATH + "/getStrassenByPlzOrt", data)];
                    case 1:
                        response = _c.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _c.sent();
                        response = null;
                        return [2 /*return*/, response];
                    case 3:
                        if (response) {
                            if (!response.data.data.strasse)
                                return [2 /*return*/, null];
                            return [2 /*return*/, response.data.data.strasse.map(function (v) { return ({
                                    value: v.name,
                                    label: v.name
                                }); })];
                        }
                        return [2 /*return*/, null];
                }
            });
        });
    };
    var getCityByZipCode = function (_a) {
        var plz = _a.plz;
        return __awaiter(void 0, void 0, Promise, function () {
            var response, e_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, client.get(BASE_ENDPOINT_PATH + "/getOrteByPlz", {
                                params: {
                                    plz: plz
                                }
                            })];
                    case 1:
                        response = _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _b.sent();
                        response = null;
                        return [2 /*return*/, response];
                    case 3: return [2 /*return*/, response ? response.data.data.ort : []];
                }
            });
        });
    };
    var getCityInfoExtended = function (_a) {
        var hausnummer = _a.hausnummer, ort = _a.ort, plz = _a.plz, strasse = _a.strasse;
        return __awaiter(void 0, void 0, Promise, function () {
            var response, data, e_3;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        data = {
                            hausnummer: hausnummer,
                            ort: ort,
                            plz: plz,
                            strasse: strasse
                        };
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, client.post(BASE_ENDPOINT_PATH + "/getOrtsinfoExtended", data)];
                    case 2:
                        response = _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_3 = _b.sent();
                        response = null;
                        return [2 /*return*/, response];
                    case 4: return [2 /*return*/, response ? response.data : null];
                }
            });
        });
    };
    return { getStreetByZipCodeAndCity: getStreetByZipCodeAndCity, getCityByZipCode: getCityByZipCode, getCityInfoExtended: getCityInfoExtended };
};
export default useLocationInfo;
