import { __decorate, __extends, __read, __spread } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
var colors = ['orangered', 'teal', 'palevioletred', 'magenta'];
var DebugComponent = /** @class */ (function (_super) {
    __extends(DebugComponent, _super);
    function DebugComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(DebugComponent.prototype, "propsString", {
        get: function () {
            return JSON.stringify(this.props);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DebugComponent.prototype, "currentPath", {
        get: function () {
            return __spread(this.parentPath, [this.index]);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DebugComponent.prototype, "currentPathStringified", {
        get: function () {
            return this.currentPath.join('.');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DebugComponent.prototype, "style", {
        get: function () {
            var depth = this.currentPath.length - 1;
            var color = colors[depth % colors.length];
            var fontSize = 32 - depth * 4 + "px";
            var borderWidth = 6 - depth * 1 + "px";
            return {
                border: borderWidth + " dashed " + color,
                color: color,
                padding: '5px 15px',
                margin: '10px',
                'font-size': fontSize,
                'word-wrap': 'break-word'
            };
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop({ default: function () { return []; } })
    ], DebugComponent.prototype, "parentPath", void 0);
    __decorate([
        Prop({ required: true })
    ], DebugComponent.prototype, "index", void 0);
    __decorate([
        Prop({ required: true })
    ], DebugComponent.prototype, "type", void 0);
    __decorate([
        Prop({ default: null })
    ], DebugComponent.prototype, "props", void 0);
    DebugComponent = __decorate([
        Component
    ], DebugComponent);
    return DebugComponent;
}(Vue));
export default DebugComponent;
