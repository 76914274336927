import { __assign, __decorate, __extends } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import { QCardSection, QOptionGroup, QRadio } from 'quasar';
import BaseControl from './BaseControl';
var RadioControl = /** @class */ (function (_super) {
    __extends(RadioControl, _super);
    function RadioControl() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(RadioControl.prototype, "radioValue", {
        get: function () {
            return this.controlProps.value;
        },
        set: function (value) {
            this.emitInput(value);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RadioControl.prototype, "html", {
        get: function () {
            var _a;
            return (_a = this.props.html) !== null && _a !== void 0 ? _a : false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RadioControl.prototype, "controlProps", {
        get: function () {
            var _a = this.props, dense = _a.dense, disabled = _a.disabled, inline = _a.inline, readonly = _a.readonly;
            return __assign(__assign({}, this.baseInputProps), { dense: dense !== null && dense !== void 0 ? dense : false, disable: disabled !== null && disabled !== void 0 ? disabled : false, inline: inline !== null && inline !== void 0 ? inline : false, options: this.options, readonly: readonly !== null && readonly !== void 0 ? readonly : false });
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop({ required: true })
    ], RadioControl.prototype, "options", void 0);
    __decorate([
        Prop({ required: true })
    ], RadioControl.prototype, "props", void 0);
    RadioControl = __decorate([
        Component({
            components: { QOptionGroup: QOptionGroup, QRadio: QRadio, QCardSection: QCardSection }
        })
    ], RadioControl);
    return RadioControl;
}(BaseControl));
export default RadioControl;
