import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
var FormRow = /** @class */ (function (_super) {
    __extends(FormRow, _super);
    function FormRow() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(FormRow.prototype, "className", {
        get: function () {
            var _a = this.props, classes = _a.classes, justify = _a.justify, reverse = _a.reverse;
            return "tr-row " + (reverse ? 'reverse' : '') + " justify-" + (justify !== null && justify !== void 0 ? justify : 'start') + " " + (classes ? "" + classes : '');
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop({ default: function () { return ({}); } })
    ], FormRow.prototype, "props", void 0);
    FormRow = __decorate([
        Component
    ], FormRow);
    return FormRow;
}(Vue));
export default FormRow;
