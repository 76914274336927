import { render, staticRenderFns } from "./TcUsage.vue?vue&type=template&id=54802f1e&"
import script from "./TcUsage.vue?vue&type=script&lang=ts&"
export * from "./TcUsage.vue?vue&type=script&lang=ts&"
import style0 from "./TcUsage.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports