import EventBus from '../../utils/eventBus';
var DEFAULT_DURATION = function (string) { return Math.max(2000, (1000 * string.length) / 12 + 400); };
export default function bake(options) {
    var config = {};
    // set default value if option is a string type
    if (typeof options === 'string') {
        config = {
            message: options,
            duration: DEFAULT_DURATION(options),
            type: 'default'
        };
    }
    // Calculate duration
    if (typeof options !== 'string') {
        if (!options.duration && options.message) {
            config.duration = DEFAULT_DURATION(options.message);
        }
        if (!options.type) {
            config.type = 'default';
        }
    }
    // send the data
    EventBus.$emit('DATA_TOAST', options);
}
