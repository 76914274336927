import { render, staticRenderFns } from "./FormClickButtonSecondary.vue?vue&type=template&id=4237897c&scoped=true&"
import script from "./FormClickButtonSecondary.vue?vue&type=script&lang=ts&"
export * from "./FormClickButtonSecondary.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4237897c",
  null
  
)

export default component.exports