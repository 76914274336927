import { __assign, __decorate, __extends, __read } from "tslib";
import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import * as ControlComponents from './controls';
import ControlNotFound from './controls/ControlNotFound.vue';
import { isRequiredField } from './models/guards';
var ComponentMappings = __assign(__assign({}, ControlComponents), { EmailControl: ControlComponents.TextControl, PasswordControl: ControlComponents.TextControl, TextareaControl: ControlComponents.TextControl });
var supportedComponentNames = Object.keys(ComponentMappings);
function isSupportedControl(controlName) {
    var isSupported = supportedComponentNames.includes(controlName);
    return isSupported;
}
var FormSmartField = /** @class */ (function (_super) {
    __extends(FormSmartField, _super);
    function FormSmartField() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(FormSmartField.prototype, "fieldDefinition", {
        get: function () {
            return this.form.fields[this.name];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FormSmartField.prototype, "componentName", {
        get: function () {
            var _a = this, input = _a.input, name = _a.name;
            if (!this.fieldDefinition) {
                console.warn("UF FormSmartField: field definition for field \"" + name + "\" not found.");
                return 'ControlNotFound';
            }
            var controlName = "" + input.charAt(0).toUpperCase() + input.slice(1).toLowerCase() + "Control";
            if (isSupportedControl(controlName)) {
                return controlName;
            }
            console.warn("UF FormSmartField: control for input type \"" + input + "\" not found.");
            return 'TextControl';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FormSmartField.prototype, "isRequired", {
        get: function () {
            var definition = this.form.fields[this.name];
            if (!isRequiredField(definition)) {
                return false;
            }
            var requiredRule = definition.validation.required;
            if (!requiredRule) {
                return false;
            }
            if (requiredRule === true || typeof requiredRule === 'string') {
                return true;
            }
            var conditions = this.formMetadata.conditions;
            var _a = __read(requiredRule, 2), conditionName = _a[1];
            var conditionResolved = !!conditions[conditionName];
            return conditionResolved;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FormSmartField.prototype, "propsResolved", {
        get: function () {
            var conditions = this.formMetadata.conditions;
            var props = this.condProps.reduce(function (acc, _a) {
                var _b = __read(_a, 3), conditionName = _b[0], conditionalProps = _b[1], revert = _b[2];
                var isConditionSatisfied = conditions[conditionName];
                var shouldApplyConditionalProps = (!revert && isConditionSatisfied) || (revert && !isConditionSatisfied);
                return __assign(__assign({}, acc), (shouldApplyConditionalProps ? conditionalProps : {}));
            }, this.props);
            return props;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(FormSmartField.prototype, "passedProps", {
        get: function () {
            var _a;
            if (!this.fieldDefinition) {
                return {
                    value: '',
                    label: 'not found!',
                    name: this.name,
                    input: 'text',
                    options: null,
                    form: this.form,
                    formMetadata: this.formMetadata,
                    isRequired: false,
                    props: null
                };
            }
            var options = (_a = this.fieldDefinition.options) !== null && _a !== void 0 ? _a : null;
            var _b = this, name = _b.name, fieldDefinition = _b.fieldDefinition, form = _b.form, formMetadata = _b.formMetadata, formValue = _b.formValue, input = _b.input, isRequired = _b.isRequired, propsResolved = _b.propsResolved;
            return {
                name: name,
                label: fieldDefinition.label,
                value: formValue[name],
                input: input,
                options: options,
                formMetadata: formMetadata,
                form: form,
                isRequired: isRequired,
                props: propsResolved
            };
        },
        enumerable: false,
        configurable: true
    });
    FormSmartField.prototype.handleValueChange = function (value) {
        this.updateFieldValue(this.name, value);
    };
    __decorate([
        Inject()
    ], FormSmartField.prototype, "updateFieldValue", void 0);
    __decorate([
        Prop({ required: true })
    ], FormSmartField.prototype, "form", void 0);
    __decorate([
        Prop({ required: true })
    ], FormSmartField.prototype, "formMetadata", void 0);
    __decorate([
        Prop({ required: true })
    ], FormSmartField.prototype, "formValue", void 0);
    __decorate([
        Prop({ required: true })
    ], FormSmartField.prototype, "name", void 0);
    __decorate([
        Prop({ required: true })
    ], FormSmartField.prototype, "input", void 0);
    __decorate([
        Prop({ default: function () { return ({}); } })
    ], FormSmartField.prototype, "props", void 0);
    __decorate([
        Prop({ default: function () { return []; } })
    ], FormSmartField.prototype, "condProps", void 0);
    FormSmartField = __decorate([
        Component({
            components: __assign(__assign({}, ComponentMappings), { ControlNotFound: ControlNotFound })
        })
    ], FormSmartField);
    return FormSmartField;
}(Vue));
export default FormSmartField;
