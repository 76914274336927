import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { QBtn, QCard, QCardSection, QIcon, QItem, QItemLabel, QItemSection, QList, QSeparator } from 'quasar';
import { dateNow, formatDateToGerman, GERMAN_DATE_FORMAT } from '../../helpers';
import TariffSelectedBox from './TariffSelectedBox.vue';
var TcccSummary = /** @class */ (function (_super) {
    __extends(TcccSummary, _super);
    function TcccSummary() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.tariffCalculatorIDs = '1,2,3,4,5,6';
        _this.telcoCalculatorIDs = '7';
        return _this;
    }
    Object.defineProperty(TcccSummary.prototype, "isRelocation", {
        get: function () {
            return this.portalData.einzug_umzug === 'E01' || this.portalData.einzug_umzug === 'tarifwechsel';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TcccSummary.prototype, "orderDate", {
        get: function () {
            return dateNow(GERMAN_DATE_FORMAT);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TcccSummary.prototype, "contactDataBirthday", {
        get: function () {
            return this.portalData.la_geburtsdatum && formatDateToGerman(this.portalData.la_geburtsdatum);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TcccSummary.prototype, "terminwunsch", {
        get: function () {
            return this.portalData.terminwunsch && formatDateToGerman(this.portalData.terminwunsch);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TcccSummary.prototype, "isTelco", {
        get: function () {
            return this.telcoCalculatorIDs.includes(this.portalData.tariffCalculatorTypeId);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TcccSummary.prototype, "DatumEinzugUmzug", {
        get: function () {
            return formatDateToGerman(this.portalData.datum_einzug_umzug);
        },
        enumerable: false,
        configurable: true
    });
    TcccSummary.prototype.formatCity = function (location) {
        if (!location)
            return '';
        if (location.includes('|')) {
            return location.split(' ')[2];
        }
        return location;
    };
    __decorate([
        Prop({ required: false, default: false })
    ], TcccSummary.prototype, "isOffer", void 0);
    __decorate([
        Prop({ required: true })
    ], TcccSummary.prototype, "portalData", void 0);
    __decorate([
        Prop({ required: true })
    ], TcccSummary.prototype, "widgetLabels", void 0);
    TcccSummary = __decorate([
        Component({
            components: {
                TariffSelectedBox: TariffSelectedBox,
                QBtn: QBtn,
                QCard: QCard,
                QCardSection: QCardSection,
                QIcon: QIcon,
                QItem: QItem,
                QItemLabel: QItemLabel,
                QItemSection: QItemSection,
                QList: QList,
                QSeparator: QSeparator
            }
        })
    ], TcccSummary);
    return TcccSummary;
}(Vue));
export default TcccSummary;
