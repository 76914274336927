<template>
  <span class="tooltip">
    <slot name="target"></slot>
    <slot name="text"></slot>
  </span>
</template>

<script>
export default {
  name: 'Tooltip'
};
</script>

<style scoped></style>
