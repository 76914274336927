var createZipCodeValidator = function (message) { return function (value) {
    var isValid = /^\d{5}$/.test(value);
    if (!isValid)
        return message;
    return null;
}; };
var createUsageValidator = function (min, max, message) { return function (value) {
    var isInRange = min <= value && value <= max;
    if (!isInRange)
        return message;
    return null;
}; };
export { createUsageValidator, createZipCodeValidator };
