import { __assign, __decorate, __extends } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
var controlsCommonStyle = {
    outlined: true,
    square: true
};
var BaseControl = /** @class */ (function (_super) {
    __extends(BaseControl, _super);
    function BaseControl() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(BaseControl.prototype, "errorMessage", {
        get: function () {
            var _a, _b;
            var message = (_b = (_a = this.formMetadata.errors) === null || _a === void 0 ? void 0 : _a[this.name]) !== null && _b !== void 0 ? _b : null;
            if (typeof message === 'boolean') {
                return '';
            }
            return message;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BaseControl.prototype, "baseInputProps", {
        get: function () {
            var _a = this, label = _a.label, name = _a.name, value = _a.value, isRequired = _a.isRequired, errorMessage = _a.errorMessage;
            var labelResolved = isRequired ? label + " *" : label;
            var errorData = this.formMetadata.hasPersistentError
                ? {
                    error: !!errorMessage,
                    errorMessage: errorMessage
                }
                : {
                    lazyRules: true,
                    rules: [function () { return errorMessage; }]
                };
            return __assign(__assign({ label: labelResolved, name: name,
                value: value }, errorData), controlsCommonStyle);
        },
        enumerable: false,
        configurable: true
    });
    BaseControl.prototype.emitInput = function (value) {
        return value;
    };
    __decorate([
        Prop({ required: true })
    ], BaseControl.prototype, "label", void 0);
    __decorate([
        Prop({ required: true })
    ], BaseControl.prototype, "name", void 0);
    __decorate([
        Prop({ required: true })
    ], BaseControl.prototype, "value", void 0);
    __decorate([
        Prop({ required: true })
    ], BaseControl.prototype, "input", void 0);
    __decorate([
        Prop({ default: function () { return ({}); } })
    ], BaseControl.prototype, "props", void 0);
    __decorate([
        Prop({ required: true })
    ], BaseControl.prototype, "isRequired", void 0);
    __decorate([
        Prop({ default: null })
    ], BaseControl.prototype, "options", void 0);
    __decorate([
        Prop({ required: true })
    ], BaseControl.prototype, "form", void 0);
    __decorate([
        Prop({ required: true })
    ], BaseControl.prototype, "formMetadata", void 0);
    __decorate([
        Emit('value-change')
    ], BaseControl.prototype, "emitInput", null);
    BaseControl = __decorate([
        Component
    ], BaseControl);
    return BaseControl;
}(Vue));
export default BaseControl;
