import { __decorate, __extends } from "tslib";
import { Component } from 'vue-property-decorator';
import BaseControl from './BaseControl';
var ControlNotFound = /** @class */ (function (_super) {
    __extends(ControlNotFound, _super);
    function ControlNotFound() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ControlNotFound = __decorate([
        Component
    ], ControlNotFound);
    return ControlNotFound;
}(BaseControl));
export default ControlNotFound;
