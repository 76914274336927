var fieldsDefinition = {
    showComment: {
        label: '',
        type: 'boolean'
    },
    comment: {
        label: '',
        type: 'text'
    },
    headerComment: {
        label: 'Kommentar',
        type: 'label'
    },
    remoteSignature: {
        label: 'Die Unterschrift soll kontaktlos erfolgen (per E-Mail)',
        type: 'boolean'
    },
    remoteSignatureDisabled: {
        label: '',
        type: 'boolean'
    },
    digitalSignature: {
        label: '',
        type: 'signature',
        validation: {
            required: ['bitte Unterschrieben Sie!.', 'isDigitalSignatureRequired']
        }
    },
    digitalSignatureBerater: {
        label: '',
        type: 'signature',
        validation: {
            required: ['bitte Unterschrieben Sie!.', 'isShowDigitalSignatureBerater']
        }
    },
    showDigitalSignatureBerater: {
        type: 'boolean',
        label: ''
    },
    counsellingProtocol: {
        type: 'boolean',
        label: ''
    },
    fn_remoteSignature: {
        label: '',
        type: 'boolean'
    },
    showDigitalSignature: {
        type: 'boolean',
        label: ''
    },
    verzichtWiderrufsrecht: {
        label: 'Hiermit verzichte auf mein Widerrufsrecht',
        type: 'boolean'
    },
    isProductDiscription: {
        label: '',
        type: 'boolean'
    },
    isPriceList: {
        label: '',
        type: 'boolean'
    },
    offerMode: {
        label: '',
        type: 'boolean'
    },
    waiverCancellationPolicy: {
        label: '',
        type: 'boolean'
    },
    advertisingConsent: {
        label: '',
        type: 'boolean'
    },
    telco: {
        label: '',
        type: 'boolean'
    },
    showPrePrint: {
        type: 'boolean',
        label: ''
    },
    survey: {
        type: 'boolean',
        label: ''
    },
    fn_billingDelivery: {
        label: '',
        type: 'boolean'
    },
    fn_customersRecruitment: {
        label: '',
        type: 'boolean'
    },
    fn_kundenzufriedenheit: {
        label: '',
        type: 'boolean'
    },
    werberIdentnummer: {
        type: 'text',
        label: 'Kundennummer'
    },
    gutscheincode: {
        type: 'text',
        label: 'Gutscheincode'
    },
    acceptGeneralTnc: {
        type: 'boolean',
        label: 'Ich habe die Allgemeinen Geschäftsbedingungen <a href="%url%" target="_blank" >AGB</a> des Versorgers %versorgername% zur Kenntnis genommen und akzeptiere diese.',
        validation: {
            required: true,
            equalsTrue: true
        }
    },
    downloadOffer: {
        type: 'boolean',
        label: 'unverbindliches Angebot',
        validation: {
            required: true,
            equalsTrue: true
        }
    },
    acceptCancellationPolicy: {
        type: 'boolean',
        label: 'Ich habe die <a href="%url%" target="_blank">Widerrufsbelehrung</a> des Versorgers %versorgername% zur Kenntnis genommen und akzeptiere diese.',
        validation: {
            required: true,
            equalsTrue: true
        }
    },
    acceptPrivacyPolicy: {
        type: 'boolean',
        label: 'Ich habe die <a href="%url%" target="_blank">Datenschutzhinweise</a> mit den Informationen zur Verarbeitung meiner personenbezogenen Daten des Versorgers %versorgername% zur Kenntnis genommen.',
        validation: {
            required: true,
            equalsTrue: true
        }
    },
    // --------------------------------------------Neue Checkboxen SWR----------------------------------------------------------
    acceptProductDiscription: {
        type: 'boolean',
        label: 'Die <a href="%url%" target="_blank">Leistungsbeschreibung</a> habe ich zur Kenntnis genommen.',
        validation: {
            required: true,
            equalsTrue: true
        }
    },
    acceptPriceList: {
        type: 'boolean',
        label: 'Die <a href="%url%" target="_blank">Preisliste</a> habe ich zur Kenntnis genommen.',
        validation: {
            required: true,
            equalsTrue: true
        }
    },
    // --------------------------------------------Neue Checkboxen SWR----------------------------------------------------------
    acceptCounsellingProtocoll: {
        type: 'boolean',
        label: 'Das <a href="%url%" target="_blank">Beratungsprotokoll</a> habe ich zur Kenntnis genommen.',
        validation: {
            required: true,
            equalsTrue: true
        }
    },
    billingDelivery: {
        type: 'text',
        label: 'Zustellung der Rechnung',
        options: [
            { value: 'post', label: 'per Post' },
            { value: 'email', label: 'per E-Mail' }
            /*  { value: 'kundenportal', label: 'im Kundenportal' } */
        ],
        validation: {
            required: [null, 'isTariff']
        }
    },
    advertisingConsentPost: { type: 'boolean', label: 'per Post' },
    advertisingConsentPhone: { type: 'boolean', label: 'per Telefon' },
    advertisingConsentEmail: { type: 'boolean', label: 'per Email' },
    advertisingConsentDigitalMessages: { type: 'boolean', label: 'per sonstiger elektronischer Nachrichten' },
    footerAdvertisingConsent: { type: 'label', label: '' },
    subAdvertisingConsent: { type: 'label', label: 'Ja, ich stimme zu, dass mich %versorgername% zu weiteren Produktangeboten kontaktieren darf. Diese Einwilligung kann ich jederzeit widerrufen.' },
    headerAdvertisingConsent: { type: 'label', label: 'Werbeeinwilligung' },
    customerSatisfactionSurveyPost: { type: 'boolean', label: 'per Post' },
    customerSatisfactionSurveyPhone: { type: 'boolean', label: 'per Telefon' },
    customerSatisfactionSurveyEmail: { type: 'boolean', label: 'per Email' },
    createAccountAgreement: { type: 'boolean', label: 'Ja, ich möchte ein Kundenkonto anlegen.' },
    fn_advertisingConsentDigitalMessages: {
        label: '',
        type: 'boolean'
    },
    fn_advertisingConsentPost: {
        label: '',
        type: 'boolean'
    },
    // ProcessingConsent
    fn_ProcessingConsent: {
        label: '',
        type: 'boolean'
    },
    dataProcessingEmail: { type: 'boolean', label: 'Ihre E-Mailadresse und Mobilfunknummer, um Ihnen Text- und Bild-Nachrichten über %versorgername%-Produkte zuzusenden, die Ihren bei %versorgername% erworbenen Produkten ähneln' },
    dataProcessingPostalAddress: { type: 'boolean', label: 'Ihre Postadresse, um Ihnen Informationen zum gesamten Sortiment der %versorgername%-Produkte per Brief zuzusenden' },
    dataProcessingInventoryData: { type: 'boolean', label: 'bestimmte Bestandsdaten, soweit es erforderlich ist, um Ihnen für Ihren individuellen Bedarf passende %versorgername%-Produkte anzubieten' },
    dataProcessingIban: { type: 'boolean', label: 'Ihre IBAN (pseudonymisiert, sofern Sie diese angegeben haben), um zu erkennen, ob Sie mit anderen %versorgername%- Kunden in einer Verbindung stehen, und Ihnen für den daraus abgeleiteten Bedarf passende %versorgername%-Produkte anzubieten.' },
    headerDataProcessingConsent: { label: 'Datenverarbeitungseinwilligungen', type: 'label' },
    subDataProcessingConsent: { label: 'Auf der Grundlage gesetzlicher Bestimmungen wird %versorgername% folgende Daten von Ihnen verarbeiten:', type: 'label' },
    footerUserProfileConsent: { label: '', type: 'label' },
    // MarketingConsent
    fn_ProfileConsent: { label: '', type: 'boolean' },
    footerDataProcessingConsent: { label: '', type: 'label' },
    userProfileConsentInventoryData: { label: 'Bestandsdaten', type: 'boolean' },
    userProfileConsentTrafficData: { label: 'Verkehrsdaten', type: 'boolean' },
    headerUserProfileConsent: { label: '', type: 'label' },
    subUserProfileConsent: { label: 'Ich bin einverstanden, dass %versorgername% ein Nutzungsprofil erstellt, um mir bedarfsgerechte %versorgername%-Produkte anzubieten und zwar mit meinen:', type: 'label' },
    // OnlineConsent
    fn_OnlineConsent: { label: '', type: 'boolean' },
    onlineConsentUseData: { type: 'boolean', label: 'Ich bin einverstanden, dass %versorgername% meine Online-Nutzungsdaten meinem Nutzungsprofil hinzufügt, um mir bedarfsgerechte %versorgername%-Produkte anzubieten.' },
    onlineConsentIdentificationFeatures: { type: 'boolean', label: 'Ich bin einverstanden, dass %versorgername% Identifikationsmerkmale an bestimmte Werbenetzwerke auch in Drittländer außerhalb der EU übermittelt, um bedarfsgerechte %versorgername%- Produkte anzubieten. Es ist hierbei nicht auszuschließen, dass dortige Behörden auf diese Daten Zugriff haben und meine Rechte insofern ganz oder teilweise eingeschränkt sind .' },
    headerOnlineConsent: { label: 'Online-Einwilligung', type: 'label' },
    headerSignature: { label: 'Unterschrift:', type: 'label' },
    headerSignatureCounsellingProtocol: { label: 'Unterschrift des Vermittlers zur Kundenbelehrung', type: 'label' },
    subOnlineConsent: { label: '', type: 'label' },
    footerOnlineConsent: {
        label: 'Meine Einwilligungen gelten im Rahmen aller unter einer Kundennummer mit %versorgername% geschlossenen Verträge. Sie können diese Einwilligungen jederzeit mit Wirkung für die ' +
            'Zukunft widerrufen. ' +
            'Weitere Informationen zur Verarbeitung Ihrer personenbezogenen Daten und zu Ihren ' +
            'Betroffenenrechten finden Sie im beigefügten Datenschutzmerkblatt.',
        type: 'label'
    }
};
var optInsForm = {
    title: 'Beauftragung',
    fields: fieldsDefinition,
    initialValues: {
        showComment: false,
        showDigitalSignatureBerater: false,
        fn_remoteSignature: false,
        remoteSignature: false,
        remoteSignatureDisabled: false,
        billingDelivery: 'email',
        telco: false,
        downloadOffer: false,
        showPrePrint: true,
        fn_customersRecruitment: false,
        fn_kundenzufriedenheit: true,
        fn_billingDelivery: true,
        fn_ProcessingConsent: false,
        fn_OnlineConsent: false,
        fn_ProfileConsent: false,
        fn_advertisingConsentDigitalMessages: false,
        fn_advertisingConsentPost: true,
        offerMode: false,
        survey: true,
        advertisingConsent: true,
        waiverCancellationPolicy: false,
        isProductDiscription: true,
        isPriceList: true,
        dataProcessingEmail: true,
        dataProcessingPostalAddress: true,
        dataProcessingInventoryData: true,
        dataProcessingIban: true
    },
    conditions: {
        isTelco: { fieldName: 'telco', type: 'equals', value: true },
        isTariff: { fieldName: 'telco', type: 'equals', value: false },
        show_customersRecruitment: { fieldName: 'fn_customersRecruitment', type: 'equals', value: true },
        showBillingDelivery: { fieldName: 'fn_billingDelivery', type: 'equals', value: true },
        showAdvertisingConsent: { fieldName: 'advertisingConsent', type: 'equals', value: true },
        show_kundenzufriedenheit: { fieldName: 'fn_kundenzufriedenheit', type: 'equals', value: true },
        showPrePrint: { fieldName: 'showPrePrint', type: 'equals', value: true },
        showOfferButton: { fieldName: 'offerMode', type: 'equals', value: true },
        showSurvey: { fieldName: 'survey', type: 'equals', value: true },
        showProductDiscription: { fieldName: 'isProductDiscription', type: 'equals', value: true },
        showPriceList: { fieldName: 'isPriceList', type: 'equals', value: true },
        showWaiverCancellationPolicy: { fieldName: 'waiverCancellationPolicy', type: 'equals', value: true },
        showProcessingConsent: { fieldName: 'fn_ProcessingConsent', type: 'equals', value: true },
        showOnlineConsent: { fieldName: 'fn_OnlineConsent', type: 'equals', value: true },
        showProfileConsent: { fieldName: 'fn_ProfileConsent', type: 'equals', value: true },
        showAdvertisingConsentDigitalMessages: { fieldName: 'fn_advertisingConsentDigitalMessages', type: 'equals', value: true },
        showAdvertisingConsentPost: { fieldName: 'fn_advertisingConsentPost', type: 'equals', value: true },
        counsellingProtocol: { fieldName: 'counsellingProtocol', type: 'equals', value: true },
        showDigitalSignature: { fieldName: 'showDigitalSignature', type: 'equals', value: true },
        isRemoteSignature: { fieldName: 'fn_remoteSignature', type: 'equals', value: true },
        isDigitalSignatureRequired: { fieldName: 'remoteSignature', type: 'equals', value: false },
        showDigitalSignatureBerater: { fieldName: 'showDigitalSignatureBerater', type: 'equals', value: true },
        isShowDigitalSignatureBerater: { fieldName: 'showDigitalSignatureBerater', type: 'equals', value: true },
        isShowComment: { fieldName: 'showComment', type: 'equals', value: true },
        isRemoteSignatureDisabled: { fieldName: 'remoteSignatureDisabled', type: 'equals', value: true }
    },
    structure: [
        { type: 'FormDiv', props: { class: 'q-ma-lg' }, children: [] },
        // Rechner per
        {
            type: 'FormCard',
            props: {},
            children: [
                {
                    type: 'FormConditionalElement',
                    props: { conditionName: 'showBillingDelivery', revert: false },
                    children: [
                        // { type: 'FormSeparator' },
                        {
                            type: 'FormRow',
                            props: { classes: 'tr-flex-wrap' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [{ type: 'FormField', input: 'radio', name: 'billingDelivery' }]
                                }
                            ]
                        }
                    ]
                }
            ]
        }, { type: 'FormDiv', props: { class: 'q-ma-lg' }, children: [] },
        {
            type: 'FormCard',
            props: {},
            children: [
                // Bestellung Tariff
                {
                    type: 'FormConditionalElement',
                    props: { conditionName: 'isTariff' },
                    children: [
                        {
                            type: 'FormRow',
                            props: { classes: 'tr-flex-wrap' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormText',
                                            props: {
                                                text: 'Bestellung',
                                                level: 'h6'
                                            }
                                        },
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'showDigitalSignature' },
                                            children: [
                                                {
                                                    type: 'FormConditionalElement',
                                                    props: { conditionName: 'isDigitalSignatureRequired' },
                                                    children: [
                                                        {
                                                            type: 'FormText',
                                                            props: {
                                                                text: 'Mit Klick auf "Zahlungspflichtig bestellen" beauftrage ich den Versorger mit der Belieferung an die angegebene Lieferanschrift und erteile ihm die Vollmacht, ggfs. meinen derzeit bestehenden Vertrag zu kündigen sowie die erforderlichen Verträge mit dem örtlichen Netzbetreiber abzuschließen.',
                                                                level: 'body2',
                                                                align: 'justify'
                                                            }
                                                        }
                                                    ]
                                                },
                                                {
                                                    type: 'FormConditionalElement',
                                                    props: { conditionName: 'isDigitalSignatureRequired', revert: true },
                                                    children: [
                                                        {
                                                            type: 'FormText',
                                                            props: {
                                                                text: 'Nach dem Klick auf "Angebot zur Unterschrift" erhalte ich vom Versorger ein Angebot zur Belieferung an die angegebene Lieferanschrift. Mit der Unterschrift per E-Mail beauftrage ich den Versorger und erteile ihm die Vollmacht, ggfs. meinen derzeit bestehenden Vertrag zu kündigen sowie die erforderlichen Verträge mit dem örtlichen Netzbetreiber abzuschließen.',
                                                                level: 'body2',
                                                                align: 'justify'
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]
                                        },
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'showDigitalSignature', revert: true },
                                            children: [
                                                {
                                                    type: 'FormText',
                                                    props: {
                                                        text: 'Mit Klick auf "Zahlungspflichtig bestellen" beauftrage ich den Versorger mit der Belieferung an die angegebene Lieferanschrift und erteile ihm die Vollmacht, ggfs. meinen derzeit bestehenden Vertrag zu kündigen sowie die erforderlichen Verträge mit dem örtlichen Netzbetreiber abzuschließen.',
                                                        level: 'body2',
                                                        align: 'justify'
                                                    }
                                                }
                                            ]
                                        },
                                        { type: 'FormDiv', props: { class: 'q-my-md' }, children: [] }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormField',
                                            input: 'checkbox',
                                            name: 'acceptGeneralTnc',
                                            props: { html: true }
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormField',
                                            input: 'checkbox',
                                            name: 'acceptCancellationPolicy',
                                            props: { html: true }
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormField',
                                            input: 'checkbox',
                                            name: 'acceptPrivacyPolicy',
                                            props: { html: true }
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'counsellingProtocol' },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    input: 'checkbox',
                                                    name: 'acceptCounsellingProtocoll',
                                                    props: { html: true }
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                // Bestellung Telko
                {
                    type: 'FormConditionalElement',
                    props: { conditionName: 'isTelco' },
                    children: [
                        {
                            type: 'FormRow',
                            props: { classes: 'tr-flex-wrap' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormText',
                                            props: {
                                                text: 'Bestellung',
                                                level: 'h6'
                                            }
                                        },
                                        {
                                            type: 'FormText',
                                            props: {
                                                text: 'Die nachfolgenden Dokumente sowie das bereitgestellte Produktinformationsblatt enthalten \n' +
                                                    'wichtige Informationen zu dem von Ihnen gewählten Dienst und den zugrundeliegenden \n' +
                                                    'vertraglichen Bestimmungen. Bitte beachten Sie, dass Sie über diese Informationen zum Zweck der  \n' +
                                                    'Dokumentation, der künftigen Bezugnahme und der unveränderten Wiedergabe nur verfügen  \n' +
                                                    'können, wenn Sie diese herunterladen.',
                                                level: 'body2',
                                                align: 'justify'
                                            }
                                        },
                                        { type: 'FormDiv', props: { class: 'q-my-md' }, children: [] }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'showProductDiscription' },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    input: 'checkbox',
                                                    name: 'acceptProductDiscription',
                                                    props: { html: true }
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'showPriceList' },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    input: 'checkbox',
                                                    name: 'acceptPriceList',
                                                    props: { html: true }
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormField',
                                            input: 'checkbox',
                                            name: 'acceptGeneralTnc',
                                            props: { html: true }
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormField',
                                            input: 'checkbox',
                                            name: 'acceptCancellationPolicy',
                                            props: { html: true }
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormField',
                                            input: 'checkbox',
                                            name: 'acceptPrivacyPolicy',
                                            props: { html: true }
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'counsellingProtocol' },
                                            children: [
                                                {
                                                    type: 'FormField',
                                                    input: 'checkbox',
                                                    name: 'acceptCounsellingProtocoll',
                                                    props: { html: true }
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        { type: 'FormDiv', props: { class: 'q-ma-lg' }, children: [] },
        // OptOut
        {
            type: 'FormConditionalElement',
            props: { conditionName: 'showProcessingConsent' },
            children: [
                {
                    type: 'FormCard',
                    props: {},
                    children: [
                        {
                            type: 'FormConditionalElement',
                            props: { conditionName: 'showProcessingConsent' },
                            children: [
                                {
                                    type: 'FormField',
                                    input: 'label',
                                    name: 'headerDataProcessingConsent',
                                    props: { classes: 'text-h6' }
                                },
                                {
                                    type: 'FormRow',
                                    props: { classes: 'tr-flex-wrap' },
                                    children: [
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12 },
                                            children: [{ type: 'FormField', input: 'label', name: 'subDataProcessingConsent' }]
                                        },
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12 },
                                            children: [{ type: 'FormField', input: 'checkbox', name: 'dataProcessingEmail' }]
                                        },
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12 },
                                            children: [{ type: 'FormField', input: 'checkbox', name: 'dataProcessingIban' }]
                                        },
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12 },
                                            children: [{ type: 'FormField', input: 'checkbox', name: 'dataProcessingInventoryData' }]
                                        },
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12 },
                                            children: [{ type: 'FormField', input: 'checkbox', name: 'dataProcessingPostalAddress' }]
                                        },
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12 },
                                            children: [{ type: 'FormField', input: 'label', name: 'footerDataProcessingConsent' }]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        { type: 'FormDiv', props: { class: 'q-ma-lg' }, children: [] },
        {
            type: 'FormCard',
            props: {},
            children: [
                {
                    type: 'FormConditionalElement',
                    props: { conditionName: 'showAdvertisingConsent' },
                    children: [
                        { type: 'FormSeparator' },
                        {
                            type: 'FormField',
                            input: 'label',
                            name: 'headerAdvertisingConsent',
                            props: { classes: 'text-h6' }
                        },
                        {
                            type: 'FormRow',
                            props: { classes: 'tr-flex-wrap' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [{ type: 'FormField', input: 'label', name: 'subAdvertisingConsent' }]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'showAdvertisingConsentPost', revert: false },
                                            children: [{ type: 'FormField', input: 'checkbox', name: 'advertisingConsentPost' }]
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [{ type: 'FormField', input: 'checkbox', name: 'advertisingConsentPhone' }]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [{ type: 'FormField', input: 'checkbox', name: 'advertisingConsentEmail' }]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'showAdvertisingConsentDigitalMessages', revert: false },
                                            children: [
                                                { type: 'FormField', input: 'checkbox', name: 'advertisingConsentDigitalMessages' }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [{ type: 'FormField', input: 'label', name: 'footerAdvertisingConsent' }]
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'FormConditionalElement',
                    props: { conditionName: 'showSurvey', revert: false },
                    children: [
                        { type: 'FormSeparator' },
                        {
                            type: 'FormRow',
                            props: { classes: 'tr-flex-wrap checkmar' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [{ type: 'FormText', props: { text: 'Umfrage zur Kundenzufriedenheit', level: 'h6' } }]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12, md: 8 },
                                    children: [
                                        {
                                            type: 'FormText',
                                            props: {
                                                text: 'Ja, ich stimme zu, dass mich der Versorger zu meiner Zufriedenheit mit den mir angebotenen und/oder vermittelten Services befragt werden darf. Diese Einwilligung kann ich jederzeit widerrufen.',
                                                level: 'body1',
                                                align: 'justify'
                                            }
                                        }
                                    ]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [{ type: 'FormField', input: 'checkbox', name: 'customerSatisfactionSurveyPost' }]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [{ type: 'FormField', input: 'checkbox', name: 'customerSatisfactionSurveyPhone' }]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [{ type: 'FormField', input: 'checkbox', name: 'customerSatisfactionSurveyEmail' }]
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'FormConditionalElement',
                    props: { conditionName: 'showProfileConsent' },
                    children: [
                        { type: 'FormSeparator' },
                        {
                            type: 'FormField',
                            input: 'label',
                            name: 'headerUserProfileConsent',
                            props: { classes: 'text-h6' }
                        },
                        {
                            type: 'FormRow',
                            props: { classes: 'tr-flex-wrap' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [{ type: 'FormField', input: 'label', name: 'subUserProfileConsent' }]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [{ type: 'FormField', input: 'checkbox', name: 'userProfileConsentTrafficData' }]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [{ type: 'FormField', input: 'checkbox', name: 'userProfileConsentInventoryData' }]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [{ type: 'FormField', input: 'label', name: 'footerDataProcessingConsent' }]
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'FormConditionalElement',
                    props: { conditionName: 'showOnlineConsent' },
                    children: [
                        { type: 'FormSeparator' },
                        {
                            type: 'FormField',
                            input: 'label',
                            name: 'headerOnlineConsent',
                            props: { classes: 'text-h6' }
                        },
                        {
                            type: 'FormRow',
                            props: { classes: 'tr-flex-wrap' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [{ type: 'FormField', input: 'label', name: 'subOnlineConsent' }]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [{ type: 'FormField', input: 'checkbox', name: 'onlineConsentUseData' }]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [{ type: 'FormField', input: 'checkbox', name: 'onlineConsentIdentificationFeatures' }]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [{ type: 'FormField', input: 'label', name: 'footerOnlineConsent' }]
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'FormConditionalElement',
                    props: { conditionName: 'show_customersRecruitment' },
                    children: [
                        { type: 'FormSeparator' },
                        {
                            type: 'FormRow',
                            props: { classes: 'flex tr-flex-wrap full-width' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [{ type: 'FormText', props: { text: 'Ich bestelle auf Empfehlung von:', level: 'h6' } }]
                                },
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        /* {
                                          type: 'FormText',
                                          props: {
                                            text: 'Geben Sie bitte hier die Kundennummer des werbenden Kunden ein:',
                                            level: 'body1',
                                            align: 'justify'
                                          }
                                        }, */
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12, gutter: ['md', 'x'] },
                                            children: [{ type: 'FormField', input: 'text', name: 'werberIdentnummer' }]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'FormConditionalElement',
                    props: { conditionName: 'isShowComment' },
                    children: [
                        { type: 'FormSeparator' },
                        {
                            type: 'FormRow',
                            props: { classes: 'flex tr-flex-wrap full-width' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormField',
                                            input: 'label',
                                            name: 'headerComment',
                                            props: { classes: 'text-h6' }
                                        },
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12, gutter: ['md', 'x'] },
                                            children: [{ type: 'FormField', input: 'text', name: 'comment', props: { maxlength: 220 } }]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'FormConditionalElement',
                    props: { conditionName: 'showWaiverCancellationPolicy', revert: false },
                    children: [
                        { type: 'FormSeparator' },
                        {
                            type: 'FormCol',
                            props: { xs: 12 },
                            children: [{ type: 'FormText', props: { text: 'Widerrufsrecht', level: 'h6' } }]
                        },
                        {
                            type: 'FormRow',
                            props: { classes: 'tr-flex-wrap' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [{ type: 'FormField', input: 'checkbox', name: 'verzichtWiderrufsrecht', props: { html: true } }]
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'FormConditionalElement',
                    props: { conditionName: 'isTelco' },
                    children: [
                        {
                            type: 'FormDiv',
                            props: { class: 'q-pt-md q-px-sm text-center' },
                            children: [
                                {
                                    type: 'FormText',
                                    props: {
                                        text: 'Bevor Sie das Produkt bestellen können, laden Sie sich bitte Ihr unverbindliches Angebot herunter.',
                                        level: 'h6'
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        { type: 'FormDiv', props: { class: 'q-ma-lg' }, children: [] },
        {
            type: 'FormConditionalElement',
            props: { conditionName: 'showDigitalSignature' },
            children: [
                {
                    type: 'FormCard',
                    props: {},
                    children: [
                        {
                            type: 'FormField',
                            input: 'label',
                            name: 'headerSignature',
                            props: { classes: 'text-h6' }
                        },
                        {
                            type: 'FormRow',
                            props: { classes: 'flex tr-flex-wrap full-width' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'isRemoteSignature' },
                                            children: [
                                                {
                                                    type: 'FormConditionalElement',
                                                    props: { conditionName: 'isRemoteSignatureDisabled' },
                                                    children: [{ type: 'FormField', input: 'checkbox', name: 'remoteSignature', props: { disabled: true } }
                                                    ]
                                                },
                                                {
                                                    type: 'FormConditionalElement',
                                                    props: { conditionName: 'isRemoteSignatureDisabled', revert: true },
                                                    children: [{ type: 'FormField', input: 'checkbox', name: 'remoteSignature', props: { disabled: false } }
                                                    ]
                                                }
                                            ]
                                        },
                                    ]
                                }
                            ]
                        },
                        {
                            type: 'FormConditionalElement',
                            props: { conditionName: 'isDigitalSignatureRequired' },
                            children: [
                                {
                                    type: 'FormDiv',
                                    props: { class: 'q-py-md' },
                                    children: [{ type: 'FormField', input: 'signature', name: 'digitalSignature' }]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        { type: 'FormDiv', props: { class: 'q-ma-lg' }, children: [] },
        {
            type: 'FormConditionalElement',
            props: { conditionName: 'showDigitalSignatureBerater' },
            children: [
                {
                    type: 'FormCard',
                    props: {},
                    children: [
                        {
                            type: 'FormField',
                            input: 'label',
                            name: 'headerSignatureCounsellingProtocol',
                            props: { classes: 'text-h6' }
                        },
                        {
                            type: 'FormDiv',
                            props: { class: 'q-py-md' },
                            children: [{ type: 'FormField', input: 'signature', name: 'digitalSignatureBerater' }]
                        }
                    ]
                }
            ]
        },
        { type: 'FormDiv', props: { class: 'q-ma-lg' }, children: [] },
        {
            type: 'FormCard',
            props: {},
            children: [
                {
                    type: 'FormConditionalElement',
                    props: { conditionName: 'showPrePrint' },
                    children: [
                        {
                            type: 'FormRow',
                            props: { classes: 'flex tr-flex-wrap full-width' },
                            children: [
                                {
                                    type: 'FormCol',
                                    props: { xs: 12 },
                                    children: [
                                        {
                                            type: 'FormCol',
                                            props: { xs: 12 },
                                            children: [{ type: 'FormClickButtonSecondary', props: { title: 'Vertragszusammenfassung zum Download' } }]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'FormConditionalElement',
                    props: { conditionName: 'isTariff' },
                    children: [
                        {
                            type: 'FormDiv',
                            props: { class: 'q-pt-md q-px-sm text-center' },
                            children: [
                                {
                                    type: 'FormConditionalElement',
                                    props: { conditionName: 'showDigitalSignature' },
                                    children: [
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'isDigitalSignatureRequired' },
                                            children: [
                                                {
                                                    type: 'FormText',
                                                    props: {
                                                        text: 'Durch die Betätigung der Schaltfläche "Zahlungspflichtig bestellen" beantrage ich die Versorgung der oben genannten Abnahmestelle zu dem genannten oder zum nächstmöglichen Termin. Im Anschluss wird automatisch eine E-Mail als Auftragseingangsbestätigung an Ihre angegebene E-Mail-Adresse gesendet.',
                                                        level: 'body2'
                                                    }
                                                }
                                            ]
                                        },
                                        {
                                            type: 'FormConditionalElement',
                                            props: { conditionName: 'isDigitalSignatureRequired', revert: true },
                                            children: [
                                                {
                                                    type: 'FormText',
                                                    props: {
                                                        text: 'Durch den Kick auf den Button "Angebot zur Unterschrift" erhält der Kunde ein Angebot zur Versorgung der oben genannten Abnahmestelle zu dem genannten oder zum nächstmöglichen Termin. Nach seiner per E-Mail gegebenen Unterschrift (Telesales) wird im Anschluss automatisch eine E-Mail als Eingangsbestätigung des Auftrages an die angegebene Kunden-E-Mail-Adresse gesendet.',
                                                        level: 'body2'
                                                    }
                                                }
                                            ]
                                        }
                                    ]
                                },
                                {
                                    type: 'FormConditionalElement',
                                    props: { conditionName: 'showDigitalSignature', revert: true },
                                    children: [
                                        {
                                            type: 'FormText',
                                            props: {
                                                text: 'Durch die Betätigung der Schaltfläche "Zahlungspflichtig bestellen" beantrage ich die Versorgung der oben genannten Abnahmestelle zu dem genannten oder zum nächstmöglichen Termin. Im Anschluss wird automatisch eine E-Mail als Auftragseingangsbestätigung an Ihre angegebene E-Mail-Adresse gesendet.',
                                                level: 'body2'
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        {
            type: 'FormDiv',
            props: { class: 'q-mx-md q-my-sm' },
            children: [{ type: 'FormText', props: { text: 'Pflichtfelder sind mit einem Stern * gekennzeichnet.', level: 'body2' } }]
        },
        {
            type: 'FormRow',
            props: { justify: 'between' },
            children: [
                {
                    type: 'FormCol',
                    props: { sm: 4, lg: 3 },
                    children: [
                        {
                            type: 'FormBackButton',
                            props: { title: 'zurück', classes: 'full-width' }
                        }
                    ]
                },
                {
                    type: 'FormCol',
                    props: { sm: 4, lg: 3 },
                    children: [
                        {
                            type: 'FormConditionalElement',
                            props: { conditionName: 'showOfferButton', revert: false, transition: false },
                            children: [
                                {
                                    type: 'FormClickButton',
                                    props: { title: 'Angebot erstellen' }
                                }
                            ]
                        }
                    ]
                },
                {
                    type: 'FormCol',
                    props: { sm: 4, lg: 3 },
                    children: [
                        {
                            type: 'FormConditionalElement',
                            props: { conditionName: 'showDigitalSignature' },
                            children: [
                                {
                                    type: 'FormConditionalElement',
                                    props: { conditionName: 'isDigitalSignatureRequired' },
                                    children: [
                                        { type: 'FormSubmitButton', props: { title: 'Zahlungspflichtig bestellen', classes: 'full-width' } }
                                    ]
                                },
                                {
                                    type: 'FormConditionalElement',
                                    props: { conditionName: 'isDigitalSignatureRequired', revert: true },
                                    children: [
                                        { type: 'FormSubmitButton', props: { title: 'Angebot zur Unterschrift', classes: 'full-width' } }
                                    ]
                                }
                            ]
                        },
                        {
                            type: 'FormConditionalElement',
                            props: { conditionName: 'showDigitalSignature', revert: true },
                            children: [
                                { type: 'FormSubmitButton', props: { title: 'Zahlungspflichtig bestellen', classes: 'full-width' } }
                            ]
                        }
                    ]
                }
            ]
        }
    ]
};
export default optInsForm;
