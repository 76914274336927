import { __assign, __decorate, __extends } from "tslib";
import { Component, Prop, Ref } from 'vue-property-decorator';
import { QBtn, QDate, QIcon, QInput, QSelect } from 'quasar';
import dayjs from 'dayjs';
import store from '../../../store';
import BaseControl from './BaseControl';
var inputMapping = {
    email: 'email',
    password: 'password',
    text: 'text',
    textarea: 'textarea'
};
function hasInputMapping(inputType) {
    return Object.prototype.hasOwnProperty.call(inputMapping, inputType);
}
var TextControl = /** @class */ (function (_super) {
    __extends(TextControl, _super);
    function TextControl() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.countInputs = 1;
        _this.inputValue = '';
        _this.inputValue1 = '';
        _this.inputValue2 = '';
        _this.inputValue3 = '';
        _this.inputValue4 = '';
        _this.inputValue5 = '';
        _this.inputValue6 = '';
        _this.inputValue7 = '';
        _this.inputValue8 = '';
        _this.inputValue9 = '';
        _this.inputValue10 = '';
        _this.inputValue11 = '';
        _this.inputValue12 = '';
        _this.inputValue13 = '';
        _this.inputValue14 = '';
        _this.inputValue15 = '';
        _this.inputValue16 = '';
        _this.inputValue17 = '';
        _this.inputValue18 = '';
        _this.inputValue19 = '';
        _this.inputValue20 = '';
        _this.showDate = false;
        return _this;
    }
    TextControl.prototype.mounted = function () {
        var _a;
        if (this.multiInput) {
            var inputList = (_a = this.controlProps.value) === null || _a === void 0 ? void 0 : _a.toString().split(',');
            if (inputList) {
                this.countInputs = inputList.length;
                // eslint-disable-next-line prefer-destructuring
                if (inputList[0])
                    this.inputValue1 = inputList[0];
                // eslint-disable-next-line prefer-destructuring
                if (inputList[1])
                    this.inputValue2 = inputList[1];
                // eslint-disable-next-line prefer-destructuring
                if (inputList[2])
                    this.inputValue3 = inputList[2];
                // eslint-disable-next-line prefer-destructuring
                if (inputList[3])
                    this.inputValue4 = inputList[3];
                // eslint-disable-next-line prefer-destructuring
                if (inputList[4])
                    this.inputValue5 = inputList[4];
                // eslint-disable-next-line prefer-destructuring
                if (inputList[5])
                    this.inputValue6 = inputList[5];
                // eslint-disable-next-line prefer-destructuring
                if (inputList[6])
                    this.inputValue7 = inputList[6];
                // eslint-disable-next-line prefer-destructuring
                if (inputList[7])
                    this.inputValue7 = inputList[7];
                // eslint-disable-next-line prefer-destructuring
                if (inputList[8])
                    this.inputValue8 = inputList[8];
                // eslint-disable-next-line prefer-destructuring
                if (inputList[9])
                    this.inputValue9 = inputList[9];
                // eslint-disable-next-line prefer-destructuring
                if (inputList[10])
                    this.inputValue9 = inputList[10];
                // eslint-disable-next-line prefer-destructuring
                if (inputList[11])
                    this.inputValue9 = inputList[11];
                // eslint-disable-next-line prefer-destructuring
                if (inputList[12])
                    this.inputValue9 = inputList[12];
                // eslint-disable-next-line prefer-destructuring
                if (inputList[13])
                    this.inputValue9 = inputList[13];
                // eslint-disable-next-line prefer-destructuring
                if (inputList[14])
                    this.inputValue9 = inputList[14];
                // eslint-disable-next-line prefer-destructuring
                if (inputList[15])
                    this.inputValue9 = inputList[15];
                // eslint-disable-next-line prefer-destructuring
                if (inputList[16])
                    this.inputValue9 = inputList[16];
                // eslint-disable-next-line prefer-destructuring
                if (inputList[17])
                    this.inputValue9 = inputList[17];
                // eslint-disable-next-line prefer-destructuring
                if (inputList[18])
                    this.inputValue9 = inputList[18];
                // eslint-disable-next-line prefer-destructuring
                if (inputList[19])
                    this.inputValue9 = inputList[19];
                // eslint-disable-next-line prefer-destructuring
                if (inputList[20])
                    this.inputValue9 = inputList[20];
            }
        }
    };
    TextControl.prototype.optionsAmount = function (maxAnzahl) {
        var allOptions = [{ label: '1', value: 1 }];
        // eslint-disable-next-line no-plusplus
        for (var i = 2; i <= maxAnzahl; i++) {
            allOptions.push({ label: "" + i, value: i });
        }
        return allOptions;
    };
    TextControl.prototype.prepareEmit = function (value) {
        this.emitInput(value.trim());
    };
    TextControl.prototype.updated = function () {
        if (this.isContinueAsOffer) {
            this.trInput.resetValidation();
        }
    };
    Object.defineProperty(TextControl.prototype, "isContinueAsOffer", {
        get: function () {
            return store.states.isContinueAsOffer;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TextControl.prototype, "inputType", {
        get: function () {
            if (hasInputMapping(this.input)) {
                return inputMapping[this.input];
            }
            return 'text';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TextControl.prototype, "date", {
        get: function () {
            return this.baseInputProps.value;
        },
        set: function (value) {
            this.emitInput(value);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TextControl.prototype, "isCheck", {
        get: function () {
            return this.props.isCheck;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TextControl.prototype, "disabled", {
        get: function () {
            var _a;
            return (_a = this.props.disabled) !== null && _a !== void 0 ? _a : false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TextControl.prototype, "readonly", {
        get: function () {
            var _a;
            return (_a = this.props.readonly) !== null && _a !== void 0 ? _a : false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TextControl.prototype, "hasToolTip", {
        get: function () {
            var _a;
            return (_a = this.props.hasToolTip) !== null && _a !== void 0 ? _a : false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TextControl.prototype, "toolTip", {
        get: function () {
            var _a;
            return (_a = this.props.toolTip) !== null && _a !== void 0 ? _a : '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TextControl.prototype, "mask", {
        get: function () {
            var _a;
            return (_a = this.props.mask) !== null && _a !== void 0 ? _a : '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TextControl.prototype, "maxlength", {
        get: function () {
            var _a;
            return (_a = this.props.maxlength) !== null && _a !== void 0 ? _a : -1;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TextControl.prototype, "dateInput", {
        get: function () {
            return this.props.extends === 'date';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TextControl.prototype, "multiInput", {
        get: function () {
            return this.props.extends === 'multi';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TextControl.prototype, "maxMultiSelect", {
        get: function () {
            return store.states.maxMultiSelect;
        },
        enumerable: false,
        configurable: true
    });
    TextControl.prototype.handleBlur = function () {
        if (this.isContinueAsOffer) {
            this.trInput.resetValidation();
        }
    };
    TextControl.prototype.dateOptions = function (date) {
        return (date <=
            dayjs()
                .add(1, 'year')
                .format('YYYY/MM/DD'));
    };
    TextControl.prototype.emitCount = function (value) {
        this.countInputs = value;
        this.mapInputs();
        this.emitInput(this.controlProps.value);
        return value;
    };
    TextControl.prototype.mapInputs = function () {
        var seperator = ',';
        this.controlProps.value = this.inputValue1
            .concat(this.inputValue2 && this.countInputs > 1 ? seperator.concat(this.inputValue2) : '')
            .concat(this.inputValue3 && this.countInputs > 2 ? seperator.concat(this.inputValue3) : '')
            .concat(this.inputValue4 && this.countInputs > 3 ? seperator.concat(this.inputValue4) : '')
            .concat(this.inputValue5 && this.countInputs > 4 ? seperator.concat(this.inputValue5) : '')
            .concat(this.inputValue6 && this.countInputs > 5 ? seperator.concat(this.inputValue6) : '')
            .concat(this.inputValue7 && this.countInputs > 6 ? seperator.concat(this.inputValue7) : '')
            .concat(this.inputValue8 && this.countInputs > 7 ? seperator.concat(this.inputValue8) : '')
            .concat(this.inputValue9 && this.countInputs > 8 ? seperator.concat(this.inputValue9) : '')
            .concat(this.inputValue10 && this.countInputs > 9 ? seperator.concat(this.inputValue10) : '')
            .concat(this.inputValue11 && this.countInputs > 10 ? seperator.concat(this.inputValue11) : '')
            .concat(this.inputValue12 && this.countInputs > 11 ? seperator.concat(this.inputValue12) : '')
            .concat(this.inputValue13 && this.countInputs > 12 ? seperator.concat(this.inputValue13) : '')
            .concat(this.inputValue14 && this.countInputs > 13 ? seperator.concat(this.inputValue14) : '')
            .concat(this.inputValue15 && this.countInputs > 14 ? seperator.concat(this.inputValue15) : '')
            .concat(this.inputValue16 && this.countInputs > 15 ? seperator.concat(this.inputValue16) : '')
            .concat(this.inputValue17 && this.countInputs > 16 ? seperator.concat(this.inputValue17) : '')
            .concat(this.inputValue18 && this.countInputs > 17 ? seperator.concat(this.inputValue18) : '')
            .concat(this.inputValue19 && this.countInputs > 18 ? seperator.concat(this.inputValue19) : '');
    };
    TextControl.prototype.emitMulti1 = function (value) {
        this.inputValue1 = value;
        this.mapInputs();
        this.emitInput(this.controlProps.value);
        return value;
    };
    TextControl.prototype.emitMulti2 = function (value) {
        this.inputValue2 = value;
        this.mapInputs();
        this.emitInput(this.controlProps.value);
        return value;
    };
    TextControl.prototype.emitMulti3 = function (value) {
        this.inputValue3 = value;
        this.mapInputs();
        this.emitInput(this.controlProps.value);
        return value;
    };
    TextControl.prototype.emitMulti4 = function (value) {
        this.inputValue4 = value;
        this.mapInputs();
        this.emitInput(this.controlProps.value);
        return value;
    };
    TextControl.prototype.emitMulti5 = function (value) {
        this.inputValue5 = value;
        this.mapInputs();
        this.emitInput(this.controlProps.value);
        return value;
    };
    TextControl.prototype.emitMulti6 = function (value) {
        this.inputValue6 = value;
        this.mapInputs();
        this.emitInput(this.controlProps.value);
        return value;
    };
    TextControl.prototype.emitMulti7 = function (value) {
        this.inputValue7 = value;
        this.mapInputs();
        this.emitInput(this.controlProps.value);
        return value;
    };
    TextControl.prototype.emitMulti8 = function (value) {
        this.inputValue8 = value;
        this.mapInputs();
        this.emitInput(this.controlProps.value);
        return value;
    };
    TextControl.prototype.emitMulti9 = function (value) {
        this.inputValue9 = value;
        this.mapInputs();
        this.emitInput(this.controlProps.value);
        return value;
    };
    TextControl.prototype.emitMulti10 = function (value) {
        this.inputValue10 = value;
        this.mapInputs();
        this.emitInput(this.controlProps.value);
        return value;
    };
    TextControl.prototype.emitMulti11 = function (value) {
        this.inputValue11 = value;
        this.mapInputs();
        this.emitInput(this.controlProps.value);
        return value;
    };
    TextControl.prototype.emitMulti12 = function (value) {
        this.inputValue12 = value;
        this.mapInputs();
        this.emitInput(this.controlProps.value);
        return value;
    };
    TextControl.prototype.emitMulti13 = function (value) {
        this.inputValue13 = value;
        this.mapInputs();
        this.emitInput(this.controlProps.value);
        return value;
    };
    TextControl.prototype.emitMulti14 = function (value) {
        this.inputValue14 = value;
        this.mapInputs();
        this.emitInput(this.controlProps.value);
        return value;
    };
    TextControl.prototype.emitMulti15 = function (value) {
        this.inputValue15 = value;
        this.mapInputs();
        this.emitInput(this.controlProps.value);
        return value;
    };
    TextControl.prototype.emitMulti16 = function (value) {
        this.inputValue16 = value;
        this.mapInputs();
        this.emitInput(this.controlProps.value);
        return value;
    };
    TextControl.prototype.emitMulti17 = function (value) {
        this.inputValue17 = value;
        this.mapInputs();
        this.emitInput(this.controlProps.value);
        return value;
    };
    TextControl.prototype.emitMulti18 = function (value) {
        this.inputValue18 = value;
        this.mapInputs();
        this.emitInput(this.controlProps.value);
        return value;
    };
    TextControl.prototype.emitMulti19 = function (value) {
        this.inputValue19 = value;
        this.mapInputs();
        this.emitInput(this.controlProps.value);
        return value;
    };
    TextControl.prototype.emitMulti20 = function (value) {
        this.inputValue20 = value;
        this.mapInputs();
        this.emitInput(this.controlProps.value);
        return value;
    };
    TextControl.prototype.emitDate = function (value) {
        this.controlProps.value = value;
        this.inputValue = value;
        this.showDate = false;
    };
    Object.defineProperty(TextControl.prototype, "controlProps", {
        get: function () {
            var _a = this, baseInputProps = _a.baseInputProps, disabled = _a.disabled, inputType = _a.inputType, readonly = _a.readonly;
            if (this.isContinueAsOffer) {
                return {
                    label: baseInputProps.label.replace('*', ''),
                    value: baseInputProps.value,
                    name: baseInputProps.name,
                    outlined: baseInputProps.outlined,
                    square: baseInputProps.square,
                    maxlength: this.maxlength,
                    color: 'primary',
                    disable: disabled,
                    readonly: readonly,
                    type: inputType,
                    style: {
                        marginBottom: '20px'
                    },
                    mask: this.mask
                };
            }
            return __assign(__assign({}, baseInputProps), { color: 'primary', disable: disabled, type: inputType, maxlength: this.maxlength, readonly: readonly, mask: this.mask });
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop({ default: function () { return ({}); } })
    ], TextControl.prototype, "props", void 0);
    __decorate([
        Ref('tr-input')
    ], TextControl.prototype, "trInput", void 0);
    TextControl = __decorate([
        Component({
            components: { QInput: QInput, QIcon: QIcon, QDate: QDate, QBtn: QBtn, QSelect: QSelect }
        })
    ], TextControl);
    return TextControl;
}(BaseControl));
export default TextControl;
