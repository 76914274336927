import { __assign, __awaiter, __generator } from "tslib";
import axios from 'axios';
import { PROXY_BASE_URL } from './serviceProxy';
var ENPOINTCONFI = '/config/feconfig';
export default (function () {
    var getCalculatorTypes = function () { return __awaiter(void 0, void 0, Promise, function () {
        var hostname, isLocalhost, response, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    hostname = window.location.hostname;
                    isLocalhost = hostname.includes('localhost');
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.get(ENPOINTCONFI, {
                            baseURL: PROXY_BASE_URL,
                            headers: __assign({}, (isLocalhost
                                ? {
                                    'x-origin': 'localhost-karsten'
                                }
                                : {}))
                        })];
                case 2:
                    response = _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    if (axios.isCancel(e_1))
                        return [2 /*return*/, null];
                    return [2 /*return*/, e_1.message];
                case 4: return [2 /*return*/, response ? response.data : null];
            }
        });
    }); };
    return { getCalculatorTypes: getCalculatorTypes };
});
